import { useCallback, useEffect, useState, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useTicketLockStatus, useTicketPermissions } from 'remote-state/ticketServiceHooks';
import { selectActiveUser } from 'store/userSlice';
import { getIsPreviewSr } from 'common/utils/srUtils';
import CustomScrollBar from 'common/components/customScroll';
import AttachmentDropzone from 'common/components/attachmentDropzone';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { ErrorBoundary as ErrorFallback } from './errorBoundary/index';
import { useUploadAttachments } from './attachments/hooks/useUploadAttachments';
import Attachments from './attachments';
import Workflows from './Workflows';
import AuditLog from './auditLog';
import RelatedItems from './relatedItems';
import { StyledMiddlePanel } from './style';
import { selectAuditLog, selectActionLine } from './auditLog/store/slice';
import PreviewPlaceholder from './previewPlaceholder';
import { useAuditLog } from './auditLog/hooks';
import { useTabs } from './auditLog/hooks/useTabs';
import { MIDDLE_PANEL_TAB_NAMES } from './constants';

export default function MiddlePanel({
  srPanelCollapsed,
  srType,
  auditLogsRef,
  scrollToAuditLogRecord,
  showTicketAsTab,
}) {
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const { selectedTabName } = useTabs({ srId, showTicketAsTab });
  const { isExpanded } = useSelector(selectActionLine);
  const ticketAuditLogs = useSelector(selectAuditLog);
  const isPreviewSR = getIsPreviewSr(srId);
  const { selectedFilterType } = useAuditLog();
  const userAccount = useSelector(selectActiveUser);
  const scrollRef = useRef();
  const previousFilterType = usePreviousValue(selectedFilterType);
  const isFilterChanged = previousFilterType !== selectedFilterType;
  const scrollPositionLog = useRef({});
  const { handleUploadNewAttachment } = useUploadAttachments({ showTicketAsTab });
  const currentAuditLogsByFilter = ticketAuditLogs[selectedFilterType];
  const { data: hasEditPermissions } = useTicketPermissions('edit', srId);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;
  const isDropzoneDisabled =
    Number.isNaN(parseInt(srId, 10)) ||
    isExpanded ||
    isTicketLocked ||
    !hasEditPermissions ||
    selectedTabName === MIDDLE_PANEL_TAB_NAMES.WORKFLOWS;
  const [showActionLineShadow, setShowActionLineShadow] = useState(false);

  useEffect(() => {
    if (selectedTabName !== MIDDLE_PANEL_TAB_NAMES.JOURNEY) return;
    if (isFilterChanged && scrollPositionLog?.current?.[selectedFilterType] >= 0) {
      scrollRef.current.scrollTop(scrollPositionLog.current[selectedFilterType]);
    }
    scrollRef.current.scrollTop({ behavior: 'smooth' });
  }, [selectedTabName, isFilterChanged, currentAuditLogsByFilter?.list?.length, scrollPositionLog, selectedFilterType]);

  const handleActionLineShadow = useCallback(
    (forceShadow) => {
      if (selectedTabName !== MIDDLE_PANEL_TAB_NAMES.JOURNEY) return;
      if (scrollRef.current.getScrollHeight() === scrollRef.current.getClientHeight()) {
        setShowActionLineShadow(false);
        return;
      }
      const isScrollBottom =
        scrollRef?.current.getScrollHeight() - scrollRef.current.getScrollTop() - scrollRef.current.getClientHeight() <
        1;
      if (!forceShadow && isScrollBottom) {
        setShowActionLineShadow(false);
        return;
      }
      setShowActionLineShadow(true);
    },
    [selectedTabName],
  );

  const handleScrollStart = useCallback(() => {
    handleActionLineShadow(true);
  }, [handleActionLineShadow]);

  const handleScrollStop = useCallback(() => {
    if (scrollRef.current.getScrollHeight() === scrollRef.current.getClientHeight()) return;
    handleActionLineShadow();
    const newPosition = scrollRef.current.getScrollTop();
    const scrollDifference = Math.abs(scrollPositionLog.current[selectedFilterType] - newPosition);
    if (!scrollPositionLog.current[selectedFilterType] || scrollDifference > 1)
      scrollPositionLog.current[selectedFilterType] = newPosition;
  }, [handleActionLineShadow, selectedFilterType]);

  const handleAttachmentDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => handleUploadNewAttachment(file));
  };

  if (isPreviewSR) return <PreviewPlaceholder />;

  return (
    <AttachmentDropzone dropzoneDisabled={isDropzoneDisabled} onAttachmentDrop={handleAttachmentDrop}>
      <CustomScrollBar
        id="resolution-middle-panel-scroll"
        data-testid="resolution-middle-panel-scroll"
        autoHide
        thumbSize={150}
        renderThumbVerticalStyle={{ marginRight: -9, width: 4 }}
        onScrollStart={handleScrollStart}
        onScrollStop={handleScrollStop}
        ref={scrollRef}
      >
        <StyledMiddlePanel isRelatedItemsTab={selectedTabName === MIDDLE_PANEL_TAB_NAMES.RELATED_ITEMS}>
          {selectedTabName === MIDDLE_PANEL_TAB_NAMES.WORKFLOWS && <Workflows />}
          {selectedTabName === MIDDLE_PANEL_TAB_NAMES.JOURNEY && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AuditLog
                srPanelCollapsed={srPanelCollapsed}
                srId={srId}
                selectedFilter={selectedFilterType}
                currentAuditLogsByFilter={currentAuditLogsByFilter}
                auditLogsRef={auditLogsRef}
                scrollToAuditLogRecord={scrollToAuditLogRecord}
                srType={srType}
                showActionLineShadow={showActionLineShadow}
              />
            </ErrorBoundary>
          )}
          {selectedTabName === MIDDLE_PANEL_TAB_NAMES.ATTACHMENTS && (
            <Attachments isDropzoneDisabled={isDropzoneDisabled} showTicketAsTab={showTicketAsTab} />
          )}
          {selectedTabName === MIDDLE_PANEL_TAB_NAMES.RELATED_ITEMS && <RelatedItems srId={srId} srType={srType} />}
        </StyledMiddlePanel>
      </CustomScrollBar>
    </AttachmentDropzone>
  );
}
