import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    title: texts?.['spaces.prompt.title'],
    okBtn: texts?.['spaces.prompt.okBtn'],
    cancelBtn: texts?.['spaces.prompt.cancelBtn'],
  };

  return componentTexts;
}
