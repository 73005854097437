import { ListItemAvatar, ListItemText } from '@mui/material';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import Tooltip from 'common/components/tooltip';
import { AVATAR_CONSTANTS } from 'constants/index';
import { StyledUserInfoCardHeader } from './styles';

const componentId = 'user-info-card-header';

const UserInfoCardHeader = ({ profileImage, displayName }) => (
  <StyledUserInfoCardHeader disablePadding id={componentId}>
    <ListItemAvatar id={`${componentId}-avatar`}>
      <BasicAvatar size={AVATAR_CONSTANTS.SIZES.XL} profileImage={profileImage} userName={displayName} disableHover />
    </ListItemAvatar>
    <ListItemText
      id={`${componentId}-name`}
      primary={<Tooltip className="user-primary-text" isTruncatedText text={displayName} />}
    />
  </StyledUserInfoCardHeader>
);
export default UserInfoCardHeader;
