import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getRelatedItemsTypes,
  getSrRelatedItems,
  linkSrRelatedSrItem,
  unlinkSrRelatedItem,
} from 'services/relatedItemsService';
import { postSearchSrRequest } from 'services/ticketService';
import { RELATIONSHIP_TYPES, MAX_LINKED_ITEMS } from '../constants';
import useTexts from './useTexts';

export const useGetSrRelatedItems = (srId) => {
  const { data: srRelatedItems, isFetching } = useQuery(['srRelatedItems', srId], () => getSrRelatedItems(srId), {
    staleTime: 60 * 1000,
    cacheTime: 0,
    placeholderData: [],
    enabled: !!srId,
  });
  return { data: srRelatedItems || [], isLoading: isFetching };
};

export const useUnlinkRelatedItem = (srId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: unlinkSrRelatedItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['srRelatedItems', srId] });
    },
  });
};

export const useLinkSrRelatedSrItem = (srId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: linkSrRelatedSrItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['srRelatedItems', srId] });
    },
  });
};

export const useInitialData = () => {
  const { data, isFetching } = useQuery(['relatedItemsTypes'], () => getRelatedItemsTypes(), {
    staleTime: 60 * 1000,
    cacheTime: 0,
    placeholderData: [],
  });
  return { data, isLoading: isFetching };
};

export const useIsSrChildOrParent = (srId) => {
  const {
    componentTexts: { parentText, childText },
  } = useTexts();
  const { data: relatedItems } = useGetSrRelatedItems(srId);
  const result = relatedItems?.serviceRequests?.find(
    (item) => item.relationship === RELATIONSHIP_TYPES.PARENT || item.relationship === RELATIONSHIP_TYPES.CHILD,
  );
  if (!result) return { isSrChildOrParent: false, relationship: null };
  const relationship = result.relationship === RELATIONSHIP_TYPES.PARENT ? parentText : childText;
  return { isSrChildOrParent: true, relationshipText: relationship, relationship: result.relationship };
};

export const useRelatedSrItemsDropdownColumns = () => [
  {
    header: 'ID',
    valueKey: 'id',
    width: 100,
  },
  {
    header: 'SR Type',
    valueKey: 'srTypeString',
    width: 100,
  },
  {
    header: 'Title',
    valueKey: 'value',
    width: 200,
  },
];

export const useCustomBackendQueryConfig = async (query, srId, queryClient) => {
  const searchSrRequest = {
    from: 0,
    limit: MAX_LINKED_ITEMS,
    columnConfiguration: [
      {
        fieldName: 'id',
        displayName: 'spaces.template.fields.id',
        alwaysReadOnly: true,
        id: 2,
        sysaidEntity: null,
        customColumn: false,
        ticketTemplateFieldType: {
          id: 3,
          typeName: 'number',
        },
      },
      {
        fieldName: 'title',
        displayName: 'spaces.template.fields.title',
        alwaysReadOnly: false,
        id: 57,
        sysaidEntity: null,
        customColumn: false,
        ticketTemplateFieldType: {
          id: 1,
          typeName: 'text',
        },
      },
      {
        fieldName: 'srType',
        displayName: 'spaces.template.fields.srType',
        alwaysReadOnly: true,
        id: 32,
        sysaidEntity: null,
        customColumn: false,
        ticketTemplateFieldType: {
          id: 6,
          typeName: 'select',
        },
      },
    ],
    sorts: [],
    filters: [
      {
        type: 'search',
        values: [query],
      },
    ],
  };
  const data = await postSearchSrRequest(searchSrRequest);
  const recentlyCreatedSrs = queryClient.getQueryData(['recentlyCreatedSrs', srId]) || [];
  const srRelatedItems = queryClient.getQueryData(['srRelatedItems', srId]) || [];
  const recentlyCreatedSrIds = recentlyCreatedSrs?.map((sr) => sr.id);
  const srRelatedItemsIds = srRelatedItems?.serviceRequests?.map((sr) => sr.id);
  if (data?.serviceRequests?.length) {
    const extractedValues = data?.serviceRequests
      ?.filter((sr) => {
        const id = sr.find((item) => item.field === 'id')?.value;
        return id !== srId && !recentlyCreatedSrIds?.includes(id) && !srRelatedItemsIds?.includes(id);
      })
      ?.map((sr) => {
        const id = sr.find((item) => item.field === 'id')?.value;
        const title = sr.find((item) => item.field === 'title')?.value;
        const srType = sr.find((item) => item.field === 'srType')?.value;
        return {
          id,
          srType,
          title,
          valueKey: id,
        };
      });
    queryClient.setQueryData(['recentlyCreatedSrs', srId], [...recentlyCreatedSrs, ...extractedValues]);
    return { values: extractedValues };
  }
  return [];
};
