import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { getRichTextValueByColumnName } from 'common/components/controls/RichTextEditor/utils';
import { checkIsDirty } from 'features/resolutionPanel/middlePanel/auditLog/utils';
import { updateState } from 'features/resolutionPanel/middlePanel/auditLog/store/actionLineReducer';
import { ResourceType, SubResourceType } from 'services/attachments';
import { useTicketAttachments } from 'remote-state/attachments';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import {
  // removeRichFieldBySource,
  resetSavedRichFieldData,
  selectRichTextFields,
  setIsRichFieldEditorDirty,
  updateRichFieldData,
} from './store/slice';

const EDITOR_TEXT_KEY = 'text';
export const DEFAULT_RICH_TEXT_VALUE = {
  text: '',
  attachments: [],
  inlineImages: [],
};

export const useRichTextEditor = ({
  sourceId,
  required,
  fieldId,
  fieldValue,
  fieldName,
  handleSaveValue,
  handleDirty,
  isTemplatePage,
  shouldAddField = false,
  isNewEditor = true,
  updateFieldValidation,
}) => {
  const dispatch = useDispatch();
  const { createSrAttachment } = useTicketAttachments();
  const fields = useSelector(selectRichTextFields);
  const field = useMemo(
    () => fields.find((field) => field.sourceId === sourceId && field.fieldId === fieldId),
    [fieldId, sourceId, fields],
  );
  const data = useMemo(() => field?.data || {}, [field?.data]);
  const isEditorDirty = !!field?.isEditorDirty;

  const [activeUploads, setActiveUploads] = useState([]);
  const [dirtyFields, setDirtyFields] = useState({});

  const checkAndUpdateValidationStatus = useCallback(() => {
    let isValid = true;
    let isEmpty = false;
    if (
      Object.keys(data).length === 0 ||
      ((!Array.isArray(data.attachments) || data?.attachments?.length === 0 || fieldName === 'description') &&
        !data?.text?.trim()?.length)
    ) {
      isEmpty = true;
    }
    if (isEmpty && required) {
      isValid = false;
    }
    if (!isTemplatePage && updateFieldValidation) {
      updateFieldValidation(fieldName, required, isValid);
    }
    return isValid;
  }, [data, fieldName, required, isTemplatePage, updateFieldValidation]);

  useEffect(() => {
    checkAndUpdateValidationStatus();
  }, [checkAndUpdateValidationStatus]);

  const handleFileUpload = async ({ file, onProgressChange, inline = false, visibility = true }) =>
    createSrAttachment({
      file,
      subResourceType: !sourceId ? SubResourceType.TEMP : SubResourceType.DESCRIPTION,
      resourceType: ResourceType.SR,
      resourceId: !sourceId || sourceId === 'new' ? 0 : sourceId,
      onProgressChange,
      visibility,
      inline,
    });

  const updateRichTextData = (newValue) => {
    const updateData = updateState(newValue, data);
    dispatch(updateRichFieldData({ fieldId, sourceId, data: updateData }));
  };

  const updateIsEditorDirty = (payload) => {
    setDirtyFields((prev) => ({ ...prev, ...{ ...payload } }));
    dispatch(
      setIsRichFieldEditorDirty({
        fieldId,
        sourceId,
        isEditorDirty: Object.values({ ...dirtyFields, ...payload }).some((isFieldDirty) => isFieldDirty),
      }),
    );
  };

  const handleEditorChange = (updatedEditorField, newValue) => {
    try {
      const updatedData = { ...data, ...updateState(newValue, data) };
      updateRichTextData(newValue);
      const isDirty = checkIsDirty({
        updatedData,
        lastSavedData: field?.lastSavedData,
      });
      updateIsEditorDirty({ [updatedEditorField]: isDirty });
      return updatedData;
    } catch (error) {
      console.error('Error =>', error);
    }
  };

  const replaceSpaces = (line) => line.replace(/<\/?p>|<br>| |&nbsp;/g, '');

  const trimValue = (value) => {
    const paragraphs = value.split(/(?=<\s*p\s*>)/);
    for (let index = 0; index < (paragraphs.length > 2 ? 2 : 1); index++) {
      let clearedParagraph = replaceSpaces(paragraphs[!index ? 0 : paragraphs.length - 1]);
      while (!clearedParagraph && paragraphs.length) {
        if (!index) {
          paragraphs.shift();
        } else {
          paragraphs.pop();
        }
        clearedParagraph = paragraphs.length && replaceSpaces(paragraphs[!index ? 0 : paragraphs.length - 1]);
      }
    }

    return paragraphs.join('');
  };

  const saveRichText = async (payload) => {
    const richTextFields = sourceId === 0 && !isTemplatePage ? payload : JSON.stringify(payload);
    if (handleSaveValue) {
      handleSaveValue({
        richTextFields,
      });
    }
    if (handleDirty) {
      const jsonContent = getRichTextValueByColumnName(richTextFields, fieldName)?.jsonContent;
      if (jsonContent) {
        let parsedJsonContent;
        try {
          parsedJsonContent = JSON.parse(jsonContent);
        } catch (err) {
          parsedJsonContent = jsonContent;
        }
        const value = parsedJsonContent?.text;
        handleDirty({ [fieldName]: value });
      }
    }
    dispatch(resetSavedRichFieldData({ fieldId, sourceId, lastSavedData: data }));
  };

  const prepareAttachmentsPayload = (dataToPrepare) => ({
    ...dataToPrepare,
    attachments: dataToPrepare?.attachments
      ? dataToPrepare.attachments.map((file) => {
          const item = {};
          for (const element of Object.keys(file)) {
            if (element !== 'uploadId') {
              item[element] = file[element];
            }
          }
          return item;
        })
      : [],
  });

  const handleSave = () => {
    if (!isEmpty(data) && !isEqual(data, fieldValue)) {
      const trimmedValue = trimValue(data.text);
      saveRichText([
        {
          referencedEntityTableName: 'SERVICE_REQ',
          columnName: fieldName,
          jsonContent: JSON.stringify(prepareAttachmentsPayload({ ...data, text: trimmedValue })),
        },
      ]);
    }
  };

  const handleChange = (editorText) => {
    handleEditorChange(EDITOR_TEXT_KEY, { [EDITOR_TEXT_KEY]: editorText });
    if (isTemplatePage) handleSave();
  };

  const handleImageUpload = async (params) => {
    const { metadata: uploadMetadata } = await handleFileUpload({ ...params, inline: true, visibility: false });
    if (uploadMetadata) {
      const { id, originalName, extension } = uploadMetadata;
      handleEditorChange('inlineImages', {
        list: 'inlineImages',
        listItem: { id, originalName, extension, temp: true },
      });
    }
    return uploadMetadata?.id;
  };

  const handleFileAttach = async (params) => {
    const fileData = await handleFileUpload(params);
    const { metadata: uploadMetadata } = fileData;
    if (uploadMetadata) {
      const { id, originalName, extension } = uploadMetadata;
      const newData = handleEditorChange('attachments', {
        list: 'attachments',
        listItem: { id, originalName, extension, temp: true, uploadId: params?.uploadId },
      });

      if (!isTemplatePage && (sourceId || sourceId === 0)) {
        const payload = [
          {
            referencedEntityTableName: 'SERVICE_REQ',
            columnName: fieldName,
            jsonContent: JSON.stringify(prepareAttachmentsPayload(newData)),
          },
        ];
        saveRichText(payload);
      }
      return uploadMetadata;
    }
  };

  const handleRemoveAttachmentById = (id) => {
    const { attachments } = data;
    let updateAttachments;
    if (isNewEditor) {
      updateAttachments = attachments.filter((item) => item.id !== id);
    } else {
      updateAttachments = attachments.map((item) => {
        if (item.id === id) {
          return { ...item, ...{ deleted: true } };
        }
        return item;
      });
    }
    const newData = handleEditorChange('attachments', {
      list: 'attachments',
      listItem: updateAttachments,
      operation: 'overwrite',
    });
    if (!isTemplatePage && (sourceId || sourceId === 0)) {
      const payload = [
        {
          referencedEntityTableName: 'SERVICE_REQ',
          columnName: fieldName,
          jsonContent: JSON.stringify(newData),
        },
      ];
      saveRichText(payload);
    }
  };

  const addUpload = (payload) => {
    setActiveUploads((prev) => [...prev, payload]);
  };
  const removeUpload = (payload) => {
    setActiveUploads((prev) => prev.filter((upload) => upload.id !== payload.id));
  };
  const updateUpload = (payload) => {
    setActiveUploads((prev) =>
      prev.map((upload) => {
        if (upload.id === payload.id) {
          upload = { ...upload, ...payload };
        }
        return upload;
      }),
    );
  };

  const handleChangeActiveUploads = ({ type, payload }) => {
    switch (type) {
      case 'addUpload':
        addUpload(payload);
        break;
      case 'removeUpload':
        removeUpload(payload);
        break;
      case 'updateUpload':
        updateUpload(payload);
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (typeof sourceId !== 'undefined' && sourceId !== null) {
  //     // can be zero
  //     dispatch(removeRichFieldBySource({ sourceId }));
  //   }
  // }, [sourceId, dispatch]);

  const prevFieldValue = usePreviousValue(fieldValue);

  useEffect(() => {
    if (prevFieldValue !== fieldValue && fieldValue && !isEqual(data, fieldValue) && shouldAddField) {
      // handles the situation of first render and real time updates (socket updates for other users on same ticket)
      // TODO investigate why the state is not updated without setTimeout
      setTimeout(() => {
        dispatch(
          updateRichFieldData({
            fieldId,
            sourceId,
            data: fieldValue,
          }),
        );
      }, 0);
    }
  }, [dispatch, data, fieldId, sourceId, prevFieldValue, fieldValue, shouldAddField]);

  return {
    data,
    isEditorDirty,
    activeUploads,
    handleSave,
    handleChange,
    handleFileAttach,
    handleImageUpload,
    handleChangeActiveUploads,
    handleRemoveAttachmentById,
  };
};
