import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getCategoryDrivenEnabled,
  allowSelfAssign,
  setCategoryDrivenEnabled,
  shouldHideAccessToClassic,
} from 'services/accountSettingsService';
import { useFeatureFlagQuery } from './featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../constants/featureFlags';
import { useApplicationData } from './applicationHooks';
import { QUERIES_KEYS } from '../constant';
import { BASIC_EDITION } from '../features/settings/constants';
import { CONSTANTS } from '../features/header/constants';

const options = {
  staleTime: 1000 * 60 * 15,
};

const useAllowSelfAssignQuery = () => {
  const queryKey = 'allowSelfAssign';
  const queryClient = useQueryClient();

  const allowSelfAssignQuery = useQuery([queryKey], () => allowSelfAssign(), {
    placeholderData: false,
    ...options,
  });

  const updateAllowSelfAssign = (newValue) => {
    queryClient.setQueryData([queryKey], () => newValue);
  };

  return { ...allowSelfAssignQuery, updateAllowSelfAssign };
};

const useCategoryDrivenEnabled = () =>
  useQuery(['categoryDrivenEnabled'], () => getCategoryDrivenEnabled(), { placeholderData: {}, ...options });

const useShowLinkToClassic = () => {
  const { data: isProjectOneEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.PROJECT_ONE_ENABLED,
    defaultValue: false,
  });

  const { data: hideAccessToClassic } = useQuery(
    ['hideAccessToClassic'],
    () => shouldHideAccessToClassic().catch(() => false),
    { placeholderData: {}, ...options },
  );
  return isProjectOneEnabled && hideAccessToClassic === false;
};

const useSrConversionEnabled = (srType) => {
  const { data: isSrConversionFFEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.SR_CONVERSION,
    defaultValue: false,
  });
  const {
    data: { allowChangingSrType },
  } = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);
  const { data: edition } = useApplicationData(QUERIES_KEYS.EDITION);

  return (
    isSrConversionFFEnabled &&
    allowChangingSrType &&
    edition !== BASIC_EDITION &&
    (srType === CONSTANTS.INCIDENT_TYPE || srType === CONSTANTS.REQUEST_TYPE)
  );
};

const useSetCategoryDrivenEnabled = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setCategoryDrivenEnabled,
    onSuccess: (data) => {
      queryClient.setQueryData(['categoryDrivenEnabled'], { data: data?.data?.enabled || false });
    },
  });
};

const useAutoPopulationEnabled = () => {
  const { data: isAutoPopulatedTicketsFFOn } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.AUTO_POPULATED_TICKETS_SPACES,
    defaultValue: false,
  });
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  return isAutoPopulatedTicketsFFOn && isAiEnabled;
};

export {
  useCategoryDrivenEnabled,
  useSetCategoryDrivenEnabled,
  useAllowSelfAssignQuery,
  useShowLinkToClassic,
  useSrConversionEnabled,
  useAutoPopulationEnabled,
};
