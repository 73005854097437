import { useCallback, useEffect, useState, useRef } from 'react';

import MessagePrompt from 'common/components/messagePrompt';
import { ReactComponent as RelatedSrsIcon } from 'images/icons/related-service-records.svg';
import { ReactComponent as AccordionToggleButton } from 'images/icons/accordionToggleButton.svg';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants';
import { StyledRelatedItemsTables } from './style';
import { ITEM_TYPES, RELATED_ITEMS_COLLAPSED_SESSION_KEY, TABLE_MAX_ROWS, TABLE_ROW_HEIGHT } from '../constants';
import { Table } from './table';
import { useTableData } from './useTableData';
import useTexts from '../hooks/useTexts';
import { useUnlinkRelatedItem } from '../hooks/useRelatedItems';

export const RelatedItemsTables = ({ relatedItems, srId, srType }) => {
  const [tablesCollapsed, setTablesCollapsed] = useState();
  const [unlinkPrompt, setUnlinkPrompt] = useState({ open: false, relationship: '', linkedItemId: 0 });
  const srRelatedItemsGridRef = useRef(null);
  const { srRelatedItemsColumns, sortingFns } = useTableData();
  const pendoTrackEvents = usePendoTrackEvents();
  const { mutateAsync: unlinkItem } = useUnlinkRelatedItem(srId);
  const srTypeValue = SR_TYPE_CODE_MAP[srType];
  const {
    componentTexts: {
      unlinkPromptTitle,
      unlinkPromptCancelBtn,
      unlinkPromptOkBtn,
      unlinkPromptDescription,
      srTableTitle,
    },
    getSrType,
  } = useTexts(srTypeValue);

  const getCollapsedTables = () => {
    let storedTableData = sessionStorage.getItem(RELATED_ITEMS_COLLAPSED_SESSION_KEY);
    storedTableData = JSON.parse(storedTableData);
    if (!storedTableData) {
      storedTableData = {};
    }
    return storedTableData;
  };

  const setTablesCollapsedInSession = (key, value) => {
    const tablesStatus = getCollapsedTables();
    tablesStatus[key] = value;
    setTablesCollapsed(tablesStatus);
    sessionStorage.setItem(RELATED_ITEMS_COLLAPSED_SESSION_KEY, JSON.stringify(tablesStatus));
  };

  useEffect(() => {
    if (srId) {
      const tablesStatus = getCollapsedTables();
      if (tablesStatus) {
        setTablesCollapsed(tablesStatus);
      }
    }
  }, [srId]);

  const handleExpansion = (table, value) => setTablesCollapsedInSession(`${srId}_relatedItems_${table}`, value);

  const onSrRowclicked = useCallback(
    (row, event) => {
      const unlinkItemCellClicked = event?.target?.closest('svg');
      if (unlinkItemCellClicked) {
        pendoTrackEvents(PENDO_TRACK_EVENTS.Unlink_existing_related_item_icon_click, {});
        setUnlinkPrompt({
          ...unlinkPrompt,
          open: true,
          relationship: row?.relationship,
          linkedItemId: row?.id,
          itemType: ITEM_TYPES?.SR?.id,
          title: unlinkPromptTitle
            ?.replace('__srType__', getSrType(SR_TYPE_CODE_MAP[row?.srType]))
            ?.replace('__id__', row?.id),
        });
      } else {
        window.open(`/spaces/ticket?id=${row?.id}`, '_blank').focus();
      }
    },
    [unlinkPrompt, unlinkPromptTitle, getSrType, pendoTrackEvents],
  );

  const handleClosePrompt = () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.Cancel_unlinking_existing_related_item_button_click_in_popup, {});
    setUnlinkPrompt({ ...unlinkPrompt, open: false });
  };

  const onPromptUnlinkClick = async () => {
    pendoTrackEvents(PENDO_TRACK_EVENTS.Unlink_existing_related_item_button_click_in_popup, {});
    await unlinkItem({
      srId,
      linkedItemId: unlinkPrompt?.linkedItemId,
      relationship: unlinkPrompt?.relationship,
      itemType: unlinkPrompt?.itemType,
    });
    setUnlinkPrompt({ ...unlinkPrompt, open: false });
  };

  const srRowsCountFitInTable =
    relatedItems?.serviceRequests?.length >= TABLE_MAX_ROWS ? TABLE_MAX_ROWS : relatedItems?.serviceRequests?.length;

  return (
    <StyledRelatedItemsTables
      rowHeight={TABLE_ROW_HEIGHT}
      srRowsCountFitInTable={srRowsCountFitInTable}
      // displayed={(tablesCollapsed && tablesCollapsed[`${srId}_relatedItems_sr`]) ?? true}
    >
      <MessagePrompt
        open={unlinkPrompt?.open}
        title={unlinkPrompt?.title}
        onOkClick={onPromptUnlinkClick}
        btnOkText={unlinkPromptOkBtn}
        showCancelBtn
        btnCancelText={unlinkPromptCancelBtn}
        onClose={handleClosePrompt}
        showIcon={false}
      >
        {unlinkPromptDescription}
      </MessagePrompt>
      <Table
        accordionId="sr"
        expanded={(tablesCollapsed && tablesCollapsed[`${srId}_relatedItems_sr`]) ?? true}
        handleExpansion={handleExpansion}
        ExpandIcon={<AccordionToggleButton className="expandIcon" />}
        headerIcon={RelatedSrsIcon}
        relatedItems={relatedItems?.serviceRequests}
        onRowclicked={onSrRowclicked}
        columns={srRelatedItemsColumns}
        sortingFns={sortingFns}
        queryKey="srRelatedSrItems"
        ref={srRelatedItemsGridRef}
        tableTitle={`${srTableTitle} (${relatedItems?.serviceRequests?.length})`}
      />
    </StyledRelatedItemsTables>
  );
};
