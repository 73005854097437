import { actionEnums } from './constants';

interface LooseObject {
  [key: string]: unknown;
}
export const capitalizeFirstLetter = (str: string): string => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};
interface Data {
  lastSavedData: LooseObject;
  updatedData: LooseObject;
}
interface Log {
  logType: number;
}

export const convertCamelCaseToSpaces = (text: string): string => {
  if (text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  return '';
};

export const convertToCamelCase = (text: string): string => {
  if (text) {
    return text
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
      .replace(/\s+/g, '');
  }
  return '';
};
const areArraysEqual = (first: LooseObject[], second: LooseObject[]) => {
  if (first === second) return true;
  if (first == null || second == null) return false;
  if (first.length !== second.length) return false;

  for (let i = 0; i < first.length; ++i) {
    const firstProps = Object.getOwnPropertyNames(first[i]);
    const secondProps = Object.getOwnPropertyNames(second[i]);

    if (firstProps.length !== secondProps.length) {
      return false;
    }

    for (let j = 0; j < firstProps.length; j++) {
      const propName = firstProps[j];
      if (first[i][propName] !== second[i][propName]) {
        return false;
      }
    }
  }
  return true;
};

export const checkIsDirty = ({ lastSavedData, updatedData }: Data): boolean =>
  lastSavedData &&
  Object.keys(lastSavedData).some((key) => {
    if (Array.isArray(lastSavedData[key]) && Array.isArray(updatedData[key])) {
      return !areArraysEqual(lastSavedData[key] as LooseObject[], updatedData[key] as LooseObject[]);
    }
    if ((lastSavedData[key] === "<div></div>" && updatedData[key] === "") || 
      (lastSavedData[key] === "" && updatedData[key] === "<div></div>")
    ) {
      return false;
    }
    return lastSavedData[key] !== updatedData[key];
  });

export const isDeleted = ({ logType }: Log): boolean =>
  logType === actionEnums.ATTACHMENTS_DELETED || logType === actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED;
