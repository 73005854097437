// test flow
import { DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as ErrorIcon } from 'images/icons/ErrorSign.svg';
import { ReactComponent as WorkflowWarningTextIcon } from 'images/icons/WorkflowWarningTextIcon.svg';
import useTexts from './useTexts';
import { StyledPromptWrapper } from './style';

const MessagePrompt = (props) => {
  // Note!! The use of this component should be according to the following rules:
  // 1. mandatory props: open, children, onOkClick
  // 2. Title & children - at least one of the two should be provided.
  // 3. showCancelBtn - We will provide if we want another button
  const { title: titleText, okBtn, cancelBtn } = useTexts();
  const {
    open,
    children = '',
    onClose,
    Icon = ErrorIcon,
    showCancelBtn = false,
    isFitContent = false,
    showIcon = true,
    title = titleText,
    btnOkText = okBtn,
    isOkButtonDisable,
    btnCancelText = cancelBtn,
    onOkClick = () => {},
    style,
    workflowWarningText,
  } = props;

  return (
    <StyledPromptWrapper
      open={open}
      onClose={onClose}
      style={style}
      isFitContent={isFitContent}
      data-cy="prompt-wrapper"
    >
      <div className="propmt-wrapper" data-testid="message-prompt">
        {showIcon && (
          <div className="prompt-icon">
            <Icon />
          </div>
        )}
        <div>
          {title && <DialogTitle data-testid="message-prompt-title" data-cy="message-prompt-title">{title}</DialogTitle>}
          {children && (
            <DialogContent>
              <DialogContentText data-testid="promt_message" data-cy="promt_message">{children}</DialogContentText>
            </DialogContent>
          )}
        </div>
      </div>
      <DialogActions className="dialog-action-wrapper">
          <div className="warning-wrapper">
            {!!workflowWarningText && (
              <div className="workflow-warning-wrapper">
                <WorkflowWarningTextIcon />
                <span className="workflow-warning-text">{workflowWarningText}</span>
              </div>
            )}
          </div>
        {showCancelBtn && (
          <button onClick={onClose} className="cancel-btn" data-testid="cancel-btn" data-cy="cancel-btn">
            {btnCancelText}
          </button>
        )}
        <button
          onClick={onOkClick}
          className="ok-btn"
          data-testid="ok-btn"
          data-cy="ok-btn"
          disabled={isOkButtonDisable}
        >
          {btnOkText}
        </button>
      </DialogActions>
    </StyledPromptWrapper>
  );
};

MessagePrompt.propTypes = {
  Icon: PropTypes.elementType,
  showCancelBtn: PropTypes.bool,
  showIcon: PropTypes.bool,
  isOkButtonDisable: PropTypes.bool,
  onOkClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  btnOkText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  btnCancelText: PropTypes.string,
  workflowWarningText: PropTypes.string,
};

export default MessagePrompt;
