import { useCallback } from 'react';
import { QUERIES_KEYS } from 'constant';
import { USER_CONSTANTS } from '../../../constants/users';
import { useUserInfo } from '../../../remote-state/userServiceHooks';
import { CONSTANTS } from '../../components/datePicker/constants';

function useDateTimeInfo() {
  const { data: userInfo } = useUserInfo(QUERIES_KEYS.CURRENT_USER_FROM_CORE);
  let { valueCaption: timezone } = userInfo?.info?.find((atr) => atr.key === USER_CONSTANTS.TIMEZONE) || {};
  const { valueCaption: dateFormat } = userInfo?.info?.find((atr) => atr.key === USER_CONSTANTS.DATE_FORMAT) || {};
  const is12HourTimeFormat = useCallback(
    () => dateFormat?.[dateFormat.length - 1] === CONSTANTS.BACKEND_12_HOUR_TIME_FORMAT,
    [dateFormat],
    [dateFormat],
  );
  if (timezone == null || timezone === '') {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return {
    timezone,
    dateFormat,
    is12HourTimeFormat,
  };
}

export default useDateTimeInfo;
