import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';

export const StyledUserAvatar = styled(Avatar)(({ theme }) => ({
  color: theme?.palette?.generic?.primary,
  fontFamily: theme?.fonts?.robotoBold,
  lineHeight: '100%',
  position: 'relative',
  fontSize: '12px',
  '& img': {
    borderRadius: '50%',
    height: 'calc(100% - 4px)',
    width: 'calc(100% - 4px)',
  },
}));
