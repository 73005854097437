import { forwardRef } from 'react';
import { Accordion, Typography } from '@mui/material';
import Grid from 'common/components/grid';
import { StyledAccordionDetails, StyledAccordionSummary } from '../style';
import { Header } from '../header';

export const Table = forwardRef(
  (
    {
      accordionId,
      expanded,
      handleExpansion,
      ExpandIcon,
      tableTitle,
      headerIcon,
      relatedItems,
      sortingFns,
      columns,
      queryKey,
      onRowclicked,
    },
    ref,
  ) => (
    <Accordion
      id={accordionId}
      expanded={expanded}
      disableGutters
      onChange={(event, value) => handleExpansion(accordionId, value)}
      elevation={0}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
    >
      <StyledAccordionSummary expandIcon={ExpandIcon}>
        <Typography component="span">
          <Header title={tableTitle} Icon={headerIcon} dataTestId={`${accordionId}-related-items-header`} />
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Grid
          ref={ref}
          onRowClicked={onRowclicked}
          queryFn={() => {}}
          queryKey={queryKey}
          columns={columns}
          sortingFns={sortingFns}
          enableSortingRemoval={false}
          gridExternalData={relatedItems}
          hideSortIcon={!expanded}
        />
      </StyledAccordionDetails>
    </Accordion>
  ),
);
