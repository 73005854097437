import { axiosInstance } from './axiosConfig';

/**
 * axios post request - validateUser
 * @param {*} username - request's body's username
 * @param {*} password request's body's password
 * @returns axios response
 */

export const parseUser = (user) => ({
  ...user,
  ...user && {
    value: user.userName
  }
});

export const postValidateUserRequest = (username, password) => {
  // CORS
  const headers = {
    'Content-Type': 'application/json',
  };

  return axiosInstance
    .post(
      '/authentication/validateUser',
      {
        username,
        password: encodeURIComponent(password),
      },
      { headers },
    )
    .then((response) => response)
    .catch(({ response }) => {
      if (response.status === 401) {
        return { ...response, status: response.status };
      }
      throw new Error('Failed to validate user!');
    });
};

/**
 * axios get request - logout
 * @returns axios response
 */
export const logout = () => axiosInstance.get('/authentication/logout').then((response) => response.data);

/**
 * axios get request - getUser from sysaid core
 * @returns axios response
 */
export const getUser = () => axiosInstance.get('authentication/users/me').then((response) => response.data);

export const getUserMobileLink = () => axiosInstance.get('users/getUserMobileLink').then((response) => response.data);

/**
 * axios post request - guestLogin
 * @returns axios response
 */
export const guestLogin = () => axiosInstance.post('/authentication/guest').then((response) => response.data);

/**
 * axios get request - getLoginObjectWithRetry
 * @returns axios response
 */
export const getLoginObject = (previousPath, manualLogin) =>
  axiosInstance
    .get(`/authentication/getLoginPageJson?previousPath=${previousPath}&manualLogin=${manualLogin}`)
    .then((response) => response.data);

export const getResourceBundle = (language) =>
  axiosInstance
    .get(`rb?language=${language}`, { headers: { 'Cache-Control': 'no-cache' } })
    .then((response) => response.data);

export const getUsersWithParams = (params = {}) => {
  const queryString = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value || value === 0) queryString.append(key, value);
  });
  return axiosInstance.get(`/users/search?${queryString.toString()}`).then((response) => response.data);
};
//call users with params and filter by notes permissions
export const getUsersWithParamsFilterNotesPermission = (params = {}) => {
  const queryString = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value || value === 0) queryString.append(key, value);
  });
  return axiosInstance
    .get(`/users/searchRichTextMentionUsers?${queryString.toString()}`)
    .then((response) => response.data);
};

export const getUsersBySearchText = (text) => {
  const queryString = new URLSearchParams();
  queryString.append('messageUserSearch', text);
  queryString.append('hasEmail', true);
  return axiosInstance.get(`/users/searchUserDetails?${queryString.toString()}`).then((res) => res.data);
};

export const saveProfilePicture = async ({ file }) => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosInstance
    .post('user/me/savePhoto', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data);
};

export const deleteProfilePicture = async () =>
  axiosInstance
    .delete('user/me/deletePhoto')
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });

export const usePhotoLimits = async () =>
  axiosInstance
    .get('users/photoLimits')
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });

export function getUserDetailsByName(userName) {
  return axiosInstance
    .get('users/getUserByName', { params: { user_name: userName } })
    .then((response) => parseUser(response.data));
}

export function getUserDetailsById(id) {
  return axiosInstance.get(`users/getUserById/${id}`).then((response) => parseUser(response.data));
}

export const validatePassword = (userName, password) =>
  axiosInstance.post('users/validatePassword', { userName, password }).then((response) => response.data);

export const getUserMainAssetAndCi = (userName) =>
  axiosInstance.get(`users/mainAssetAndCi${userName ? `?userName=${userName}` : ''}`).then((response) => response.data).catch((error) => error);

export const getUserAttributes = ({ username, attributeName }) =>
  axiosInstance.get(`user/attributes/${username}/${attributeName}`).then((response) => response.data);

export const saveUserAttributes = ({ username, attributeName, attributeValue }) =>
  axiosInstance.post('user/attributes', { username, attributeName, attributeValue }).then((response) => response.data);
