import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    searchStatusText: texts?.['spaces.status.dropdown.searchStatus'],
    emptyStatusPlaceholder: texts?.['spaces.select.status.empty.placeholder'] || 'Select Status',
    statusRequiredFieldsTooltipMessage: texts?.['spaces.status.required.fields.toaster-message'],
    mandatoryFieldError: texts?.['spaces.field.tooltip.error.mandatoryFieldError'] || 'Missing required field',
  };

  return componentTexts;
}
