import React, { useMemo, useEffect, CSSProperties } from 'react';
import Checkbox from '../../checkbox';
import Tooltip from '../../tooltip';
import useKeyPress from '../hooks/useKeyPress';
import { GridCellWrapper } from './GridCell.styles';

import { useOptionById, useSelectContext } from '../context';

type ColumnConfig = {
  header: string;
  valueKey: string;
  width: number;
};

type GridCellProps = {
  columnIndex: number;
  id: string;
  style: CSSProperties;
  column: ColumnConfig;
  isInFocus: boolean;
};

export const GridCell = ({ columnIndex, id, style, column, isInFocus }: GridCellProps): React.ReactElement => {
  const option = useOptionById(id);
  const enterPress = useKeyPress('Enter');

  // @ts-ignore
  const { selection, classString, styleString, toggleCheckOption } = useSelectContext();

  useEffect(() => {
    if (enterPress && isInFocus) {
      toggleCheckOption(id);
    }
  }, [enterPress, toggleCheckOption, isInFocus, id]);

  const isChecked = useMemo(
    () => (selection?.length ? Boolean(selection?.map(String).includes(String(id))) : false),
    [selection, id],
  );
  const title = option?.[column?.valueKey] || '';

  const textStyle = {
    ...(option ? option[styleString] : {}),
    width: style.width,
    display: 'flex',
    alignItems: 'start',
  };

  return (
    <GridCellWrapper style={style} onClick={() => toggleCheckOption(id)} data-testid={`${id}-grid-cell-wrapper`}>
      {columnIndex === 0 && <Checkbox checked={isChecked} data-testid={`${id}-grid-cell-checkbox`} />}
      <span color={option?.[classString]} style={textStyle}>
        <Tooltip data-testid={`${id}-grid-cell-title`} isTruncatedText title={title} text={title} placement="top" />
      </span>
    </GridCellWrapper>
  );
};
