import * as CryptoJS from 'crypto-js';
import { CHANNEL_MESSAGE_TYPES } from 'constants/common';

export const decryptData = ({ encryptedData, secretKey }) => {
  try {
    // Convert the base64-encoded encrypted data back to bytes
    const ciphertext = CryptoJS.enc.Base64.parse(encryptedData);
    // Create a key object from the provided key
    const keyBytes = CryptoJS.enc.Utf8.parse(secretKey);
    // Decrypt the data using AES
    const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    // Convert the decrypted bytes to a string and return
    return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Websockets: Error happened while decrypting data', { error, encryptedData, secretKey });
    throw error;
  }
};

export const generateSecretKey = ({ id, insertTime }) => {
  let secretKey = `${id}_${insertTime}`;
  const secretLength = 16;
  if (secretKey.length < secretLength) {
    secretKey = secretKey + secretKey.substring(0, secretLength - secretKey.length);
  } else if (secretKey.length > secretLength) {
    secretKey = secretKey.substring(0, secretLength);
  }
  return secretKey;
};

export const parseChannelData = ({ data, secretKey, name, prevData }) => {
  switch (name) {
    case CHANNEL_MESSAGE_TYPES.UPDATED: {
      const encryptedData = data?.encryptedData;
      const parsedData = decryptData({ encryptedData, secretKey });
      const newdata = { params: parsedData.params ?? {} };
      // We check if there were already pending updates for particular SR. It will happen if user in ZenMode
      // If yes then we will combine data updates
      if (prevData) {
        return {
          params: { ...prevData.params, ...newdata.params },
        };
      }
      return newdata;
    }
    default:
      return data;
  }
};
