import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAttachmentChipWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['disabled', 'showTitle', 'showDownloadBtn', 'showDeleteBtn'].includes(prop),
})(({
  theme,
  disabled,
  showTitle,
  showDownloadBtn,
  showDeleteBtn,
}) => ({
  fontSize: theme?.fontSize?.small,

  ...(disabled && {
    opacity: '0.5',
    pointerEvents: 'none',
  }),

  ...(!showTitle && {
    '.attach-fileUrl-text': {
      display: 'none',
    },
  }),

  ...(!showDownloadBtn && {
    '.download-btn': {
      display: 'none',
    },
  }),

  ...(!showDeleteBtn && {
    '.delete-btn': {
      display: 'none',
    },
  }),

  '.attachment-details': {
    display: 'none',
  },
}));
