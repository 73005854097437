import { Divider } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { isNone } from 'common/utils/utils';
import { getDate } from 'common/utils/dateTimeUtils';
import UserInfoCardHeader from './userInfoCardHeader';
import UserInfoCardDetails from './userInfoCardDetails';
import UserInfoCardTeamDetails from './userInfoCardTeamDetails';
import { StyledUserInfoCard } from './styles';
import useDateTimeInfo from '../../utils/hooks/useDateTimeInfo';

const componentId = 'user-info-card';

const UserInfoCard = ({ user = {}, team = {} }) => {
  const { calculatedUserName, firstName, lastName, profileImage, emailAddress, phone, timezone } = user;
  const { dateFormat } = useDateTimeInfo();
  const { teamName, teamMembers } = team;

  // TODO: get display name from backend;
  const displayName = calculatedUserName || `${firstName} ${lastName}`;

  return (
    <StyledUserInfoCard id={componentId}>
      {!isEmpty(user) && (
        <>
          <UserInfoCardHeader profileImage={profileImage} displayName={displayName} />
          <UserInfoCardDetails
            email={emailAddress}
            phoneNumber={phone}
            localTime={`${getDate(timezone, dateFormat)} ${timezone || ''}`}
          />
        </>
      )}
      {!isNone(teamName) && (
        <>
          <Divider />
          <UserInfoCardTeamDetails teamName={teamName} memberCount={teamMembers?.length} />
        </>
      )}
    </StyledUserInfoCard>
  );
};
export default UserInfoCard;
