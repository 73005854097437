import { useQuery } from '@tanstack/react-query';
import { FEATURE_FLAGS_KEYS } from 'constants/featureFlags';
import { getAIConfigData } from 'services/sysaiService';
import { QUERIES_KEYS } from 'constant';
import { useFeatureFlagQuery } from './featureFlagsHooks';

export const useGetAIConfigData = (configDataKey) => {
  const { data: isCopilotAutoEnable } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.COPILOT_AUTO_ENABLE,
    defaultValue: false,
  });
  const selectConfigData = (data) => {
    let assistiveAi = {
      AICaseSummarizations: true,
      AISuggestedCategory: true,
      AIAutoPopulate: true,
      AIEmotions: true,
      AIAuthor: true,
    };
    if (isCopilotAutoEnable && data.orgConf?.AssistiveAI) {
      if (data.orgConf.AssistiveAI.enable) {
        assistiveAi = data.orgConf.AssistiveAI;
      } else {
        Object.keys(assistiveAi).forEach((key) => {
          assistiveAi[key] = false;
        });
      }
    }
    switch (configDataKey) {
      case QUERIES_KEYS.IS_AI_SUMMARIZATIONS:
        return assistiveAi.AICaseSummarizations;
      case QUERIES_KEYS.IS_AI_SUGGESTED_CATEGORY:
        return assistiveAi.AISuggestedCategory;
      case QUERIES_KEYS.IS_AI_AUTO_POPULATE:
        return assistiveAi.AIAutoPopulate;
      case QUERIES_KEYS.IS_AI_EMOTIONS:
        return assistiveAi.AIEmotions;
      case QUERIES_KEYS.IS_AI_AUTHOR:
        return assistiveAi.AIAuthor;
      case QUERIES_KEYS.ASSISTIVE_AI:
        return assistiveAi;
      case QUERIES_KEYS.IS_AI_ADMINISTRATOR:
        return data.isAdministrator;
      default:
        return data.orgConf;
    }
  };

  return {
    ...useQuery({
      queryKey: ['AIConfigData'],
      queryFn: getAIConfigData,
      select: (data) => selectConfigData(data),
    }),
  };
};
