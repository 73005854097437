import { axiosInstance } from './axiosConfig';

export function getSrRelatedItems(srId) {
  return axiosInstance.get(`related_items/sr?srId=${srId}`).then((response) => response.data);
}

export function getRelatedItemsTypes() {
  return axiosInstance.get(`related-items/types`).then((response) => response.data);
}

export function unlinkSrRelatedItem({ srId, linkedItemId, relationship, itemType }) {
  if (!srId || !linkedItemId || !relationship || !itemType) return;
  const queryParams = new URLSearchParams();
  queryParams.append('linkedItemId', linkedItemId);
  queryParams.append('relationship', relationship);
  queryParams.append('itemType', itemType);
  return axiosInstance
    .delete(`related_items/sr/${srId}?${queryParams.toString()}`)
    .then((response) => response.data)
    .catch((error) => error);
}

export function linkSrRelatedSrItem({ linkedRelatedItems }) {
  if (!linkedRelatedItems?.srId || !linkedRelatedItems?.relatedItems?.length) return;
  return axiosInstance
    .post(`related_items/sr/sr`, linkedRelatedItems)
    .then((response) => response.data)
    .catch((error) => error);
}
