import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledUploadProgressBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ theme, error }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: '170px',
  gridArea: 'progressBar',
  gap: '12px',

  '.upload-file-wrapper': {
    maxWidth: '190px',
    minWidth: '190px',
    width: '190px',
  },
  '.upload-file-name span': {
    fontFamily: theme.fonts.robotoBold,
    fontSize: theme.fontSize.small,
    lineHeight: 1.667,
    color: error ? theme.palette.labels.bt_red_reg : theme.palette.text.secondary_txt,
    paddingBottom: theme.spacing(0.125),
    textAlign: 'start',
  },

  '.svg': {
    height: '16px',
    cursor: 'pointer',
  },

  '.MuiLinearProgress-root': {
    borderRadius: '2px',
    height: '5px',
    width: '100%',

    '& .MuiLinearProgress-bar': {
      background: error
        ? theme.palette.labels.bt_red_reg
        : `linear-gradient(270deg, ${theme.palette.generic.sysaid_default} 45.31%, #C3DCD4 100%)`,
    },
  },
}));

export const StyledError = styled('div')(({ theme }) => ({
  fontFamily: theme?.fonts?.robotoRegular,
  color: theme.palette.labels.bt_red_reg,
  display: 'flex',
  columnGap: theme.spacing(0.75),
  fontSize: theme.fontSize.small,
  gridArea: 'error',
  marginTop: '-2px',
  width: '100%',
  height: '40px',
  '.error-icon': {
    marginTop: '2px',
  },
  '.text-wrapper': {
    flex: '1',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    maxHeight: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
