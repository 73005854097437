import { useMemo, useRef, useState } from 'react';
import { ThemeProvider, useTheme } from '@mui/styles';

import { StyledDropDownDivMenu } from '../dropdownSelect/MenuContainer/MenuContainer.styles';
import { StyledMenu, AssigneeButtonWrapper } from './style';
import { AssigneeButton } from './assigneeButton';
import AssigneeSelect from './assigneeSelect';
import Tooltip from '../tooltip';

export default function Assignee(props) {
  const {
    CustomAssigneeButton,
    assigneeTitle,
    currentAssigned,
    filteredAssignees,
    filteredGroups,
    isMultiSelect,
    fieldId,
    onClick,
    subTitle,
    onClear,
    onSelectAll,
    required,
    disablePopover,
    bigAvatar,
    handleChangeAssignees,
    disabled,
    adminList,
    groupList,
    menuStyle,
    buttonStyle,
    setDropdownGroupName,
    dropdownGroupList,
    dropdownGroupName,
    anchorHtmlElementOuter,
    handleAssigneeClicked,
    isOutsideClickTriggered,
    disabledClear,
    isGridFilter,
    isQueueTableFilter,
    tooltipTitle,
    notValid = false,
    assigneeTextComponent = null,
    isSelectAllHidden,
    disableTooltipFocusListener,
  } = props;
  const [anchorHtmlElement, setAnchorHtmlElement] = useState(null);
  const assigneeRef = useRef();
  const theme = useTheme();

  const isBottomPosition = useMemo(() => {
    if (!disablePopover && anchorHtmlElement) {
      return window.innerHeight - anchorHtmlElement?.getBoundingClientRect().top <= 620; // 620 Max drop-down height
    }
    return false;
  }, [disablePopover, anchorHtmlElement]);

  const handleClick = (event) => {
    if (disabled) return;
    if (onClick) {
      onClick(fieldId);
    }
    setAnchorHtmlElement(event.currentTarget);
  };
  const handleClose = () => {
    if (handleAssigneeClicked) {
      handleAssigneeClicked();
    }
    setAnchorHtmlElement(null);
  };

  const handleChange = (newAssignee) => {
    handleChangeAssignees(newAssignee?.admin, newAssignee?.group);
    if (!isMultiSelect) {
      setAnchorHtmlElement(null);
    }
  };

  const handleKeyDown = () => {};

  const handleClearValue = () => {
    if (handleAssigneeClicked) {
      handleAssigneeClicked();
    }
    handleChangeAssignees(null, null);

    if (!isMultiSelect) {
      setAnchorHtmlElement(null);
    }
  };
  const isWideDropdown = isQueueTableFilter || isGridFilter;
  return (
    <ThemeProvider theme={theme}>
      {disablePopover ? (
        <StyledDropDownDivMenu isWideDropdown={isWideDropdown} withTitle={assigneeTitle}>
          <AssigneeSelect
            isQueueTableFilter={isQueueTableFilter}
            isWideDropdown={isWideDropdown}
            assigneeTitle={assigneeTitle}
            currentAssigned={currentAssigned}
            filteredAssignees={filteredAssignees}
            filteredGroups={filteredGroups}
            onChange={handleChange}
            handleClearValue={handleClearValue}
            isGridFilter={isGridFilter}
            disabledClear={disabledClear}
            subTitle={subTitle}
            isMultiSelect={isMultiSelect}
            onClear={onClear}
            onSelectAll={onSelectAll}
            required={required}
            adminList={adminList}
            groupList={groupList}
            dropdownGroupList={dropdownGroupList}
            dropdownGroupName={dropdownGroupName}
            setDropdownGroupName={setDropdownGroupName}
            menuStyle={menuStyle}
            isOutsideClickTriggered={isOutsideClickTriggered}
            isSelectAllHidden={isSelectAllHidden}
          />
        </StyledDropDownDivMenu>
      ) : (
        <>
          <Tooltip
            title={tooltipTitle}
            placement="top"
            disableHoverListener={!tooltipTitle}
            disableFocusListener={disableTooltipFocusListener}
            isError={notValid}
            className="assignee-tooltip-wrapper"
            text={
              <div
                ref={assigneeRef}
                role="button"
                tabIndex={0}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                className={`assignee-open-popup-button ${anchorHtmlElementOuter || anchorHtmlElement ? 'opened' : ''}`}
                data-testid="assignee-open-popup-button"
              >
                <AssigneeButtonWrapper buttonStyle={buttonStyle} isError={notValid} onClick={handleClick}>
                  {CustomAssigneeButton ? (
                    <CustomAssigneeButton />
                  ) : (
                    <AssigneeButton
                      currentAssigned={currentAssigned}
                      filteredAssignees={filteredAssignees}
                      filteredGroups={filteredGroups}
                      bigAvatar={bigAvatar}
                      disabled={disabled}
                    />
                  )}
                  {assigneeTextComponent && assigneeTextComponent(!!(anchorHtmlElementOuter || anchorHtmlElement))}
                </AssigneeButtonWrapper>
              </div>
            }
          />
          <StyledMenu
            anchorEl={anchorHtmlElementOuter || anchorHtmlElement}
            open={!!(anchorHtmlElementOuter || anchorHtmlElement)}
            onClose={handleClose}
            anchorOrigin={{ vertical: isBottomPosition ? 'top' : 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: isBottomPosition ? 'bottom' : 'top', horizontal: 'left' }}
            menuStyle={menuStyle}
            variant="menu"
            withTitle={assigneeTitle}
          >
            <AssigneeSelect
              isGridFilter={isGridFilter}
              disabledClear={disabledClear}
              subTitle={subTitle}
              assigneeTitle={assigneeTitle}
              currentAssigned={currentAssigned}
              filteredAssignees={filteredAssignees}
              filteredGroups={filteredGroups}
              onChange={handleChange}
              handleClearValue={handleClearValue}
              isMultiSelect={isMultiSelect}
              onClear={onClear}
              onSelectAll={onSelectAll}
              required={required}
              adminList={adminList}
              groupList={groupList}
              dropdownGroupName={dropdownGroupName}
              dropdownGroupList={dropdownGroupList}
              setDropdownGroupName={setDropdownGroupName}
              isOutsideClickTriggered={isOutsideClickTriggered}
            />
          </StyledMenu>
        </>
      )}
    </ThemeProvider>
  );
}
