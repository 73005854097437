import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import DropDownHeader from 'common/components/dropdownSelect/dropDownHeader';

import { UX_CONSTANTS } from '../../../../constants';
import { AdminList } from '../assigneeList/adminsList';
import { GroupList } from '../assigneeList/groupList';
import { CONSTANTS } from '../constants';
import { GroupDetails } from '../groupMenu';
import useTexts from '../useTexts';
import { AssigneeMenuWrapper, TeamsText } from './style';

const AssigneeSelect = (props) => {
  const {
    assigneeTitle,
    subTitle,
    disabledClear,
    currentAssigned,
    filteredAssignees,
    filteredGroups,
    onChange,
    isMultiSelect,
    onClear,
    onSelectAll,
    required,
    adminList,
    groupList,
    handleClearValue,
    dropdownGroupName,
    dropdownGroupList,
    setDropdownGroupName,
    isOutsideClickTriggered,
    isQueueTableFilter,
    isGridFilter,
    isWideDropdown,
    isSelectAllHidden,
  } = props;

  const { defaultSearchPlaceholder, drillDownSearchPlaceholder, teamsText, clearText, clearAllText, selectAll } =
    useTexts();
  const [searchString, setSearchString] = useState('');
  const [selectButton, setSelectButton] = useState(true);
  const [clearButton, setClearButton] = useState(true);
  const adminsListRef = useRef(null);

  const maxItemsToShow =
    adminList?.length > 0 && groupList?.length > 0
      ? CONSTANTS.DROPDOWN_GROUPS_AND_ADMINS_SCROLL_THRESHOLD
      : CONSTANTS.DROPDOWN_SCROLL_THRESHOLD;

  let isSearchVisible;
  if (dropdownGroupName) {
    isSearchVisible = dropdownGroupList?.length > UX_CONSTANTS.SEARCH_VISIBILITY_THRESHOLD;
  } else {
    const groupAndAdminLength = groupList?.length + adminList?.length;
    if (groupAndAdminLength) {
      isSearchVisible = groupList?.length + adminList?.length > UX_CONSTANTS.SEARCH_VISIBILITY_THRESHOLD;
    } else {
      isSearchVisible =
        adminList?.length > UX_CONSTANTS.SEARCH_VISIBILITY_THRESHOLD ||
        groupList?.length > UX_CONSTANTS.SEARCH_VISIBILITY_THRESHOLD;
    }
  }

  useEffect(() => {
    if (isOutsideClickTriggered) {
      setSearchString('');
    }
  }, [isOutsideClickTriggered]);

  const filterBySearchString = useCallback(
    (list, captionPropName) => {
      if (list?.length) {
        const startsWithList = [];
        const includesList = [];
        list?.forEach((item) => {
          if (item[captionPropName].toLowerCase().startsWith(searchString.toLowerCase())) {
            startsWithList.push(item);
          } else if (item[captionPropName].toLowerCase().includes(searchString.toLowerCase())) {
            includesList.push(item);
          }
        });
        //Display the first few values from the list according to the constant: DROPDOWN_MAX_ITEMS_TO_DISPLAY
        //If there are only admins or only groups, then a double number of values are displayed.
        if (groupList?.length && adminList?.length) {
          return startsWithList.concat(includesList).slice(0, CONSTANTS.DROPDOWN_MAX_ITEMS_TO_DISPLAY / 2);
        }
        return startsWithList.concat(includesList).slice(0, CONSTANTS.DROPDOWN_MAX_ITEMS_TO_DISPLAY);
      }
    },
    [adminList?.length, groupList?.length, searchString],
  );

  const { showAdmins, showGroups, filteredBySearchAdmins, filteredBySearchGroups } = useMemo(() => {
    const filteredBySearchAdmins = filterBySearchString(adminList, 'calculatedUserName');
    const filteredBySearchGroups = filterBySearchString(groupList, 'groupName');
    return {
      filteredBySearchAdmins,
      filteredBySearchGroups,
      showAdmins: filteredBySearchAdmins?.length > 0,
      showGroups: filteredBySearchGroups?.length > 0,
    };
  }, [filterBySearchString, adminList, groupList]);

  const displayLineAndTeamsTitle = () => {
    if (showAdmins && showGroups && adminList) {
      return (
        <>
          <Divider />
          <TeamsText>{teamsText}</TeamsText>
        </>
      );
    }
    if (showAdmins && showGroups) {
      return <Divider />;
    }
    if (adminList && showGroups) {
      return <TeamsText>{teamsText}</TeamsText>;
    }
  };

  const clearValueHandler = () => {
    if (handleClearValue) {
      handleClearValue();
    }
  };

  const onClickBackFromDrillDown = () => {
    setDropdownGroupName(null);
    setSearchString('');
  };

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
  };
  const onSearchClear = () => {
    setSearchString('');
  };
  const handleClickGroup = (option) => {
    if (isMultiSelect || isGridFilter) {
      onChange(option, 'group');
    } else if (!isGridFilter) {
      setDropdownGroupName(option.group.groupName);
      setSearchString('');
    }
  };

  useEffect(() => {
    // length + 1 is for unassigned dummy assignee
    setClearButton(currentAssigned?.admin || filteredAssignees?.length || filteredGroups?.length);
    setSelectButton(filteredAssignees?.length !== adminList?.length || filteredGroups?.length !== groupList?.length);
  }, [filteredAssignees, filteredGroups, currentAssigned?.admin, adminList?.length, groupList?.length]);

  return (
    <AssigneeMenuWrapper
      isQueueTableFilter={isQueueTableFilter}
      isWideDropdown={isWideDropdown}
      className="assignee-menu"
    >
      <DropDownHeader
        title={assigneeTitle}
        subTitle={subTitle}
        isSearchVisible={isSearchVisible}
        isActionsVisible
        searchFieldProps={{
          value: searchString,
          placeholderText: dropdownGroupName ? drillDownSearchPlaceholder : defaultSearchPlaceholder,
          onChange: onSearchChange,
          onClear: onSearchClear,
          inputProps: {
            'data-testid': 'assignee-search-field',
          },
          autoFocus: true,
        }}
        actionsProps={{
          isMultiSelect,
          isDirty: false,
          isClearHidden: disabledClear,
          isClearDisabled: !clearButton,
          isSelectAllDisabled: !selectButton,
          isSelectAllHidden,
          clearText,
          clearAllText,
          selectAllText: selectAll,
          handleClearAll: () => onClear(filteredBySearchAdmins, filteredBySearchGroups, Boolean(searchString)),
          clearValueHandler,
          handleSelectAll: () => {
            const allAdminFromRef = adminsListRef.current.getAllAdmins();
            onSelectAll(allAdminFromRef, filteredBySearchGroups, Boolean(searchString));
          },
        }}
      />
      {dropdownGroupName ? (
        <GroupDetails
          groupName={dropdownGroupName}
          numberOfMembers={dropdownGroupList.length}
          memberList={filterBySearchString(dropdownGroupList, 'calculatedUserName')}
          currentAssigned={currentAssigned}
          onClickBack={onClickBackFromDrillDown}
          handleChange={onChange}
          showAssignToTeam={!searchString && !isGridFilter}
          filteredAssignees={filteredAssignees}
          isGridFilter={isGridFilter}
        />
      ) : (
        <>
          {adminList?.length > 0 && (
            <AdminList
              ref={adminsListRef}
              adminsList={filteredBySearchAdmins}
              handleChange={onChange}
              currentAssigned={currentAssigned}
              isSearching={!searchString}
              isEmptyResults={groupList ? !showAdmins && !showGroups : !showGroups}
              maxHeight={maxItemsToShow}
              showCheckboxes={isMultiSelect}
              filteredAssignees={filteredAssignees}
              required={required}
            />
          )}
          <Box style={{ padding: '0 8px' }}>{displayLineAndTeamsTitle()}</Box>
          {groupList?.length > 0 && showGroups && (
            <GroupList
              groupList={filteredBySearchGroups}
              handleChange={handleClickGroup}
              currentAssigned={currentAssigned}
              showUnassigned={!required && !adminList && !searchString}
              isEmptyResults={!adminList && !showGroups}
              maxHeight={maxItemsToShow}
              showCheckboxes={isMultiSelect}
              isGridFilter={isGridFilter}
              filteredGroups={filteredGroups}
            />
          )}
        </>
      )}
    </AssigneeMenuWrapper>
  );
};

export default AssigneeSelect;
