import { Box, LinearProgress } from '@mui/material';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { ReactComponent as ErrorIcon } from 'images/icons/errorIcon.svg';

import Tooltip from 'common/components/tooltip';
import { StyledUploadProgressBar, StyledError } from './style';

const MAX_VALUE = 100;

export function UploadProgressBar({ id, fileName, value, error, onClose = () => {} }) {
  const handleClose = () => {
    onClose(id);
  };

  return (
    <>
      <StyledUploadProgressBar className="progress-bar-wrapper" error={!!error} key={id}>
        <Box className="upload-file-wrapper">
          <div className="upload-file-name">
            <Tooltip text={fileName} isTruncatedText />
          </div>
          <LinearProgress variant="determinate" value={error ? MAX_VALUE : value || 0} />
        </Box>
        <div className="svg">
          <CloseIcon onClick={handleClose} />
        </div>
      </StyledUploadProgressBar>
      {error && (
        <StyledError className="progress-bar-error-wrapper">
          <ErrorIcon className="error-icon" />
          <div className="text-wrapper">{error}</div>
        </StyledError>
      )}
    </>
  );
}
