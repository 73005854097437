import { styled } from '@mui/material/styles';
import { Box, Chip } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const StyledChipContainer = styled(Chip)(({ theme, isSelected }) => ({
  height: '20px',
  marginLeft: '10px',
  color: isSelected ? 'white' : theme.palette.generic.sysaid_default_hover,
  backgroundColor: isSelected ? theme.palette.generic.sysaid_default_hover : theme.palette.generic.sysaid_very_light,
}));

export const StyledToggleButtonMore = styled(({ ...props }) => <ToggleButton {...props} />, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  display: 'flex',
  justifyContent: 'center',
  columnGap: '5px',

  '&&&&': {
    backgroundColor: isSelected ? theme.palette.generic.sysaid : theme.palette.generic.primary,
    border: 'none',
    color: isSelected ? 'white' : theme.palette.generic.sysaid,
    textTransform: 'none',
    padding: theme.spacing(1.25, 2.25, 1.25, 2.5),
    position: 'relative',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    width: '142px',

    '&:hover': {
      backgroundColor: isSelected
        ? theme.palette.generic.sysaid_default_hover
        : theme.palette.generic.sysaid_very_light,
    },
    '& svg': {
      height: '20px',
      width: '20px',
    },
  },

  '&&&& .MuiTypography-root': {
    fontSize: '14px',
    fontFamily: theme.fonts.robotoMedium,
  },
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '&& .MuiButtonBase-root': {
    backgroundColor: theme?.palette?.generic?.primary,
    color: theme?.palette?.generic?.sysaid,
    paddingLeft: '30px',
    paddingRight: '20px',
    lineHeight: 1,
  },
}));

export const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => {
  let backgroundColor = 'white';
  let color = theme.palette.generic.sysaid;
  const buttonWidth = '142px';

  if (selected) {
    backgroundColor = theme.palette.generic.sysaid;
    color = theme.palette.generic.primary;
  } else {
    backgroundColor = theme.palette.generic.primary;
    color = theme.palette.generic.sysaid;
  }

  return {
    display: 'flex',
    justifyContent: 'center',
    columnGap: '5px',
    '&&&&': {
      marginRight: '2px',
      backgroundColor,
      border: 'none',
      fontSize: '14px',
      fontFamily: theme.fonts.robotoBold,
      color,
      textTransform: 'none',
      padding: theme.spacing(1.25, 0),
      maxWidth: '220px',
      width: buttonWidth,

      '&:hover': {
        backgroundColor: selected
          ? theme.palette.generic.sysaid_default_hover
          : theme.palette.generic.sysaid_very_light,
      },

      '&:first-of-type': {
        maxWidth: '112px',
      },
    },
    '&:first-of-type': {
      borderTopLeftRadius: '40px',
      borderBottomLeftRadius: '40px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px',
    },

    '&&&& .MuiTypography-root': {
      fontSize: '14px',
      fontFamily: theme.fonts.robotoMedium,
      lineHeight: '20px',
    },
  };
});

export const StyledTicketNumber = styled(({ ...props }) => <div {...props} />, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isIncidentLabel',
})(({ theme, isSelected, isIncidentLabel }) => {
  let backgroundColor;
  let color;

  if (isIncidentLabel) {
    backgroundColor = theme.palette.generic.sysaid_very_light;
    color = theme.palette.generic.sysaid;
  } else if (isSelected) {
    backgroundColor = theme.palette.generic.sysaid_default_hover;
    color = theme.palette.generic.primary;
  } else {
    backgroundColor = theme.palette.generic.sysaid_very_light;
    color = theme.palette.generic.sysaid_default_hover;
  }

  return {
    backgroundColor,
    color,
    borderRadius: '10px',
    fontSize: theme?.fontSize?.small,
    letterSpacing: '0.035em',
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.25, 0.75),
    fontFamily: theme.fonts.robotoRegular,
  };
});

export const StyledIncidentLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '5px',
  lineHeight: '17px',

  '&&&& .MuiTypography-root': {
    fontSize: theme?.fontSize?.main,
    fontFamily: theme.fonts.robotoMedium,
  },
}));
