import { actionEnums } from '../constants/actionEnums';
import { convertCamelCaseToSpaces } from '../utils';
import useTexts from '../../../useTexts';

import { StyledLogTitle } from './style';
import { journeyConstants } from '../constants/journey';
import { CUSTOM_FIELD_PREFIX } from '../../../../srPanel/consts';

const checkCategoryFieldName = (fieldName, texts) => {
  const {
    changedText,
    categoryText,
    subCategoryText,
    thirdLevelCategoryTitle,
    primaryCategoryText,
    secondaryCategoryText,
  } = texts;
  if (fieldName === primaryCategoryText) {
    return `${categoryText} ${changedText}`;
  }
  if (fieldName === secondaryCategoryText) {
    return `${subCategoryText} ${changedText}`;
  }
  // thirdLevelCategoryText
  return `${thirdLevelCategoryTitle} ${changedText}`;
};

const renderLogTitle = (auditLog, texts) => {
  const {
    changedText,
    ticketText,
    statusText,
    priorityText,
    assignmentText,
    openEvent,
    closeEvent,
    reopenEvent,
    viewEvent,
    dueDateEvent,
    mainAssetText,
    mainCiText,
    deescalation,
    escalation,
    noteText,
    deletedText,
    editedText,
    attachmentAdded,
    attachmentsDeleted,
    attachmentsDownloaded,
    notificationSent,
    actionItemNotificationSent,
    closedInDueDate,
    closedNotInDueDate,
    newArticleCreated,
    solutionEdited,
    resolutionEdited,
    getColumnText,
    activityAdded,
    linkedAsText,
    unlinkedText,
    getRelationshipText,
  } = texts;
  switch (auditLog?.logType) {
    // field change
    case actionEnums.NOTE_CREATE:
    case actionEnums.MESSAGE_SENT:
    case actionEnums.AI_MESSAGE_SEND:
    case actionEnums.MESSAGE_RECEIVED:
    case actionEnums.IM_MESSAGE:
    case actionEnums.SMS_MESSAGE:
    case actionEnums.ITIL_MESSAGE:
    case actionEnums.SOLUTION_RESOLUTION_CREATED:
    case actionEnums.FIELD_REASSIGNMENT:
    case actionEnums.FIELD_OUT_OF_OFFICE:
    case actionEnums.TICKET_MIGRATION_HISTORY:
    case actionEnums.TICKET_CONVERSION:
      return null;
    case actionEnums.FIELD_STATUS:
    case actionEnums.SOLUTION_STATUS_CHANGED:
      return `${statusText} ${changedText}`;
    case actionEnums.FIELD_PRIORITY:
      return `${priorityText} ${changedText}`;
    case actionEnums.FIELD_ASSIGNMENT:
    case actionEnums.SOLUTION_ASSIGNEE_CHANGED:
      return `${assignmentText} ${changedText}`;
    case actionEnums.NOTE_DELETE:
      return `${noteText} ${deletedText}`;
    case actionEnums.NOTE_UPDATE:
      return `${noteText} ${editedText}`;
    case actionEnums.FIELD_CATEGORY:
      return checkCategoryFieldName(auditLog.logInformation?.fieldName, texts);
    case actionEnums.FIELD_REGULAR: {
      if (auditLog.logInformation?.fieldName === journeyConstants.CI_ID) {
        return `${mainCiText} ${changedText}`;
      }
      if (auditLog.logInformation?.fieldName === journeyConstants.ASSET_ID) {
        return `${mainAssetText} ${changedText}`;
      }
      const fieldText =
        (getColumnText &&
          auditLog.logInformation?.fieldDisplayName &&
          getColumnText(auditLog.logInformation?.fieldDisplayName)) ||
        (auditLog.logInformation?.fieldName.includes(CUSTOM_FIELD_PREFIX) &&
          auditLog.logInformation?.fieldDisplayName) ||
        convertCamelCaseToSpaces(auditLog.logInformation?.fieldName);
      return `${fieldText} ${changedText}`;
    }
    // ticket event
    // action in past tense
    case actionEnums.OPERATION_CREATE:
      return `${ticketText} ${openEvent}`;
    case actionEnums.EVENT_CLOSE:
      return `${ticketText} ${closeEvent}`;
    case actionEnums.EVENT_REOPEN:
      return `${ticketText} ${reopenEvent}`;
    case actionEnums.EVENT_DUE_DATE:
      return `${ticketText} ${dueDateEvent}`;
    case actionEnums.EVENT_VIEW:
      return `${ticketText} ${viewEvent}`;
    case actionEnums.ATTACHMENTS_ADDED:
    case actionEnums.ACTION_ITEM_ATTACHMENTS_ADDED:
      return `${attachmentAdded}`;
    case actionEnums.ACTION_ITEM_ATTACHMENTS_DELETED:
    case actionEnums.ATTACHMENTS_DELETED:
      return `${auditLog.logInformation.attachments.length} ${attachmentsDeleted}`;
    case actionEnums.ATTACHMENTS_DOWNLOADED:
      return `${auditLog.logInformation.attachments.length} ${attachmentsDownloaded}`;
    case actionEnums.ESCALATION_RESET:
      return deescalation;
    case actionEnums.ESCALATION_CHANGED:
      return `${escalation} ${changedText}`;
    case actionEnums.NOTIFICATION:
    case actionEnums.ESCALATION_MESSAGE:
      if (auditLog.logInformation.workflowActionItemCaption)
        return `${actionItemNotificationSent} ${auditLog.logInformation.workflowActionItemCaption}`;
      return notificationSent;
    case actionEnums.SOLUTION_SR_RESOLVED:
      return auditLog.logInformation?.isClosedDueDate ? closedInDueDate : closedNotInDueDate;
    case actionEnums.SOLUTION_NEW_ARTICLE_CREATED:
      return newArticleCreated;
    case actionEnums.SOLUTION_UPDATED:
      return solutionEdited;
    case actionEnums.RESOLUTION_UPDATED:
      return resolutionEdited;
    case actionEnums.ACTION_ITEM_STATUS_COMPLETED:
    case actionEnums.ACTION_ITEM_STATUS_ENABLED:
      return auditLog.logInformation.actionItemName;
    case actionEnums.ACTIVITY_CREATED:
      return activityAdded;
    case actionEnums.RELATED_ITEM_SR_CREATED:
      return `${ticketText} ${linkedAsText} ${getRelationshipText(Object.keys(auditLog?.logInformation)?.[0])}`;
    case actionEnums.RELATED_ITEM_SR_DELETED:
      return `${ticketText} ${unlinkedText}`;
    default: {
      const fieldText =
        (getColumnText &&
          auditLog.logInformation?.fieldDisplayName &&
          getColumnText(auditLog.logInformation?.fieldDisplayName)) ||
        convertCamelCaseToSpaces(auditLog.logInformation?.fieldName);
      return `${fieldText} ${changedText}`;
    }
  }
};

export default function LogTitle({ auditLog, isEditorAction }) {
  const texts = useTexts();
  const logTitle = renderLogTitle(auditLog, texts);

  const renderLogSubTitle = () => {
    switch (auditLog?.logType) {
      case actionEnums.ACTION_ITEM_STATUS_COMPLETED:
      case actionEnums.ACTION_ITEM_STATUS_ENABLED:
        return `${texts.statusText} ${texts.changedText}`;
      default:
        return null;
    }
  };

  const logSubTitle = renderLogSubTitle();

  if (!logTitle) return null;

  return (
    <StyledLogTitle
      isEditorAction={isEditorAction}
      isWorkflowNotification={auditLog.logInformation.workflowActionItemCaption}
    >
      <div className="title-text">
        {logTitle}
        {logSubTitle && <span className="sub-title-text">{logSubTitle}</span>}
        {auditLog?.logType === actionEnums.OPERATION_CREATE && (
          <span className="by-text"> {` ${texts.auditLogBy}`}</span>
        )}
      </div>
    </StyledLogTitle>
  );
}
