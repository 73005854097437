import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { DropdownSelect, itemClassNames, useDropdownControl } from 'common/components/dropdownSelect';
import { useGetListValueById } from 'common/utils/hooks/useGetListValueById';
import Tooltip from 'common/components/tooltip';
import { getFieldAttributes } from 'services/ticketService';
import { updateSRStatus } from 'store/srSlice';
import useTexts from './useTexts';
import { Label } from './style';

function Status(props) {
  const {
    fieldId,
    status,
    statusList,
    onFocus,
    isTemplatePage,
    dataset = {},
    disabled,
    srType,
    handleDirty,
    isFloatingTicketPanel,
    updateFieldValidation,
    onChange,
    dataTestid = 'status',
    forceStopuseStateStore = false,
  } = props;
  const dispatch = useDispatch();
  const { statusRequiredFieldsTooltipMessage, emptyStatusPlaceholder, mandatoryFieldError } = useTexts();
  const isError = !status && !isTemplatePage;
  const srStatus = statusList?.find((stsItem) => Number(stsItem.id) === Number(status));

  const handleFocus = useCallback(() => {
    onFocus(fieldId);
  }, [onFocus, fieldId]);

  const { anchor, handleOpen, handleClose } = useDropdownControl(disabled, handleFocus);
  const { data } = useGetListValueById({ listKey: fieldId, list: statusList, id: status });

  const checkAndUpdateValidationStatus = useCallback(
    (value) => {
      let isValid = true;
      if (!value) {
        isValid = false;
      }

      if (updateFieldValidation && !isTemplatePage) {
        updateFieldValidation('status', true, isValid);
      }
      return isValid;
    },
    [isTemplatePage, updateFieldValidation],
  );

  useEffect(() => {
    if (!forceStopuseStateStore) {
      dispatch(updateSRStatus(data));
    }
    const identifier = data?.id || data?.valueKey || null;
    checkAndUpdateValidationStatus(identifier);
  }, [data, checkAndUpdateValidationStatus, dispatch, forceStopuseStateStore]);

  const handleChange = useCallback(
    (value) => {
      const identifier = value.id || value.valueKey;
      checkAndUpdateValidationStatus(identifier);
      if (handleDirty) handleDirty({ status: Number(identifier) });
      if (!forceStopuseStateStore) {
        dispatch(updateSRStatus(value));
      }
      if (onChange) {
        onChange(value);
      }
      handleClose();
    },
    [checkAndUpdateValidationStatus, handleDirty, onChange, handleClose, dispatch, forceStopuseStateStore],
  );

  const handleClear = useCallback(() => {
    checkAndUpdateValidationStatus(null);
    if (!forceStopuseStateStore) {
      dispatch(updateSRStatus(null));
    }
    if (onChange) {
      onChange({ id: null });
    }
    handleClose();
  }, [onChange, handleClose, dispatch, checkAndUpdateValidationStatus, forceStopuseStateStore]);

  const onOpen = (event) => {
    if (!disabled) {
      handleOpen(event);
    }
  };

  const getTooltipText = () => {
    let text = '';
    if (isError) {
      text = mandatoryFieldError;
    } else if (disabled) {
      text = statusRequiredFieldsTooltipMessage;
    } else if (srStatus?.valueClass) {
      text = srStatus.valueClass;
    }
    return text;
  };

  const selection = useMemo(() => (srStatus?.id || srStatus?.id === 0 ? [srStatus?.id] : []), [srStatus?.id]);

  const backendQueryConfig = useMemo(
    () => ({
      fetchingPromise: (query) => getFieldAttributes(fieldId, { query, srType }),
    }),
    [fieldId, srType],
  );

  useEffect(() => {
    checkAndUpdateValidationStatus(srStatus?.value || null);
  }, [checkAndUpdateValidationStatus, srStatus]);


  return (
    <>
      <Label
        marginLeft={Boolean(isTemplatePage || isFloatingTicketPanel) && '12px'}
        stylingVariant={itemClassNames.status}
        color={srStatus?.valueClass}
        onClick={onOpen}
        isTemplatePage={isTemplatePage}
        isReadOnly={disabled}
        data-testid={dataTestid}
        isEmpty={isEmpty(srStatus)}
        data-cy={`status-item-${selection}`}
        {...dataset}
      >
        <Tooltip
          isError={isError}
          title={getTooltipText()}
          text={srStatus?.value || srStatus?.valueCaption || emptyStatusPlaceholder}
          forceShowOnHover={disabled || isError}
          isTruncatedText
          placement="top"
          style={{ fontFamily: 'Roboto-Bold' }}
        />
      </Label>

      <DropdownSelect
        fieldName="status"
        options={statusList}
        handleChange={handleChange}
        handleClose={handleClose}
        selection={selection}
        stylingVariant={itemClassNames.status}
        anchor={anchor}
        backendQueryConfig={backendQueryConfig}
        handleClearValue={handleClear}
        isActionsHidden={!isTemplatePage}
      />
    </>
  );
}

export default Status;
