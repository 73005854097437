import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { selectTheme } from 'store/globalSlice';
import { useSelector } from 'react-redux';
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';

export default function Theme({ children }) {
  const themeSlice = useSelector(selectTheme);
  const [currentTheme, setCurrentTheme] = useState(lightTheme);

  useEffect(() => {
    if (themeSlice === 'light') {
      setCurrentTheme(lightTheme);
    } else {
      setCurrentTheme(darkTheme);
    }
  }, [themeSlice]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}
