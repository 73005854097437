export const classesStyles = {
  icon: {},
  svgIcon: {
    width: '36px',
    height: '36px',
  },
  svgIconSmall: {
    width: '25px',
    height: '25px',
  },
  promptDisplay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',

    '& > div': {
      height: '36px',
    },
  },
  promptDisplayCreatedTicket: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',

    '& > div': {
      height: '51px',
    },
  },
  okBtn: {
    margin: '0 0 auto 24px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    padding: 0,
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};
