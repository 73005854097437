export const attachmentIcons = {
    bmp: 'image',
    gif: 'image',
    ico: 'image',
    svg: 'image',
    jpeg: 'image',
    jpg: 'image',
    png: 'image',
    psd: 'image',
    ai: 'image',
    indd: 'image',
    raw: 'image',
    webp: 'image',
    odt: 'text',
    rtf: 'text',
    tex: 'text',
    txt: 'text',
    wpd: 'text',
    xlsx: 'xls',
    xls: 'xls',
    pdf: 'pdf',
    webm: 'video',
    mkv: 'video',
    flv: 'video',
    vob: 'video',
    ogv: 'video',
    ogg: 'video',
    drc: 'video',
    gifv: 'video',
    mng: 'video',
    avi: 'video',
    mov: 'video',
    qt: 'video',
    wmv: 'video',
    yuv: 'video',
    rm: 'video',
    rmvb: 'video',
    viv: 'video',
    asf: 'video',
    amv: 'video',
    mp4: 'video',
    m4v: 'video',
    mpg: 'video',
    mp2: 'video',
    mpeg: 'video',
    mpe: 'video',
    mpv: 'video',
    svi: 'video',
    '3gp': 'audio',
    aa: 'audio',
    aax: 'audio',
    act: 'audio',
    aiff: 'audio',
    alac: 'audio',
    amr: 'audio',
    dvf: 'audio',
    flac: 'audio',
    gsm: 'audio',
    m4p: 'audio',
    m4b: 'audio',
    mp3: 'audio',
    mpc: 'audio',
    wav: 'audio',
    wma: 'audio',
    wv: 'audio',
    cda: 'audio',
    docx: 'word',
    doc: 'word',
    pptx: 'ppt',
    ppt: 'ppt',
    zip: 'archive',
    rar: 'archive',
    '7z': 'archive',
    tar: 'archive',
};

export const fileIcon = (extension) => {
    const DEFAULT_FILE_ICON = 'defaultFileIcon';
    return attachmentIcons[extension?.toLowerCase()] ? attachmentIcons[extension?.toLowerCase()] : DEFAULT_FILE_ICON;
};
