export const ITEM_TYPES = {
  SR: { id: 1, key: 'sr' },
  ASSET: { id: 5, key: 'asset' },
  CI: { id: 6, key: 'ci' },
};

export const TABLE_ROW_HEIGHT = 45;
export const TABLE_MAX_ROWS = 5;
export const MAX_LINKED_ITEMS = 50;

export const DEFAULT_ITEM_TYPE = ITEM_TYPES.SR;

export const RELATED_ITEMS_COLLAPSED_SESSION_KEY = 'relatedItemsCollapsed';
export const OMIT_API_SEARCH = -1;
export const FIELD_ATTRIBUTES = { fieldName: 'relatedItems', fieldId: OMIT_API_SEARCH };

export const RELATIONSHIP_ENUMS = {
  1: 'none',
  2: 'causes',
  3: 'causedBy',
  4: 'parent',
  5: 'child',
  6: 'relatesTo',
  7: 'blocks',
  8: 'blockedBy',
  9: 'mergedFrom',
  10: 'mergedTo',
};

export const RELATIONSHIP_TYPES = {
  // NONE: 1,
  // CAUSED: 2,
  // CAUSED_BY: 3,
  PARENT: 4,
  CHILD: 5,
  // RELATES_TO: 6,
  // BLOCKED: 7,
  // BLOCKED_BY: 8,
  MERGED_FROM: 9,
  MERGED_TO: 10,
};
