import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { AvatarGroup } from '@mui/material';
import { AvatarGroupWrapper } from 'common/components/avatars/avatarGroupWrapper';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import Tooltip from 'common/components/tooltip';
import User from 'common/components/user';
import { selectActiveUser } from 'store/userSlice';
import separatorArrow from 'images/icons/values-seperator-arrow.svg';
import { CategorySelect } from 'features/header/categories/categorySelect';
import DueDateField from 'common/components/dueDate';
import { ConditionalWrapper, isNone, toDisplayUser } from 'common/utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import { getCustomHeaderStyles } from 'features/header/categories/style';
import { PRIORITY_FIELD_ID, STATUS_FIELD_ID } from 'features/srPanel/consts';
import {
  useClosureInformationList,
  useFieldAttributes,
  useStatusList,
  useTicketTemplateData,
  useUserDetailsByName,
} from 'remote-state/ticketServiceHooks';
import { Label as StatusLabel } from 'common/components/status/style';
import { Label as PriorityLabel } from 'common/components/priority/style';
import { itemClassNames } from 'common/components/dropdownSelect';
import { getCustomValueList, getFieldAttributes } from 'services/ticketService';
import { AVATAR_CONSTANTS } from 'constants/index';
import { actionEnums } from '../../constants/actionEnums';
import useTexts from '../../../../useTexts';
import { StyledArrowDivider, StyledAuditLogFieldChange } from './style';
import { journeyConstants } from '../../constants/journey';
import { RichTextField } from './richTextField';
import { useDisplayTextTruncation } from './hooks/useDisplayTextTruncation';
import { LogDescriptionItem } from '../style';
import TruncatedTextButton from '../editor/truncatedTextButton';
import { useAuditLog } from '../../hooks';

const IS_NOT_REGULAR_FIELDS = ['title'];

export default function AuditLogFieldChange(props) {
  const router = useRouter();
  const {
    logInformation,
    logType,
    isRichTextTruncated = true,
    auditLogId,
    srType,
    oldWorkflowLog,
    newWorkflowLog,
  } = props;
  const { oldKey, newKey, oldValue, newValue, camelCaseFieldName, fieldName, listType, isValueId, chipItem } =
    logInformation;
  const { toggleAuditLogsProperty } = useAuditLog();
  const { meText, unassignedText, emptyAuditLogValueText, requestUserTexts } = useTexts();
  const isDynamicList = listType === journeyConstants.DYNAMIC_LIST;
  const isWorkflowListChange = logType === actionEnums.ACTION_ITEM_FIELD_CHANGE && (isDynamicList || isValueId); //isValueId is for regular selects/dropdowns
  const statusListInput = useMemo(() => ({ srType }), [srType]);
  const { data: statusList } = useStatusList(statusListInput);
  const fieldAttributesInput = useMemo(() => [{ fieldId: PRIORITY_FIELD_ID, srType }], [srType]);
  const {
    data: { values: priorityList },
  } = useFieldAttributes(fieldAttributesInput)?.[0] || { data: { values: [] } };

  const derivedFieldName = camelCaseFieldName || fieldName;
  const oldDerivedValue = oldWorkflowLog || (isDynamicList ? oldKey : oldValue);
  const newDerivedValue = newWorkflowLog || (isDynamicList ? newKey : newValue);

  const { data: closureInformationList } = useClosureInformationList();
  const srId = router.latestLocation.search.id;
  const { data: template } = useTicketTemplateData(srId);

  const fields = useMemo(
    () => template?.sections?.flatMap((section) => section.sectionRows.flatMap((row) => row.fields)),
    [template?.sections],
  );

  const fieldToValuesMap = useFieldAttributes(fields).reduce((acc, field) => {
    if (field?.data?.fieldName && field.data.values) {
      acc[field.data.fieldName] = field.data.values;
    }
    return acc;
  }, {});

  const fieldChangeRef = useRef(null);
  const currentUser = useSelector(selectActiveUser);
  const { showTruncationButton } = useDisplayTextTruncation({
    divRef: fieldChangeRef,
    logType,
    isRichText: logInformation?.isRichText,
  });

  const { data: newUserDetails } = useUserDetailsByName(logInformation?.newValue?.admin);
  const { data: oldUserDetails } = useUserDetailsByName(logInformation?.oldValue?.admin);
  const queryClient = useQueryClient();

  const isCurrentUserAdmin = (admin, currentUser) => admin?.toLowerCase() === currentUser?.toLowerCase();

  // render dynamic list value - if user language is one of the language captions we saved in DB then return the caption based on user language,
  // else - find the caption by key in the relevant list and return the caption
  const renderDynamicListValue = useCallback(
    (fieldName, valueKey, isPreviousValue) => {
      const relevantValueClass = isPreviousValue ? logInformation.oldColor : logInformation.newColor;

      let fieldList;
      let fieldId;
      let record;
      if (isWorkflowListChange) {
        record = valueKey;
      } else {
        if (logInformation.sectionField) {
          fieldList = fieldToValuesMap[fieldName] || [];
          record = Array.isArray(fieldList) && fieldList?.find((obj) => obj?.id === valueKey);
        } else {
          if (fieldName === journeyConstants.STATUS) {
            fieldList = statusList;
            fieldId = STATUS_FIELD_ID;
          } else if (fieldName === journeyConstants.PRIORITY) {
            fieldList = priorityList;
            fieldId = PRIORITY_FIELD_ID;
          } else if (fieldName === actionEnums.SOLUTION_CLOSURE_INFORMATION_CHANGED) {
            fieldList = closureInformationList;
          }
          record =
            Array.isArray(fieldList) && fieldList?.find((obj) => obj.id === valueKey || obj.valueKey === valueKey);
        }
        if (!record) {
          if ([journeyConstants.STATUS, journeyConstants.PRIORITY].includes(fieldName)) {
            record = getFieldAttributes(fieldId, { id: valueKey })?.[0] || null;
          } else if (logType === actionEnums.SOLUTION_CLOSURE_INFORMATION_CHANGED) {
            record = getCustomValueList('closureInformation', { id: valueKey });
          }
        }
      }

      const currentUserLangTranslations = logInformation.langCaptionsArr.find(
        (langCaption) => langCaption.lang?.substring(0, 2) === currentUser?.language?.substring(0, 2),
      );
      if (currentUserLangTranslations) {
        // return value by caption of user language
        let valueCaption = isPreviousValue
          ? currentUserLangTranslations.oldCaption
          : currentUserLangTranslations.newCaption;

        // in case value caption is empty, find value caption by key in list to avoid empty string rendering as a value
        if (!valueCaption) {
          valueCaption = record?.valueCaption ? record?.valueCaption : record?.value;
        }
        if (!valueCaption) {
          valueCaption = emptyAuditLogValueText;
        }

        if (chipItem) {
          // TODO: Change in the future to a common Label component(need to be done by Ariel's team and then modify here)
          return fieldName === journeyConstants.STATUS ? (
            <StatusLabel stylingVariant={itemClassNames.status} color={relevantValueClass} marginLeft="0px">
              <Tooltip text={valueCaption} isTruncatedText placement="top" />
            </StatusLabel>
          ) : (
            <PriorityLabel stylingVariant={itemClassNames.priority} color={relevantValueClass}>
              <Tooltip text={valueCaption} isTruncatedText placement="top" />
            </PriorityLabel>
          );
        }
        if (Array.isArray(valueCaption) && valueCaption.length >= 1) {
          valueCaption = valueCaption.map((value, index) => index === 0 ? value : `,${  value}`);
        }
        return <span className="field-change">{valueCaption}</span>;
      }
      if (record) {
        let valueCaption = record.valueCaption ? record.valueCaption : record.value;
        if (chipItem) {
          // TODO: Change in the future to a common Label component(need to be done by Ariel's team and then modify here)
          return fieldName === journeyConstants.STATUS ? (
            <StatusLabel stylingVariant={itemClassNames.status} color={record.valueClass} marginLeft="0px">
              <Tooltip text={valueCaption} isTruncatedText placement="top" />
            </StatusLabel>
          ) : (
            <PriorityLabel stylingVariant={itemClassNames.priority} color={record.valueClass}>
              <Tooltip text={valueCaption} isTruncatedText placement="top" />
            </PriorityLabel>
          );
        }
        if (!valueCaption) {
          valueCaption = emptyAuditLogValueText;
        }
        return <span className="field-change">{valueCaption}</span>;
      }
      // key not found in list
      return <span className="field-change">{typeof valueKey === 'object' ? valueKey.value : valueKey}</span>;
    },
    [
      chipItem,
      closureInformationList,
      currentUser?.language,
      emptyAuditLogValueText,
      fieldToValuesMap,
      isWorkflowListChange,
      logInformation.langCaptionsArr,
      logInformation.newColor,
      logInformation.oldColor,
      logInformation.sectionField,
      logType,
      priorityList,
      statusList,
    ],
  );

  const renderAssigneesAvatars = (auditLogValue, userDetails) => (
    <AvatarGroup
      component={({ children }) => (
        <AvatarGroupWrapper size={AVATAR_CONSTANTS.SIZES.MEDIUM}>{children}</AvatarGroupWrapper>
      )}
    >
      {isNone(auditLogValue.group) && isNone(userDetails.calculatedUserName) && (
        <BasicAvatar type={AVATAR_CONSTANTS.TYPES.UNASSIGNED} size={AVATAR_CONSTANTS.SIZES.MEDIUM} />
      )}
      {!isNone(userDetails.calculatedUserName) && (
        <User userId={userDetails.id}>
          <BasicAvatar
            size={AVATAR_CONSTANTS.SIZES.MEDIUM}
            userName={userDetails.calculatedUserName}
            profileImage={userDetails.profileImage}
          />
        </User>
      )}
      {!isNone(auditLogValue.group) && (
        <User teamName={auditLogValue.group}>
          <BasicAvatar type={AVATAR_CONSTANTS.TYPES.GROUP} size={AVATAR_CONSTANTS.SIZES.MEDIUM} />
        </User>
      )}
    </AvatarGroup>
  );

  const renderAssigneesNames = (auditLogValue, userDetails) => {
    const { calculatedUserName } = userDetails;
    const { group } = auditLogValue;
    const isUserNone = isNone(calculatedUserName);
    const isGroupNone = isNone(group) || group === 'none';
    const assigneeUsername = (
      <span className="assignee-username">
        {isCurrentUserAdmin(auditLogValue.admin, currentUser?.username)
          ? `${calculatedUserName} (${meText})`
          : calculatedUserName}
      </span>
    );

    if (isUserNone && isGroupNone) {
      return <span className="assignee-username">{unassignedText}</span>;
    }

    if (!isGroupNone && isUserNone) {
      return <span className="assignee-group">{group}</span>;
    }

    if (isGroupNone && !isUserNone) {
      return assigneeUsername;
    }

    return (
      <div className="both-assignee">
        {!isUserNone && assigneeUsername}
        {!isGroupNone && <span className="assignee-group">{group}</span>}
      </div>
    );
  };

  const findValueByList = (values, auditLogValueId) => {
    const logValue = values.find((value) => value.id.toString() === auditLogValueId);
    if (logValue) {
      return logValue.value;
    }
    return emptyAuditLogValueText;
  };

  const toggleTruncationButton = () => {
    toggleAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isRichTextTruncated' });
  };

  const renderAuditLogValue = ({ fieldName, listType, isValueId, isPreviousValue, auditLogValue }) => {
    if (listType === journeyConstants.DYNAMIC_LIST) {
      return renderDynamicListValue(fieldName, auditLogValue, isPreviousValue);
    }
    auditLogValue = isWorkflowListChange && typeof auditLogValue === 'object' ? auditLogValue.value : auditLogValue;

    if (!(auditLogValue !== '0' && auditLogValue)) {
      return <span className="field-change">{emptyAuditLogValueText}</span>;
    }

    switch (logType) {
      case actionEnums.FIELD_ASSIGNMENT:
      case actionEnums.SOLUTION_ASSIGNEE_CHANGED: {
        const userDetails = isPreviousValue ? oldUserDetails : newUserDetails;
        return (
          <div className="assignment-wrapper">
            <div className="avatars-wrapper">{renderAssigneesAvatars(auditLogValue, userDetails)}</div>
            <div className="assignees-text-wrapper">{renderAssigneesNames(auditLogValue, userDetails)}</div>
          </div>
        );
      }
      case actionEnums.FIELD_CATEGORY: {
        return (
          <CategorySelect
            initValue={auditLogValue}
            defaultTitle={auditLogValue}
            customHeaderStyles={(auditLogValue) => getCustomHeaderStyles(auditLogValue)}
            disabled
          />
        );
      }
      case actionEnums.FIELD_DUE_DATE: {
        const { insertTime } = queryClient.getQueryData(['srId', srId]);
        return <DueDateField disabled startDate={insertTime} endDate={parseInt(auditLogValue, 10)} srType={srType} />;
      }
      // custom column / regular fields(impact, title etc.)
      default: {
        // check if this field has list of values(urgency, impact, request user, etc.)
        let values = [];
        if (!isWorkflowListChange) {
          values = isValueId && fieldToValuesMap[fieldName]?.length ? fieldToValuesMap[fieldName] : [];
          if (values?.length) {
            auditLogValue = findValueByList(values, auditLogValue);
          }
        }
        if (logInformation.fieldName === 'assignee') {
          // workflow assignee change
          const userDetails = isPreviousValue ? oldUserDetails : newUserDetails;
          return (
            <div className="assignment-wrapper">
              <div className="avatars-wrapper">{renderAssigneesAvatars(auditLogValue, userDetails)}</div>
              <div className="assignees-text-wrapper">{renderAssigneesNames(auditLogValue, userDetails)}</div>
            </div>
          );
        }
        return (
          <ConditionalWrapper
            condition={toDisplayUser([requestUserTexts], fieldName)}
            wrapper={(children) => <User userName={auditLogValue}>{children}</User>}
          >
            <RichTextField
              auditLogValue={auditLogValue}
              showTruncationButton={showTruncationButton}
              isRichTextField={logInformation?.isRichText}
              isRegularText={
                !logInformation?.textTruncateEnabled &&
                !logInformation?.isRichText &&
                !IS_NOT_REGULAR_FIELDS.includes(logInformation?.fieldName)
              }
            />
          </ConditionalWrapper>
        );
      }
    }
  };

  return (
    <>
      <StyledAuditLogFieldChange
        className="field-change-log"
        isRichTextTruncated={isRichTextTruncated}
        ref={fieldChangeRef}
      >
        {logInformation && (
          <>
            <LogDescriptionItem
              logInfo={{
                fieldName: derivedFieldName,
                oldValue: oldDerivedValue,
                newValue: newDerivedValue,
                isNewValue: false,
                logType,
                textTruncateEnabled: logInformation?.textTruncateEnabled,
              }}
            >
              {renderAuditLogValue({
                fieldName: derivedFieldName,
                listType,
                isValueId,
                isPreviousValue: true,
                auditLogValue: oldDerivedValue,
              })}
            </LogDescriptionItem>
            <StyledArrowDivider
              alt="change-arrow"
              src={separatorArrow}
              isCentered={isRichTextTruncated || logInformation?.textTruncateEnabled}
            />
            <LogDescriptionItem
              logInfo={{
                fieldName: derivedFieldName,
                oldValue: oldDerivedValue,
                newValue: newDerivedValue,
                isNewValue: true,
                logType,
                textTruncateEnabled: logInformation?.textTruncateEnabled,
              }}
            >
              {renderAuditLogValue({
                fieldName: derivedFieldName,
                listType,
                isValueId,
                isPreviousValue: false,
                auditLogValue: newDerivedValue,
              })}
            </LogDescriptionItem>
          </>
        )}
      </StyledAuditLogFieldChange>
      {showTruncationButton && (
        <TruncatedTextButton isRichTextTruncated={isRichTextTruncated} onClick={toggleTruncationButton} />
      )}
    </>
  );
}
