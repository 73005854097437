import { useEffect, useMemo, useRef, useState } from 'react';
import { ClickAwayListener } from '@mui/base';
import { debounce } from 'lodash-es';
import { MoreItemsPopup } from './MoreItemsPopup';
import { actionEnums } from '../../constants';
import { StyledChip, StyledChipWrapper } from './style';

const MAX_DISPLAY_ITEMS_LIMIT = 2;
const MORE_BTN_WIDTH = 200;
const ITEM_WIDTH = 111;
const removedItemsValues = 'removedItemsValues';
const addedItemsValues = 'addedItemsValues';

export const MultiListWrapper = ({ logType, logInformation, wrapperRef }) => {
  const [toggleMoreItems, setToggleMoreItems] = useState(false);
  const [displayItemsLimit, setDisplayItemsLimit] = useState(MAX_DISPLAY_ITEMS_LIMIT);
  const items = 
      logInformation[logType === actionEnums.ACTION_ITEM_MULTISELECT_VALUE_REMOVED ? removedItemsValues : addedItemsValues];

  const itemsLength = items?.length;
  const showMoreItems = itemsLength > displayItemsLimit;
  const moreItemsSum = itemsLength - displayItemsLimit;
  const showMoreRef = useRef(null);
  const handleCloseDropdown = () => {
    setToggleMoreItems(false);
  };

  const setLimit = useMemo(
    () =>
      debounce(() => {
        const offsetWidth = wrapperRef?.current?.offsetWidth;
        const attachmentWidth = ITEM_WIDTH;
        let elementsCount = Math.floor((offsetWidth - MORE_BTN_WIDTH) / attachmentWidth);
        if (elementsCount <= 0) {
          elementsCount = 1;
        }
        const limit = elementsCount;
        setDisplayItemsLimit(limit);
      }, 300),
    [wrapperRef],
  );

  useEffect(() => {
    setLimit();
  }, [setLimit]);

  useEffect(() => {
    window.addEventListener('resize', setLimit);
    return () => window.removeEventListener('resize', setLimit);
  }, [setLimit]);

  if (!items) {
    return null;
  }
  const thumbnails = items
    .map((item) => (
      <StyledChip logType={logType} key={`${item}-thumbnail`}>
        {item}
      </StyledChip>
    ))
    .slice(0, displayItemsLimit);

  return (
    <StyledChipWrapper ref={wrapperRef}>
      {thumbnails}
      {showMoreItems && (
        <>
          <ClickAwayListener onClickAway={handleCloseDropdown}>
            <StyledChip
              ref={showMoreRef}
              logType={logType}
              showMore
              className="more-attachments-btn"
              onClick={() => setToggleMoreItems((prevToggle) => !prevToggle)}
            >
              +{moreItemsSum}
            </StyledChip>
          </ClickAwayListener>
          <MoreItemsPopup
            stroke={logType === 115}
            displayItemsLimit={displayItemsLimit}
            items={items}
            toggleMoreItems={toggleMoreItems}
            anchorEl={showMoreRef}
          />
        </>
      )}
    </StyledChipWrapper>
  );
};
