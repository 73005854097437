import { useRbTexts } from 'remote-state/applicationHooks';
import { formatTranslation } from '../../../../common/utils/translation';
import { actionTypes } from './constants';

export default function useTexts(ticketCount) {
  const texts = useRbTexts();
  const componentTexts = {
    deleteTicketText: texts?.['spaces.actionBar.deleteTickets.delete'],
    deleteTicketCancelText: texts?.['spaces.actionBar.deleteTickets.cancel'],
    deleteTicketErrorText: texts?.['spaces.header.actions.deleteTicket.error'],
    recordsSelectedText: texts?.['spaces.actionBar.recordsSelected'],
    assignText: texts?.['spaces.actionBar.assign'],
    deleteTicketWarningMessageText: texts?.['spaces.actionBar.deleteTickets.warningMessage'],
    deleteTicketMessageText: ({ selectedTicketsCount, failureCount, successCount }) => {
      let text;
      if (selectedTicketsCount === successCount) {
        text = texts?.['spaces.actionBar.deleteTicket.wasDeletedMessage'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.deleteTickets.wasDeletedMessage'];
        return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
      }
      if (failureCount === selectedTicketsCount) {
        text = texts?.['spaces.actionBar.deleteTicket.all.failed.to.delete'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.deleteTickets.all.failed.to.delete'];
        return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
      }
      text = texts?.['spaces.actionBar.deleteTicket.partial.success'];
      if (ticketCount > 1) text = texts?.['spaces.actionBar.deleteTickets.partial.success'];
      return formatTranslation(text, [
        { placeHolder: '{numberOfSuccessTickets}', value: successCount },
        { placeHolder: '{numberOfFailedTickets}', value: failureCount },
      ]);
    },

    loadingTicketMessageText: ({ actionType, assignee }) => {
      let text;
      switch (actionType) {
        case actionTypes.ASSIGN: {
          text = texts?.['spaces.actionBar.load.assign'];
          if (ticketCount > 1) text = texts?.['spaces.actionBar.loading.assign'];
          return formatTranslation(text, [
            { placeHolder: '{numberOfTickets}', value: ticketCount },
            { placeHolder: '{assignee}', value: assignee },
          ]);
        }
        case actionTypes.DELETE: {
          text = texts?.['spaces.actionBar.load.delete'];
          if (ticketCount > 1) text = texts?.['spaces.actionBar.loading.delete'];
          return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
        }
        default:
      }
    },
    assignedTicketMessageText: ({ selectedTicketsCount, failureCount, successCount, assignee = null }) => {
      let text = texts?.['spaces.actionBar.assignTicket.partial.success'];

      if (selectedTicketsCount === successCount) {
        text = texts?.['spaces.actionBar.assignTicket.wasAssignedMessage'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.assignTickets.wasAssignedMessage'];

        return formatTranslation(text, [
          { placeHolder: '{numberOfTickets}', value: ticketCount },
          { placeHolder: '{assignee}', value: assignee },
        ]);
      }
      if (failureCount === selectedTicketsCount) {
        text = texts?.['spaces.actionBar.assignTicket.all.failed.assignment'];
        if (ticketCount > 1) text = texts?.['spaces.actionBar.assignTickets.all.failed.assignment'];

        return formatTranslation(text, [{ placeHolder: '{numberOfTickets}', value: ticketCount }]);
      }
      if (ticketCount > 1) text = texts?.['spaces.actionBar.assignTickets.partial.success'];
      return formatTranslation(text, [
        { placeHolder: '{numberOfSuccessTickets}', value: successCount },
        { placeHolder: '{numberOfFailedTickets}', value: failureCount },
      ]);
    },
    assignedSubTitleText: texts?.['spaces.actionBar.assignTickets.subTitle'],
  };

  return componentTexts;
}
