import { useRef } from 'react';
import separatorArrow from 'images/icons/values-seperator-arrow.svg';

import { ReadOnlyEditor } from './readOnlyEditor';
import { StyledArrowDivider, StyledAuditLogFieldChange } from '../fieldChange/style';
import { useDisplayTextTruncation } from '../fieldChange/hooks/useDisplayTextTruncation';
import TruncatedTextButton from './truncatedTextButton';

export const EditorChange = (props) => {
  const {
    oldValue,
    oldCustomFooter,
    newValue,
    newCustomFooter,
    isRichTextTruncated,
    toggleAuditLogsProperty,
    logType,
    auditLogId,
    logInformation,
  } = props;
  const oldValueRef = useRef(null);
  const newValueRef = useRef(null);

  const { showTruncationButton: showTruncationButtonOldValue } = useDisplayTextTruncation({
    divRef: oldValueRef,
    logType,
  });
  const { showTruncationButton: showTruncationButtonNewValue } = useDisplayTextTruncation({
    divRef: newValueRef,
    logType,
  });

  return (
    <StyledAuditLogFieldChange isRichTextTruncated={isRichTextTruncated}>
      <ReadOnlyEditor
        {...props}
        ref={oldValueRef}
        value={oldValue}
        customFooter={oldCustomFooter}
        logInformation={logInformation?.oldValue}
      />
      <StyledArrowDivider alt="change-arrow" src={separatorArrow} isCentered={isRichTextTruncated} />
      <ReadOnlyEditor
        {...props}
        ref={newValueRef}
        value={newValue}
        customFooter={newCustomFooter}
        logInformation={logInformation?.newValue}
        isFirst={false}
      />

      {(showTruncationButtonOldValue || showTruncationButtonNewValue) && (
        <TruncatedTextButton
          isRichTextTruncated={isRichTextTruncated}
          onClick={() => toggleAuditLogsProperty({ auditLogIds: [auditLogId], property: 'isRichTextTruncated' })}
        />
      )}
    </StyledAuditLogFieldChange>
  );
};
