import { styled } from '@mui/material/styles';
import { ListItem } from '@mui/material';

export const StyledUserInfoCardHeader = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.generic.sysaid_default,

  '.MuiListItemAvatar-root': {
    paddingBlock: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    '& .MuiBox-root': {
      padding: theme.spacing(0.75),
    },
  },

  '.user-primary-text': {
    color: theme.palette.generic.primary,
    fontSize: '1.25rem',
    lineHeight: 1.2,
    paddingRight: theme.spacing(3),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    marginLeft: '-4px',
  },
}));
