import { useState } from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
import StyledUserInfoCardDetail from './styles';

const componentId = 'user-info-card-detail';

const UserInfoCardDetail = ({ Icon, text, secondaryAction, id }) => {
  const [showAction, setShowAction] = useState(false);

  const hover = () => {
    setShowAction(true);
  };

  const unhover = () => {
    setShowAction(false);
  };

  return (
    <StyledUserInfoCardDetail disablePadding id={`${componentId}-${id}`} onMouseEnter={hover} onMouseLeave={unhover}>
      <ListItemIcon id={`${componentId}-${id}-icon`}>
        <Icon />
      </ListItemIcon>
      <ListItemText id={`${componentId}-${id}-text`} primary={text || '-'} />
      {secondaryAction && showAction && (
        <ListItemIcon id={`${componentId}-${id}-secondary-action`}>{secondaryAction}</ListItemIcon>
      )}
    </StyledUserInfoCardDetail>
  );
};

export default UserInfoCardDetail;
