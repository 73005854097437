import { axiosInstance } from './axiosConfig';

export function getUsersByGroup(groupName) {
  return axiosInstance.get(`groups/${groupName}`).then((response) => response.data);
}

export const getGroupsWithParams = (params = {}) => {
  const queryString = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value || value === 0) queryString.append(key, value);
  });
  return axiosInstance.get(`groups/search?${queryString.toString()}`).then((response) => response.data);
};
