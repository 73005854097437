import { useCallback, useRef, useState } from 'react';
import { MenuItem } from '@mui/material';
import FieldName from 'features/srPanel/templateFieldsGrid/fieldName';
import FieldSearchableDropdown from 'features/srPanel/templateFieldsGrid/fieldSearchableDropdown';
import { useGetRecentlyCreatedSrs } from 'remote-state/ticketServiceHooks';
import CustomScroll from 'common/components/customScroll';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants';

import { StyledDropdownItem } from '../linkItemProcess/style';
import { StyledRelationshipItemDropdowns, StyledDropdown, StyledDropDownMenu, StyledTextField } from './style';
import useTexts from '../hooks/useTexts';
import {
  useCustomBackendQueryConfig,
  useIsSrChildOrParent,
  useRelatedSrItemsDropdownColumns,
} from '../hooks/useRelatedItems';
import { FIELD_ATTRIBUTES, RELATIONSHIP_TYPES } from '../constants';

const customScrollProps = {
  style: { display: 'flex' },
  autoHeight: true,
  autoHeightMax: 230,
  renderThumbVerticalStyle: { width: '4px' },
  renderTrackVerticalStyle: { width: '0px', padding: 0 },
  renderViewStyle: { flex: '1' },
  dataset: { 'data-testid': 'relationship-item-dropdown-scrollbar' },
};

const RelationshipItemDropdowns = ({ data, relatedItemsToLink, handleChange, srId }) => {
  const {
    componentTexts: { relationshipText, itemText, recentItemsText, searchSrPlaceholder },
    getRelationshipText,
  } = useTexts();
  const relatedSrItemsDropdownColumns = useRelatedSrItemsDropdownColumns();
  const [relationshipMenuOpen, setRelationshipMenuOpen] = useState(false);
  const ref = useRef();
  const { data: recentlyCreatedSrs } = useGetRecentlyCreatedSrs(srId);
  const { relationship: relationType } = useIsSrChildOrParent(srId);
  const customBackendQueryConfig = useCustomBackendQueryConfig;
  const pendoTrackEvents = usePendoTrackEvents();

  const handleOpenMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setRelationshipMenuOpen(true);
    },
    [setRelationshipMenuOpen],
  );

  const handleCloseMenu = useCallback(
    (e) => {
      e.stopPropagation();
      setRelationshipMenuOpen(false);
    },
    [setRelationshipMenuOpen],
  );

  const handleSelect = useCallback(
    ({ type, payload, selectedValues, isSingleChipCleared }) => {
      let values = payload;
      if (selectedValues && isSingleChipCleared) {
        // delete single chip when dropdown is closed
        const idsArray = selectedValues?.split(',')?.map(Number);
        values = recentlyCreatedSrs?.filter((option) => idsArray.includes(option.id));
      }
      // when selecting 'Child Of' relationship, only one item can be selected
      if (type === 'items' && relatedItemsToLink?.relationship?.id === RELATIONSHIP_TYPES.CHILD && values?.length > 1) {
        return;
      }
      handleChange({ type, payload: values });
      if (type === 'relationship') {
        pendoTrackEvents(PENDO_TRACK_EVENTS.Choosing_relationship_type_in_related_items_box, {
          relationshipId: values?.id,
          relationshipType: values?.name,
        });
        setRelationshipMenuOpen(false);
      }
    },
    [handleChange, recentlyCreatedSrs, relatedItemsToLink?.relationship, pendoTrackEvents],
  );

  return (
    <StyledRelationshipItemDropdowns>
      <StyledDropdown>
        <FieldName required name={relationshipText} />
        <StyledTextField
          id="relationship-input"
          placeholder="--"
          value={getRelationshipText(relatedItemsToLink?.relationship?.name)}
          onClick={handleOpenMenu}
          readOnly
          name="relationship"
          autoComplete="off"
          type="text"
          ref={ref}
          isOpen={relationshipMenuOpen}
          hasValue={relatedItemsToLink?.relationship?.name}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            autoComplete: 'off',
          }}
        />
        <StyledDropDownMenu
          anchorEl={ref.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={relationshipMenuOpen}
          onClose={handleCloseMenu}
        >
          <CustomScroll {...customScrollProps}>
            {data?.relationships?.map((relationship) => (
              <MenuItem
                key={relationship.id}
                onClick={() => handleSelect({ type: 'relationship', payload: relationship })}
                // disable parent option when sr is child of another ticket as it can't be parent and child
                // or disable child option if sr is parent of another tickets
                // also disable merged from, merged to as we don't support it yet in spaces
                disabled={
                  (relationship.id === RELATIONSHIP_TYPES.PARENT && relationType === RELATIONSHIP_TYPES.CHILD) ||
                  (relationship.id === RELATIONSHIP_TYPES.CHILD && relationType === RELATIONSHIP_TYPES.PARENT) ||
                  relationship.id === RELATIONSHIP_TYPES.MERGED_TO ||
                  relationship.id === RELATIONSHIP_TYPES.MERGED_FROM
                }
              >
                <StyledDropdownItem>{`${getRelationshipText(relationship.name)} ${
                  relationship.id === RELATIONSHIP_TYPES.MERGED_TO || relationship.id === RELATIONSHIP_TYPES.MERGED_FROM
                    ? '(Coming soon)' // merged from, merged to options: add 'Coming soon' as it not yet supported in spaces
                    : ''
                }`}</StyledDropdownItem>
              </MenuItem>
            ))}
          </CustomScroll>
        </StyledDropDownMenu>
      </StyledDropdown>
      <StyledDropdown isDirty={relatedItemsToLink?.items?.length} isFullWidth>
        <FieldSearchableDropdown
          field={FIELD_ATTRIBUTES}
          fieldValue={relatedItemsToLink?.items?.map((item) => item.id)}
          onChange={(values, selectedObjects, isSingleChipCleared) =>
            handleSelect({ type: 'items', payload: selectedObjects, selectedValues: values, isSingleChipCleared })
          }
          skipValidation
          isSelectAllHidden
          listLabel={recentItemsText}
          values={recentlyCreatedSrs}
          displayName={itemText}
          required
          isMultiple
          chipCaptionKey="chipCaption"
          searchPlaceholder={searchSrPlaceholder}
          columns={relatedSrItemsDropdownColumns}
          selectedValuesAsObjects
          customBackendQueryConfig={customBackendQueryConfig}
          dataCy="items-dropdown"
          noChipFixedWidth
        />
      </StyledDropdown>
    </StyledRelationshipItemDropdowns>
  );
};

export default RelationshipItemDropdowns;
