import { useRbTexts } from 'remote-state/applicationHooks';

export default function useTexts() {
  const texts = useRbTexts();
  const componentTexts = {
    membersText: texts?.['spaces.header.current-assigned.groupTitleMembers'],
    localTimeText: texts?.['spaces.user-info-card.local-time'],
  };

  return componentTexts;
}
