import { Fragment } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useTheme } from '@mui/styles';

import { CONSTANTS } from '../constants';

const renderThumbVertical = (theme) => (
  <div
    style={{
      position: 'relative',
      display: 'block',
      width: theme?.spacing(0.5),
      cursor: 'pointer',
      borderRadius: 'inherit',
      backgroundColor: theme?.palette?.grey?.divider_grey_v1,
      height: '112px',
      transform: 'translateY(0px)',
    }}
  />
);
const renderTrackVertical = (theme) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      width: theme?.spacing(1.5),
      borderRadius: '3px',
      right: '0',
      bottom: theme?.spacing(0.25),
      top: theme?.spacing(0.25),
    }}
  />
);
export const useScrollbar = (length) => {
  const theme = useTheme();
  //The maximum height is the length times the fixed height for each item.
  const maxHeight = length * CONSTANTS.DROPDOWN_ITEM_HEIGHT;
  const scrollProps = {
    autoHeight: true,
    autoHeightMax: maxHeight,
    renderThumbVertical: () => renderThumbVertical(theme),
    renderTrackVertical: () => renderTrackVertical(theme),
  };
  return {
    Container: length >= 0 ? Scrollbars : Fragment,
    scrollProps: length >= 0 ? scrollProps : {},
  };
};
