import { ListItemAvatar, ListItemText } from '@mui/material';
import { BasicAvatar } from 'common/components/avatars/basicAvatar';
import { AVATAR_CONSTANTS } from 'constants/index';
import Tooltip from 'common/components/tooltip';
import { StyledUserInfoCardTeamDetails } from './styles';
import useTexts from '../useText';

const componentId = 'user-info-card-team-details';

const UserInfoCardTeamDetails = ({ teamName, memberCount }) => {
  const { membersText } = useTexts();
  return (
    <>
      <StyledUserInfoCardTeamDetails id={componentId} disablePadding>
        <ListItemAvatar id={`${componentId}-avatar`}>
          <BasicAvatar type={AVATAR_CONSTANTS.TYPES.GROUP} disableHover />
        </ListItemAvatar>
        <ListItemText
          id={`${componentId}-text`}
          primaryTypographyProps={{ id: `${componentId}-text-primary` }}
          primary={<Tooltip
            className="primary-text"
            isTruncatedText
            text={teamName}
          />}
          secondaryTypographyProps={{ id: `${componentId}-text-secondary` }}
          secondary={`${memberCount} ${membersText}`}
        />
      </StyledUserInfoCardTeamDetails>
    </>
  );
};

export default UserInfoCardTeamDetails;
