import { COMMON_CONSTANTS } from 'constants/common';
import { ROUTES_PATHS } from 'constants/routes';
import { APP_CONSTANTS } from '../../constants';
// test

export const getCookie = (cookieName) => {
  const cookie = {};
  document.cookie.split(';').forEach((el) => {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};

export const capitalizeFirstLetter = (string) => string?.[0].toUpperCase() + string?.slice(1);

export const removeCookie = (key) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const setCookie = (key, value, days = 365) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${key}=${value}; expires=${expires.toUTCString()}; path=/`;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export const toDisplayUser = (fields, currentField) => {
  const res = fields.some((field) => field?.toLowerCase() === currentField?.toLowerCase());
  return res;
};

export const setStyleProperty = (ref, propertyName, value, priority = '') => {
  const node = ref.current;
  node?.style.setProperty(propertyName, value, priority);
};

export const createUrlWithQuery = (url, queryObj) => {
  let urlWithQuery = url;
  const keys = Object.keys(queryObj || {});
  if (keys.length) {
    keys.forEach((k) => {
      const add =
        queryObj[k] === undefined || queryObj[k] === null
          ? ''
          : `${urlWithQuery === url ? '?' : '&'}${k}=${queryObj[k]}`;
      urlWithQuery += add;
    });
  }

  return urlWithQuery;
};

export const localeIncludes = (string, searchString, { position = 0, locales, ...options } = {}) => {
  const stringLength = string.length;
  const searchStringLength = searchString.length;
  const lengthDiff = stringLength - searchStringLength;

  for (let i = position; i <= lengthDiff; i++) {
    if (string.substring(i, i + searchStringLength).localeCompare(searchString, locales, options) === 0) {
      return true;
    }
  }

  return false;
};

export const openWindow = (url, width = window.innerWidth * 0.8, height = window.innerHeight * 0.8) => {
  const left = window.innerWidth / 2 - width / 2;
  const top = window.innerHeight / 2 - height / 2;
  setCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE, 'true');
  const newWindow = window.open(
    encodeURI(url),
    '_blank',
    `width=${width},height=${height},left=${left},top=${top},resizable=yes`,
  );
  newWindow.addEventListener('load', () => {
    const width = newWindow.document.body.scrollWidth;
    const height = newWindow.document.body.scrollHeight;
    newWindow.resizeTo(width, height);
  });
};

export const isNone = (name) => !name || name === COMMON_CONSTANTS.NONE;

export const getShowNavBar = (router) =>
  ['', 'logout', 'ticket', 'templates-list', 'settings', 'classic'].includes(
    router.latestLocation.pathname.replace(/((^\/spaces\/(?=.+))|(^\/spaces(?=\/*$)))/, '').replace(/(?<!\/)\/*$/, ''),
  );

export const expandQueryKey = (queryKeyArray, queryObj) => {
  if (queryObj == null) return queryKeyArray;
  const copiedQueryKey = [...queryKeyArray];
  const entries = Object.entries(queryObj);
  if (entries.length) {
    entries.forEach(([k, v]) => copiedQueryKey.push(`${k}${v}`));
  }
  return copiedQueryKey;
};

export const mergeArraysByKey = (arr1, arr2, key) => {
  if (arr1?.length && !arr2?.length) {
    return arr1;
  }
  if (arr2?.length && !arr1?.length) {
    return arr2;
  }
  return [
    ...arr1
      .concat(arr2)
      .reduce((m, o) => m.set(o[key], Object.assign(m.get(o[key]) || {}, o)), new Map())
      .values(),
  ];
};

export const getLoginPathInfo = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const manualLogin = params.get('manual');
  const previousPath = params.get('from') || ROUTES_PATHS.BASE_PATH;
  return {
    manualLogin,
    previousPath,
  };
};

export const getEnvironment = () => {
  const environment = window?.location?.href?.toLowerCase()?.includes('.qa.') ? 'QA' : 'Production';
  return environment;
};

export const buildLoginUrl = (url) =>
  `${ROUTES_PATHS.LOGIN_URL}?from=${encodeURIComponent(url || window.location.pathname + window.location.search)}`;

export const findValuesByIds = (valuesList, ids, key = 'idForFilter') => {
  const valuesFound = [];
  const idsNotFound = [];
  ids.forEach((id) => {
    const value = valuesList.find((opt) => opt[key] === id);
    if (value) {
      valuesFound.push(value);
    } else {
      idsNotFound.push(id);
    }
  });
  return { valuesFound, idsNotFound };
};

export const handlePreventXssAttack = (router) => {
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('url');
  if (url) {
    const decodedUrl = decodeURIComponent(url);
    // eslint-disable-next-line no-script-url
    if (decodedUrl?.includes('javascript:')) {
      router.navigate({ to: '/spaces' });
    }
  }
};

export const isMobileUserAgent = (userAgent) => {
  if (
    userAgent &&
    (userAgent.toLowerCase().indexOf('blackberry') >= 0 ||
      userAgent.toLowerCase().indexOf('iphone') >= 0 ||
      userAgent.toLowerCase().indexOf('windows phone') >= 0 ||
      userAgent.toLowerCase().indexOf('symbianos') >= 0 ||
      userAgent.toLowerCase().indexOf('ipod') >= 0 ||
      (userAgent.toLowerCase().indexOf('android') >= 0 && userAgent.toLowerCase().indexOf('xoom') === -1))
  ) {
    return true;
  }

  return false;
};
