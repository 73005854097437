import { useCallback, useMemo } from 'react';
import { sortingFns as importedSortingFns } from '@tanstack/react-table';

import Tooltip from 'common/components/tooltip';
import { ReactComponent as UnlinkItemIcon } from 'images/icons/link-item.svg';
import { SR_TYPE_CODE_MAP } from 'features/TicketPanel/constants';

import { RELATIONSHIP_ENUMS } from '../constants';
import useTexts from '../hooks/useTexts';

export const useTableData = () => {
  const { componentTexts, getRelationshipText } = useTexts();
  const srRelatedItemsColumns = useMemo(
    () => [
      {
        accessorKey: 'relationship',
        header: componentTexts?.RelationshipText,
        cell: (info) => getRelationshipText(RELATIONSHIP_ENUMS[info.getValue()]),
        sortingFn: 'relatedItemsSortingFn',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'id',
        header: componentTexts?.idText,
        sortingFn: 'relatedItemsSortingFn',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'title',
        header: componentTexts?.titleText,
        cell: (info) => (
          <div className="related-items-title">
            <Tooltip
              text={info.getValue()}
              isTruncatedText
              placement="top"
              style={{ fontFamily: 'Roboto-Regular', lineHeight: '16px' }}
            />
          </div>
        ),
        sortingFn: 'relatedItemsSortingFn',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'srType',
        header: componentTexts?.srTypeText,
        cell: (info) => {
          const srTypeValue = SR_TYPE_CODE_MAP[info.getValue()];
          return String(srTypeValue).charAt(0).toUpperCase() + String(srTypeValue).slice(1);
        },
        sortingFn: 'relatedItemsSortingFn',
        enableColumnFilter: false,
      },
      {
        accessorKey: 'unlinkItem',
        id: 'unlinkItem',
        header: '',
        cell: () => <UnlinkItemIcon className="unlink-item-icon" />,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [
      componentTexts?.RelationshipText,
      componentTexts?.idText,
      componentTexts?.srTypeText,
      componentTexts?.titleText,
      getRelationshipText,
    ],
  );

  const templatesListDefaultSort = useCallback((rowA, rowB, desc) => {
    const defaultSrTypeOrder = ['incident', 'request', 'change', 'problem'];
    const rowASrTypeIndex = defaultSrTypeOrder.indexOf(rowA.getValue('srType'));
    const rowBSrTypeIndex = defaultSrTypeOrder.indexOf(rowB.getValue('srType'));
    if (rowASrTypeIndex > rowBSrTypeIndex) {
      return desc ? -1 : 1;
    }
    if (rowASrTypeIndex < rowBSrTypeIndex) {
      return desc ? 1 : -1;
    }
    return 0;
  }, []);

  const relatedItemsSortingFn = useCallback(
    (rowA, rowB, columnId, sorting) => {
      const desc = sorting && sorting[0].desc;
      if (rowA.original.default && !rowB.original.default) {
        return desc ? 1 : -1;
      }
      if (rowB.original.default && !rowA.original.default) {
        return desc ? -1 : 1;
      }
      if (rowA.original.default && rowB.original.default) {
        return templatesListDefaultSort(rowA, rowB, desc);
      }
      return sorting ? importedSortingFns.alphanumeric(rowA, rowB, columnId) : 0;
    },
    [templatesListDefaultSort],
  );

  const sortingFns = useMemo(
    () => ({
      relatedItemsSortingFn,
    }),
    [relatedItemsSortingFn],
  );

  return { srRelatedItemsColumns, sortingFns };
};
