import { useState, useEffect, useCallback } from 'react';

import { UploadProgressBar } from 'common/components/uploadFile/uploadProgressBar';
import { Attachments as AttachmentsList } from 'features/attachments/attachmentsList';
import { useDeleteAttachmentMutation } from 'remote-state/attachments';
import AttachmentUploader from './attachmentUploader';
import { StyledUploadProgressContainer } from '../styles';

const Attachments = (props) => {
  const {
    attachments,
    activeUploads = [],
    onChangeActiveUploads,
    onAttachmentDeleted,
    shouldTriggerDeleteApi,
    isReadOnly,
    isExpanded,
    deleteAttachmentEnabled = true,
  } = props;
  const { mutateAsync: deleteAttachmentApi } = useDeleteAttachmentMutation();
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);

  const handleCloseUploadProgress = async (id) => {
    setAttachmentsToDelete((prev) => [...prev, id]);
    onChangeActiveUploads({ type: 'removeUpload', payload: { id } });
  };

  const clearCanceledAttachments = useCallback(() => {
    const itemsToDelete =
      attachments?.length && attachmentsToDelete?.length
        ? attachments.filter((item) => attachmentsToDelete.includes(item.uploadId))
        : [];
    if (itemsToDelete?.length) {
      itemsToDelete.forEach(async (attachment) => {
        onAttachmentDeleted(attachment.id);
        await deleteAttachmentApi({ attachmentId: attachment?.metadata?.fileId, isDeleteDuplicate: false });
      });
      setAttachmentsToDelete([]);
    }
  }, [attachments, attachmentsToDelete, onAttachmentDeleted, deleteAttachmentApi]);

  useEffect(() => {
    clearCanceledAttachments();
  }, [clearCanceledAttachments]);

  return (
    <>
      {isExpanded && activeUploads?.length > 0 && (
        <StyledUploadProgressContainer>
          {activeUploads.map((upload) => (
            <div className="upload-progress-wrapper" key={upload.id}>
              <UploadProgressBar
                id={upload.id}
                fileName={upload.fileName}
                value={upload.progress}
                error={upload.error}
                onClose={handleCloseUploadProgress}
              />
            </div>
          ))}
        </StyledUploadProgressContainer>
      )}
      {(isExpanded || !!attachments?.length) && (
        <AttachmentsList
          attachments={attachments}
          onAttachmentDeleted={onAttachmentDeleted}
          isReadOnly={isReadOnly}
          shouldTriggerDeleteApi={shouldTriggerDeleteApi}
          deleteAttachmentEnabled={deleteAttachmentEnabled}
        />
      )}
      <AttachmentUploader {...props} />
    </>
  );
};

export default Attachments;
