import { styled } from '@mui/material/styles';

export const HeaderLabel = styled('span')(({ theme }) => ({
  color: theme?.palette?.text?.title_txt,
  fontFamily: theme?.fonts?.robotoRegular,
  fontSize: theme?.fontSize?.small,
  fontWeight: 700,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const HeadersWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  overflow: 'auto!important',
  scrollbarWidth: 'none',
  marginRight: '20px',
}));
