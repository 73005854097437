import { FIELD_TYPE } from 'common/components/grid/constants';
import { createUrlWithQuery } from 'common/utils/utils';
import { axiosInstance } from './axiosConfig';
import { SR_SOURCE_TYPE } from '../features/TicketPanel/constants';

const MAX_LIST_COUNT = 100;

export function getTicketTemplateDataBySrType(srType, source) {
  return axiosInstance
    .get(`sr/${srType}/default_ticket_template${source ? `?source=${source}` : ''}`)
    .then((response) => response.data);
}

export function getTicketTemplateDataBySrId(srId) {
  return axiosInstance.get(`sr/${srId}/ticket_template`).then((response) => response.data);
}

export function getSrById(id) {
  return axiosInstance.get(`sr/${id}`).then((response) => response.data);
}

export function getMigrationStatusBySrId(id) {
  return axiosInstance.get(`sr/isTicketMigrated/${id}`).then((response) => response.data);
}

export function updateSR(srObject) {
  return axiosInstance
    .patch('sr', srObject)
    .then((response) => response)
    .catch((error) => error.response);
}

export function convertSR(convertSrObject) {
  return axiosInstance.patch('sr/convert', convertSrObject).then((response) => response);
}

export function getCustomValueList(listKey, queryObj) {
  const url = `list/${listKey}`;
  return axiosInstance.get(createUrlWithQuery(url, queryObj)).then((response) => response.data);
}

export function createSR(srObject) {
  return axiosInstance
    .post('sr', {
      ...srObject,
      requestUser: srObject.requestUserName || srObject.requestUser?.userName || srObject.requestUser,
      agreement: srObject.agreement?.id || srObject.agreement,
      source: SR_SOURCE_TYPE.SR_SOURCE_ADMIN_PORTAL,
    })
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function newSrFieldUpdate(newSrFieldUpdateObject) {
  return axiosInstance.post('newSrFieldUpdate', newSrFieldUpdateObject).then((response) => response.data);
}

export function deleteSrById(id) {
  return axiosInstance.delete(`sr/${id}`).then((response) => response.data);
}

export function getTicketPermissions(action, id) {
  return axiosInstance.get(`permission/ticket/${action}/${id}`).then((response) => response.data);
}

export async function postSearchSrRequest(searchSrRequest, signal) {
  return axiosInstance.post('sr/_search', searchSrRequest, { signal }).then((response) => response.data);
}

export function getColumnFilterList(listKey) {
  if (listKey === 'srType') {
    axiosInstance.get(`list/${listKey}?count=${MAX_LIST_COUNT}`).then((response) => response?.data);
  }
  if (listKey === FIELD_TYPE.requestUser) {
    return axiosInstance.get('/users/all').then((response) => response.data);
  }
  return axiosInstance.get(`list/${listKey}?count=${MAX_LIST_COUNT}`).then((response) => response.data);
}

export function getSecondaryOrThirdLevelList(key) {
  const url = 'category';
  return axiosInstance.get(`${url}/${key}`).then((response) => response.data);
}

export function getCategoryList(primaryKey, secondaryKey, query, srType) {
  const url = 'category';
  let queryParams;
  if (query || srType) {
    queryParams = new URLSearchParams();
    if (query) {
      queryParams.append('query', query);
    }
    if (srType) {
      queryParams.append('type', srType);
    }
  }
  if (primaryKey) {
    if (secondaryKey) {
      return axiosInstance
        .get(`${url}/${primaryKey}/${secondaryKey}${queryParams ? `?${queryParams.toString()}` : ''}`)
        .then((response) => response.data);
    }
    return axiosInstance
      .get(`${url}/${primaryKey}${queryParams ? `?${queryParams.toString()}` : ''}`)
      .then((response) => response.data);
  }
  return axiosInstance
    .get(`${url}${queryParams ? `?${queryParams.toString()}` : ''}`)
    .then((response) => response.data);
}

export function getCategoryListAttributes(primaryKey, secondaryKey, query, srType, fieldId) {
  const url = `sr/attributes/${fieldId}`;
  const queryParams = new URLSearchParams();
  if (query || srType) {
    if (query) {
      queryParams.append('query', query);
    }
    if (srType) {
      queryParams.append('srType', srType);
    }
  }
  if (primaryKey || primaryKey === 0) {
    queryParams.append('firstLevelKey', primaryKey);
  }
  if (secondaryKey || secondaryKey === 0) {
    queryParams.append('secondLevelKey', secondaryKey);
  }
  return axiosInstance
    .get(`${url}${queryParams ? `?${queryParams.toString()}` : ''}`)
    .then((response) => response.data || []);
}

export function lockSR(lockSrObject) {
  return axiosInstance
    .post('lockTicket', lockSrObject)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function unlockSR(unlockSrObject) {
  return axiosInstance
    .post('unlockTicket', unlockSrObject)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function getTicketLockStatus({ username, srId }) {
  const queryParams = new URLSearchParams();
  queryParams.append('username', username);
  return axiosInstance.get(`ticket/${srId}/locked?${queryParams.toString()}`).then((response) => response.data);
}

export function getFieldAttributes(fieldId, queryObj) {
  const url = `sr/attributes/${fieldId}`;
  const cacheKey = localStorage.getItem('cacheKey') || '1';
  queryObj.cacheKey = cacheKey;
  return axiosInstance.get(createUrlWithQuery(url, queryObj)).then((response) => response.data);
}

export function getStatusListByValueClasses(queryObj) {
  const url = '/statusList';
  return axiosInstance.get(createUrlWithQuery(url, queryObj)).then((response) => response.data);
}

export function getRecentlyCreatedSrs(srId) {
  return axiosInstance
    .get(`/sr/_getRecentlyCreatedServiceRequests${srId ? `?id=${srId}` : ''}`)
    .then((response) => response.data);
}
