import { useCallback, useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import { DropDownMenu } from 'common/components/dropDownMenu';
import { ReactComponent as ArrowDownIcon } from 'images/icons/chevron-down-black.svg';
import usePendoTrackEvents from 'common/utils/hooks/usePendoTrackEvents';
import { PENDO_TRACK_EVENTS } from 'constants';
import { ProgressWrapper } from 'containers/App/style';
import CircularProgress from 'common/components/circularProgress';

import {
  StyledButtons,
  StyledCancelBtn,
  StyledDropdownItem,
  StyledForm,
  StyledLinkBtn,
  StyledLinkItemProcess,
  StyledSubTitle,
} from './style';
import useTexts from '../hooks/useTexts';
import { useInitialData, useLinkSrRelatedSrItem } from '../hooks/useRelatedItems';
import { DEFAULT_ITEM_TYPE } from '../constants';
import RelationshipItemDropdowns from '../relationshipItemDropdowns';

const LinkItemProcess = ({ relatedItemsToLink, handleChangeRelatedItems, handleFinishLinkProcess, srId }) => {
  const [itemTypeMenuOpen, setItemTypeMenuOpen] = useState(false);
  const {
    componentTexts: { linkProcessSubTitle, cancelText, linkText },
    getItemTypeText,
  } = useTexts();
  const pendoTrackEvents = usePendoTrackEvents();

  const { data, isLoading } = useInitialData();
  const [selectedItemType, setSelectedItemType] = useState(null);
  const { mutateAsync: linkItems } = useLinkSrRelatedSrItem(srId);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      const srType = Object.values(data)?.find((type) => type.id === DEFAULT_ITEM_TYPE?.id);
      setSelectedItemType({
        id: srType?.id,
        key: srType?.key,
        name: getItemTypeText(srType?.key),
      });
    }
  }, [data, getItemTypeText]);

  const handleItemTypeChange = useCallback(
    (type) => {
      setSelectedItemType({ id: type?.id, key: type?.key, name: getItemTypeText(type?.key) });
      setItemTypeMenuOpen(false);
      pendoTrackEvents(PENDO_TRACK_EVENTS.Choosing_entity_type_in_related_items_box, {
        entityId: type?.id,
        entityType: type?.name,
      });
    },
    [getItemTypeText, pendoTrackEvents],
  );

  const handleOpenMenu = useCallback((val) => {
    setItemTypeMenuOpen(val);
  }, []);

  const handleCancelProcess = useCallback(() => {
    handleFinishLinkProcess();
    const linkedItemsCounts = relatedItemsToLink?.items?.reduce((acc, item) => {
      acc[item?.srTypeString] = (acc[item?.srTypeString] || 0) + 1;
      return acc;
    }, {});
    linkedItemsCounts.totalLinkedItems = relatedItemsToLink?.items?.length;
    pendoTrackEvents(PENDO_TRACK_EVENTS.Cancel_new_related_item_link_click, { linkedItemsCounts });
  }, [handleFinishLinkProcess, pendoTrackEvents, relatedItemsToLink?.items]);

  const handleLinkItems = async () => {
    const linkedRelationship = relatedItemsToLink?.relationship?.id;
    // adding relationship property to the linked items, change srType to number
    const updatedLinkedItems = relatedItemsToLink?.items?.map((item) => ({
      ...item,
      relationship: linkedRelationship,
    }));
    const linkedRelatedItems = {
      srId,
      relatedItems: updatedLinkedItems,
    };
    await linkItems({ linkedRelatedItems });
    const linkedItemsCounts = updatedLinkedItems?.reduce((acc, item) => {
      acc[item?.srTypeString] = (acc[item?.srTypeString] || 0) + 1;
      return acc;
    }, {});
    linkedItemsCounts.totalLinkedItems = updatedLinkedItems?.length;
    pendoTrackEvents(PENDO_TRACK_EVENTS.Link_new_related_item_click, { linkedItemsCounts });
    handleFinishLinkProcess();
  };

  return (
    <StyledLinkItemProcess>
      {isLoading ? (
        <ProgressWrapper sx={{ width: '100%', zIndex: 1, marginTop: '45px' }}>
          <CircularProgress />
        </ProgressWrapper>
      ) : (
        <>
          <StyledForm>
            <DropDownMenu
              text={selectedItemType?.name}
              icon={<ArrowDownIcon />}
              handleOpen={handleOpenMenu}
              open={itemTypeMenuOpen}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              minWidth={142}
            >
              {Object.values(data)?.map(
                (
                  itemType, // TODO 6910
                ) => (
                  <MenuItem
                    key={itemType.id}
                    onClick={() => handleItemTypeChange(itemType)}
                    disabled={itemType.id !== 1}
                  >
                    <StyledDropdownItem>{`${getItemTypeText(itemType.key)} ${
                      itemType.id !== 1 ? '(Coming Soon)' : ''
                    } `}</StyledDropdownItem>
                  </MenuItem>
                ),
              )}
            </DropDownMenu>
            <StyledSubTitle>{linkProcessSubTitle}</StyledSubTitle>
            <RelationshipItemDropdowns
              data={data[selectedItemType?.key]}
              relatedItemsToLink={relatedItemsToLink}
              handleChange={handleChangeRelatedItems}
              srId={srId}
            />
          </StyledForm>
          <StyledButtons>
            <StyledCancelBtn onClick={handleCancelProcess}>{cancelText}</StyledCancelBtn>
            <StyledLinkBtn
              data-cy="approve-link-btn"
              onClick={handleLinkItems}
              disabled={!relatedItemsToLink?.relationship || !relatedItemsToLink?.items?.length}
            >
              {linkText}
            </StyledLinkBtn>
          </StyledButtons>
        </>
      )}
    </StyledLinkItemProcess>
  );
};

export default LinkItemProcess;
