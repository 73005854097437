import { useQuery, useMutation } from '@tanstack/react-query';
import { getBulkActionsFields, deleteTicketsByIds, changeFieldValuesByIds } from 'services/bulkActionsService';

export const useGetBulkActionsFieldsQuery = () =>
  useQuery(['bulkActionsFields'], () => getBulkActionsFields(), { placeholderData: [] });

export function useDeleteTicketsByIdsQuery() {
  const deleteTicketsMutation = useMutation(deleteTicketsByIds);

  const deleteTickets = async (ticketIds) => {
    const data = await deleteTicketsMutation.mutateAsync(ticketIds);
    return data;
  };

  return {
    deleteTickets,
    ...deleteTicketsMutation,
  };
}

export function useUpdateTicketsByIdsQuery() {
  const changeFieldTicketsMutation = useMutation(changeFieldValuesByIds);

  const changeFieldValues = async (ticketIds) => {
    const data = await changeFieldTicketsMutation.mutateAsync(ticketIds);
    return data;
  };

  return {
    changeFieldValues,
    ...changeFieldTicketsMutation,
  };
}
