import { useSelector } from 'react-redux';
import TicketTabs from 'features/ticketTabs';
import MiddlePanel from './middlePanel';
import { StyledResolutionPanel } from './styledResolutionPanel';
import { StyledResolutionPanelContent } from './styledResolutionPanelContent';
import { selectJourneyFilters } from './middlePanel/auditLog/store/slice';

const ResolutionPanel = ({ showTicketAsTab, srPanelComponent, ...restProps }) => {
  const { tab: selectedTabIndex } = useSelector(selectJourneyFilters);
  return (
    <StyledResolutionPanel showTicketAsTab={showTicketAsTab}>
      <StyledResolutionPanelContent>
        <TicketTabs {...restProps} showTicketAsTab={showTicketAsTab}>
          {showTicketAsTab && selectedTabIndex === 0 ? (
            srPanelComponent
          ) : (
            <MiddlePanel {...restProps} showTicketAsTab={showTicketAsTab} />
          )}
        </TicketTabs>
      </StyledResolutionPanelContent>
    </StyledResolutionPanel>
  );
};

export default ResolutionPanel;
