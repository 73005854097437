import { styled } from '@mui/material/styles';
import { ListItem } from '@mui/material';

export const StyledUserInfoCardTeamDetails = styled(ListItem)(({ theme }) => ({
  '.MuiListItemAvatar-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    alignSelf: 'flex-start',

    '& .MuiBox-root': {
      padding: theme.spacing(0.5),

      '& .MuiAvatar-root': {
        width: '45px',
        height: '45px',

        '& svg': {
          height: '30px',
          width: '30px',
        },
      },
    },
  },

  '.MuiListItemText': {
    '&-root': {
      paddingRight: theme.spacing(3),
      margin: 0,
    },

    '&-primary .primary-text, &-secondary': {
      fontFamily: theme.fonts.robotoRegular,
      color: theme.palette.text.title_txt,
    },

    '&-primary .primary-text': {
      fontSize: '1.25rem',
      lineHeight: 1.2,
      paddingTop: theme.spacing(2.375),
    },

    '&-secondary': {
      fontSize: '0.875rem',
      lineHeight: 1.429,
      paddingBottom: theme.spacing(2),
    },
  },
}));
