import { Box, styled } from '@mui/material';

export const StyledChipWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const StyledChip = styled(Box)(({ theme, logType, showMore = false }) => {
  const colors = {
    114: theme?.palette?.generic?.sysaid_light,
    115: theme?.palette?.grey?.bt_alt_grey_reg,
  };
  return {
    maxWidth: '220px',
    height: '26px',
    width: showMore ? 'max-content' : '111px',
    borderRadius: '2px',
    padding: '3px 6px',
    backgroundColor: colors[logType],
    color: '#3D6B5C',
    fontSize: '14px',
    textWrap: 'nowrap',
    textOverflow: !showMore && 'ellipsis',
    overflow: 'hidden',
    marginRight: '6px',
    cursor: showMore && 'pointer',
    textDecoration: logType === 115 && !showMore && 'line-through',
    '&.more-attachments-btn': {
      backgroundColor: colors[logType],
    },
  };
});
