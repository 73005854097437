import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, useRouterState } from '@tanstack/react-router';
import { get } from 'lodash';
import { useCategoryDrivenEnabled } from 'remote-state/accountSettingsServiceHooks';
import { QUERIES_KEYS } from 'constant';
import { useApplicationData } from 'remote-state/applicationHooks';
import { addUpdatingSR, removeUpdatingSR, updateEscalationPrompt } from 'store/srSlice';
import { selectPrefetchSrParams, selectSearchSrParams } from 'features/queue/slice';
import { getQueryKeyFromParams, getRequestFromParams } from 'features/queue/grid/helpers';
import { selectActiveUser } from 'store/userSlice';
import {
  createSR,
  getCategoryListAttributes,
  getColumnFilterList,
  getCustomValueList,
  getFieldAttributes,
  getSrById,
  getStatusListByValueClasses,
  getTicketLockStatus,
  getTicketPermissions,
  getTicketTemplateDataBySrType,
  lockSR,
  newSrFieldUpdate,
  postSearchSrRequest,
  unlockSR,
  updateSR,
  convertSR,
  getRecentlyCreatedSrs,
} from 'services/ticketService';
import { getUserDetailsById, getUserDetailsByName } from 'services/userService';
import { isLocalHost } from 'services/localhost';
import { getUsersByGroup } from 'services/groups';
import {
  getCategoryDrivenTemplate,
  getCategoryDrivenTemplateWithoutThirdLevel,
  getTemplatesList,
} from 'services/templateService';
import { setIsSrSaved } from 'store/saveSrValidationSlice';
import { SR_CODE_TYPE_MAP, SR_TYPE_CODE_MAP, SR_TYPES } from 'features/TicketPanel/constants';
import { APP_CONSTANTS } from 'constants/app';
import { ROUTES_PATHS, TICKETS_OPEN_URL } from 'constants/index';
import { capitalizeFirstLetter, expandQueryKey, findValuesByIds } from 'common/utils/utils';
import { getIsExistingSr, getIsPreviewSr, shouldShowDeEscalationPrompt } from 'common/utils/srUtils';
import { convertFromClientField, convertToClientField } from 'common/utils/fieldUtils';
import { CATEGORIES } from 'features/header/categories/constants';
import { SourcePageEnum } from 'features/templateList/sourcePageEnum';
import { SESSION_STORAGE } from 'features/queue/constants';
import { convertClientColumnConfig } from 'features/queue/utils';
import { SR_PANEL_CONSTANTS, STATUS_FIELD_ID } from 'features/srPanel/consts';
import useMemoizedQueries from 'common/utils/hooks/useMemoizedQueries';
import useTexts from 'features/queue/grid/useTexts';
import { useCategory } from 'store/CategoryContext';
import { getSuggestedCategoriesByText } from 'services/sysaiService';
import { CONSTANTS } from 'common/components/autoPopulateTicket/constants';
import { useGetTemplateById, useTemplateData } from './templateHooks';
import { setToasterMessage } from '../store/globalSlice';

//TODO: Update collator according to locale
const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

const options = {
  staleTime: 1000 * 60 * 15,
};

export function useClosureInformationList(queryObj) {
  const queryKey = expandQueryKey(['closureInformation'], queryObj);
  return useQuery(queryKey, () => getCustomValueList('closureInformation', queryObj), {
    placeholderData: [],
    staleTime: 0,
    cacheTime: 0,
  });
}

export function useIsSrPage(forceSrPage) {
  const router = useRouter();
  return forceSrPage || router.latestLocation?.pathname?.indexOf('ticket') >= 0;
}

export function useCategoryDrivenTemplate(srType) {
  const router = useRouter();
  const { data: isTripleCategory } = useApplicationData(QUERIES_KEYS.IS_EXTERNAL_MODE);
  const { state: catgoryState } = useCategory();
  const { selectedPrimaryCategory, selectedSecondaryCategory, selectedThirdLevelCategory } = catgoryState;
  const isCategoryDrivenEnabled = useCategoryDrivenEnabled()?.data?.data;
  const isNew = router.latestLocation.search.id === 'new';
  const allCategoriesSelectedInDrivenMode =
    isCategoryDrivenEnabled &&
    isNew &&
    !!selectedPrimaryCategory &&
    !!selectedSecondaryCategory &&
    !!(!isTripleCategory || selectedThirdLevelCategory);

  return useQuery(
    [
      'categoryDrivenTemplateData',
      selectedPrimaryCategory,
      selectedSecondaryCategory,
      selectedThirdLevelCategory,
      srType,
    ],
    () => {
      if (isTripleCategory) {
        return getCategoryDrivenTemplate(
          selectedPrimaryCategory,
          selectedSecondaryCategory,
          selectedThirdLevelCategory,
          srType,
        );
      }
      return getCategoryDrivenTemplateWithoutThirdLevel(selectedPrimaryCategory, selectedSecondaryCategory, srType);
    },
    {
      enabled: !!allCategoriesSelectedInDrivenMode && !!sessionStorage.getItem(SESSION_STORAGE.categoryIsSelected),
      ...options,
      staleTime: 100,
      onSettled: () => {
        sessionStorage.removeItem(SESSION_STORAGE.categoryIsSelected);
      },
    },
  );
}

export function useSrById(srId) {
  const queryKey = ['srId', srId];
  return useQuery(queryKey, () => getSrById(srId), {
    placeholderData: {},
    enabled: getIsExistingSr(srId),
    ...options,
  });
}

export function useTicketTemplateData(srTypeOrId, isConvertSrType = false, forceSrPage, disabled) {
  const isExisting = getIsExistingSr(srTypeOrId);
  const isSrPage = useIsSrPage(forceSrPage);
  const { data: existingSR } = useSrById(srTypeOrId);
  const templateDataBySrId = useGetTemplateById(existingSR?.ticketTemplate?.id, 'ticket');
  const templateDataBySrType = useQuery({
    queryKey: ['defaultTemplate', srTypeOrId],
    queryFn: () => getTicketTemplateDataBySrType(srTypeOrId, 'ticket'),
    enabled: !!srTypeOrId && (isSrPage || isConvertSrType) && !isExisting && !disabled,
    select: useCallback(
      (template) => ({
        ...template,
        sections: template.sections.map((section) => ({
          ...section,
          sectionRows: section.sectionRows.map((sectionRow) => ({
            ...sectionRow,
            fields: sectionRow.fields.map((field) => convertToClientField(field, 'fieldId')),
          })),
        })),
      }),
      [],
    ),
    ...options,
  });
  return isExisting ? templateDataBySrId : templateDataBySrType;
}

export const buildHeaderDefaultValues = (template) => {
  const defaultValues = {};
  template?.header?.forEach((headerField) => {
    defaultValues[headerField.fieldName] = headerField.defaultValue;
  });
  return defaultValues;
};

export function useNewSR(template = {}) {
  const userAccount = useSelector(selectActiveUser);
  const { isPlaceholderData, data } = template;
  const newSRObject = useMemo(() => {
    if (isPlaceholderData) {
      return {};
    }
    const headerDefaultValues = buildHeaderDefaultValues(data);

    return {
      id: null,
      assignee: headerDefaultValues.assignee,
      priority: headerDefaultValues.priority,
      status: headerDefaultValues.status,
      assignedGroup: headerDefaultValues.assignedGroup,
      title: headerDefaultValues.title,
      richTextFields: [],
      primaryCategory:
        headerDefaultValues[CATEGORIES.FIRST_LEVEL_CATEGORY_FIELD_KEY] === ''
          ? null
          : headerDefaultValues[CATEGORIES.FIRST_LEVEL_CATEGORY_FIELD_KEY],
      secondaryCategory:
        headerDefaultValues[CATEGORIES.SECOND_LEVEL_CATEGORY_FIELD_KEY] === ''
          ? null
          : headerDefaultValues[CATEGORIES.SECOND_LEVEL_CATEGORY_FIELD_KEY],
      thirdLevelCategory:
        headerDefaultValues[CATEGORIES.THIRD_LEVEL_CATEGORY_FIELD_KEY] === ''
          ? null
          : headerDefaultValues[CATEGORIES.THIRD_LEVEL_CATEGORY_FIELD_KEY],
      submitUser: userAccount?.username,
      srType: SR_CODE_TYPE_MAP[data?.srType || SR_TYPES.incident],
    };
  }, [isPlaceholderData, data, userAccount]);

  return {
    data: newSRObject,
  };
}

export function useSR(forceSrId, forceSrType) {
  const routerState = useRouterState();
  const { search, pathname } = routerState.location;
  const sr = { id: undefined, srType: undefined, ...search };
  let srId = sr.id;
  let srType = sr.srType;
  if (forceSrId) {
    srId = forceSrId;
  }
  if (forceSrType) {
    srType = forceSrType;
  }
  const isPreviewSR = getIsPreviewSr(srId);
  const isExistingSR = getIsExistingSr(srId) && pathname !== ROUTES_PATHS.TEMPLATE_URL;
  const template = useTicketTemplateData(isExistingSR ? srId : srType, forceSrId != null);
  let previewTemplateOrIdFromStorage = sessionStorage.getItem(APP_CONSTANTS.PREVIEW_TEMPLATE_OR_ID_KEY) || null;
  const isTemplateIdFromStorage = previewTemplateOrIdFromStorage
    ? !Number.isNaN(Number(previewTemplateOrIdFromStorage))
    : null;
  const { data } = useTemplateData(isTemplateIdFromStorage ? previewTemplateOrIdFromStorage : undefined, 'ticket');
  if (previewTemplateOrIdFromStorage) {
    if (isTemplateIdFromStorage) {
      previewTemplateOrIdFromStorage = data;
    } else {
      previewTemplateOrIdFromStorage = JSON.parse(previewTemplateOrIdFromStorage);
    }
  }
  const previewTemplate = previewTemplateOrIdFromStorage || template;
  const existingSR = useSrById(isExistingSR ? srId : undefined);
  const newSR = useNewSR(isPreviewSR ? previewTemplate : template);
  const srResponse = !isExistingSR ? newSR : existingSR;
  return {
    srId,
    sr: srResponse,
    error: srResponse?.error?.response,
    template: isPreviewSR ? { ...template, data: previewTemplate } : template,
  };
}

export function useCreateNewSR(srId, srType) {
  const template = useTicketTemplateData(srType, true);
  const srResponse = useNewSR(template);
  return {
    srId,
    sr: srResponse,
    error: srResponse?.error?.response,
    template,
  };
}

export function useSaveSR(srId) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isExistingSR = getIsExistingSr(srId);
  const onSuccess = (updatedTicket) => {
    if (updatedTicket) {
      if (isExistingSR) {
        if (updatedTicket.data?.sr) {
          queryClient.setQueryData(['srId', srId], () => updatedTicket.data.sr);
        }
      } else if (updatedTicket.sr?.id && updatedTicket.sr?.ticketTemplate?.id) {
        queryClient.setQueryData(['srId', updatedTicket.sr.id], () => updatedTicket.sr);
      }
    }
    // global.queueAbortController?.abort(); // cancel on going search requests
    // queryClient.invalidateQueries({ queryKey: ['queueData'] });
  };
  const onSettled = (data) => {
    dispatch(setIsSrSaved({ status: data?.status || 200 }));
  };
  const create = useMutation(createSR, {
    onSuccess,
    onSettled,
    onError: () => {
      createSR({ status: 200 });
    },
  });
  const update = useMutation(updateSR, {
    onSuccess,
    onSettled: (data) => {
      onSettled(data);
      if (data.status < 200 || data.status > 300) {
        updateSR({ status: 200 });
      }
    },
  });
  return !isExistingSR ? create : update;
}

const handleRequestUserFields = ({ srObject = {}, associatedFields }) => {
  if (!Object.keys(srObject)?.length) return [];
  const requestUserSrFields = Object.keys(srObject)?.filter((key) => key.startsWith(CONSTANTS.REQUEST_USER_PREFIX));
  if (!requestUserSrFields?.length) return {};
  const { requestUser } = associatedFields || {};
  const requestUserFieldsMap = requestUserSrFields?.reduce((acc, field) => {
    const fieldName = field?.split('.')[1]; // Extract the part after 'requestUser.'
    acc[field] = requestUser?.[fieldName];
    return acc;
  }, {});
  if (associatedFields?.assetId) {
    requestUserFieldsMap.assetId = associatedFields.assetId;
  }
  if (associatedFields?.ciId) {
    requestUserFieldsMap.ciId = associatedFields.ciId;
  }
  return requestUserFieldsMap;
};

export const useUpdateAssociatedFields = ({ srObject = {}, updateObj, key }) => {
  const updatedSRValues = useMemo(() => {
    if (updateObj) {
      // temporary solution until fixed in mocks api
      const lastFieldUpdate = key || Object.keys(updateObj)[0];
      const sr = { ...srObject, ...updateObj };
      return {
        ...sr,
        lastFieldUpdate,
        accountId: sr.accountId || localStorage.getItem(APP_CONSTANTS.ACCOUNT_ID_LOCAL_KEY),
        agreement: sr.agreement?.id || sr.agreement || 1,
        requestUser: sr.requestUserName || sr.requestUser?.userName || sr.requestUser || null,
        requestUserUpdated: true,
        category: sr.category?.firstLevelKey || sr.primaryCategory || 0,
        subCategory: sr.category?.secondLevelKey || sr.secondaryCategory || 0,
        thirdLevelCategory: sr.category?.thirdLevelKey || sr.thirdLevelCategory || 0,
        requestUserManager: sr.requestUser?.userManagerName || null,
        status: sr.status ? parseInt(sr.status, 10) : 0,
      };
    }
    return null;
  }, [updateObj, key, srObject]);

  const isCategoryDrivenEnabled = useCategoryDrivenEnabled()?.data?.data;

  const enabled = !isCategoryDrivenEnabled && !isLocalHost();

  const {
    data: associatedFields,
    isError,
    ...rest
  } = useQuery({
    queryKey: ['associatedFields', updatedSRValues],
    queryFn: () => newSrFieldUpdate(updatedSRValues),
    enabled: Boolean(enabled && updatedSRValues),
  });

  if ((!enabled || isError) && updateObj) {
    return { data: updateObj, isError, ...rest };
  }

  if (associatedFields) {
    const requestUserFields = handleRequestUserFields({ srObject, associatedFields });
    return { data: { ...updateObj, ...associatedFields, ...requestUserFields }, isError, ...rest };
  }

  return { data: associatedFields, isError, ...rest };
};

export function useUpdateSR() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const data = useApplicationData(QUERIES_KEYS.GENERAL_SETTINGS);
  let deescalationPromptEnabled = false;
  let manualPriorityChangesEnabled = false;
  if (data != null) {
    deescalationPromptEnabled = data.deescalationPromptEnabled;
    manualPriorityChangesEnabled = data.manualPriorityChangesEnabled;
  }
  const { mutateAsync } = useMutation(updateSR, {
    onSuccess: (data, variables) => {
      const updateTicket = data.data.sr;
      if (updateTicket) {
        queryClient.setQueryData(['srId', updateTicket.id], (oldTicket) => {
          const shouldDeescalate = shouldShowDeEscalationPrompt(
            updateTicket,
            oldTicket,
            variables.requestParams,
            deescalationPromptEnabled,
            manualPriorityChangesEnabled,
          );
          if (shouldDeescalate) {
            dispatch(updateEscalationPrompt({ show: true, srId: updateTicket.id }));
          }

          return {
            ...oldTicket,
            ...updateTicket,
            deescalation: shouldDeescalate,
          };
        });
        // global.queueAbortController?.abort(); // cancel on going search requests
        if (variables?.requestParams?.richTextFields) {
          queryClient.invalidateQueries({ queryKey: ['queueData'] });
        }
      }
    },
    onSettled: (data, error, variables) => {
      dispatch(removeUpdatingSR(variables.id));
      dispatch(
        setIsSrSaved({
          status: data.status,
          isClearRequiredField: data?.data?.params?.isClearRequiredField,
          requiredFieldsList: data?.data?.params?.fieldsList,
        }),
      );
    },
  });

  const checkAndUpdateSr = useCallback(
    async (objectToUpdate) => {
      dispatch(addUpdatingSR(objectToUpdate.id));
      const response = await mutateAsync(objectToUpdate);
      return response;
    },
    [mutateAsync, dispatch],
  );

  return { checkAndUpdateSr };
}

export function useConvertSR() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(convertSR, {
    onSuccess: (data) => {
      const convertedTicket = data?.data?.sr;
      if (convertedTicket) {
        queryClient.setQueryData(['srId', convertedTicket?.id], (oldTicket) => ({
          ...oldTicket,
          ...convertedTicket,
        }));
      }
    },
    onSettled: (data, error, variables) => {
      dispatch(removeUpdatingSR(variables.id));
    },
  });

  const convertSrType = useCallback(
    async (objectToUpdate) => {
      dispatch(addUpdatingSR(objectToUpdate.id));
      return mutateAsync(objectToUpdate);
    },
    [mutateAsync, dispatch],
  );

  return { convertSrType };
}

export function useFieldAttributes(fields = [], forFilter = false, srId = null) {
  const queryClient = useQueryClient();
  // const isQueuePage = useIsQueuePage();
  // const queueCache = queryClient.getQueryData(['queueData']);

  const queriesInput = useMemo(
    () =>
      fields.map((field) => {
        const {
          fieldId,
          queryForIds,
          values,
          getAll,
          srType,
          query,
          customColumn = false,
        } = convertFromClientField(field, 'fieldId');
        const queryObj = {};
        let queryKey = ['fieldAttribute', fieldId, customColumn, forFilter, srId];

        if (getAll) {
          queryObj.getAll = getAll;
        }

        if (srType) {
          queryObj.srType = Number(srType);
        }

        if (query) {
          queryObj.query = query;
        }

        if (customColumn) {
          queryObj.customColumn = customColumn;
        }

        if (values && Array.isArray(values)) {
          queryKey = [...queryKey, values.join(',')];
        }

        queryKey = expandQueryKey(queryKey, queryObj);

        return {
          queryKey,
          queryFn: () => {
            if (forFilter && fieldId === STATUS_FIELD_ID && !customColumn) {
              return getStatusListByValueClasses(queryObj);
            }
            if (!queryForIds || !values?.length) {
              return getFieldAttributes(fieldId, queryObj);
            }
            //getQueriesData will return all data that matches field ids.
            const getAllAttributesCache = queryClient.getQueriesData([
              'fieldAttribute',
              fieldId,
              customColumn,
              forFilter,
              srId,
            ]);
            if (Array.isArray(getAllAttributesCache)) {
              //eslint-disable-next-line @typescript-eslint/no-unused-vars
              const valuesList = getAllAttributesCache?.flatMap(([_, field]) => field?.values || []);
              const fieldData = getAllAttributesCache?.[0]?.[1];
              if (fieldData) {
                const { valuesFound, idsNotFound } = findValuesByIds(valuesList, values);

                const dataResponse = [
                  ...valuesFound.map((valueInCache) => Promise.resolve({ ...fieldData, values: [valueInCache] })),
                  ...idsNotFound.map((id) => getFieldAttributes(fieldId, { id, customColumn })),
                ];
                return Promise.all(dataResponse);
              }
            }

            return Promise.all(values.map((id) => getFieldAttributes(fieldId, { id, customColumn }))); // @TODO - change to one requests with value list support after creation in BE
          },
          enabled: !!fieldId, // on queue we want to delay this call to after search call
          placeholderData: {},
          select: (result) => {
            if (!Array.isArray(result)) return result;
            return {
              ...result[0],
              values: result?.flatMap((field) => field?.values || []),
            };
          },
          onSuccess: (data) => {
            //Check if it is the multiple search request by ids. In that case we will save all data in react-query cache separetedly
            if (!Array.isArray(data?.values) || !queryForIds) return;
            data.values.forEach((option) => {
              const value = {
                ...data,
                values: [option],
              };
              queryClient.setQueryData(['fieldAttribute', fieldId, customColumn, forFilter, `id${option.id}`], value);
            });
          },
        };
      }),
    [fields, forFilter, queryClient, srId],
  );
  return useMemoizedQueries(queriesInput);
}

export function useStatusList(queryObj, filters = null) {
  const fieldAttributesInput = useMemo(
    () => [{ fieldId: STATUS_FIELD_ID, srType: queryObj?.srType, getAll: queryObj?.getAll, queryObj }],
    [queryObj],
  );
  const queryResult = useFieldAttributes(fieldAttributesInput)?.[0];
  let filteredStatusList = queryResult.data.values;

  if (filters && filteredStatusList?.length > 0) {
    filteredStatusList = filteredStatusList.filter((status) =>
      Object.keys(filters).every((filter) => filters[filter] === status[filter]),
    );
  }

  const isTicketStatusClosedType = useCallback(
    (statusKey) => {
      if (queryResult.data?.values?.length > 0) {
        const foundStatus = queryResult.data.values.find((status) => status.id === statusKey);
        if (foundStatus?.valueClass === SR_PANEL_CONSTANTS.CLOSED_CLASS) {
          return foundStatus;
        }
      }
      return null;
    },
    [queryResult],
  );

  return { ...queryResult, data: filteredStatusList, isTicketStatusClosedType };
}

export function useAssigneesList(groupName) {
  return {
    admins: useApplicationData(QUERIES_KEYS.ADMINISTRATORS),
    groups: useApplicationData(QUERIES_KEYS.GROUPS),
    adminsByGroup: useQuery(['adminsByGroup', groupName], () => getUsersByGroup(groupName), {
      placeholderData: [],
      select: (list) => list.sort((a, b) => collator.compare(a.userName, b.userName)),
      enabled: !!groupName,
      ...options,
    }),
  };
}

export function useUserDetailsById(userId) {
  return useQuery([QUERIES_KEYS.USER_DETAILS_BY_ID, userId], () => getUserDetailsById(userId), {
    placeholderData: {},
    enabled: !!userId,
    staleTime: 0,
    cacheTime: 0,
  });
}

export function useUserDetailsByName(userName) {
  return useQuery([QUERIES_KEYS.USER_DETAILS_BY_NAME, userName], () => getUserDetailsByName(userName), {
    placeholderData: {},
    enabled: !!userName,
    staleTime: 0,
    cacheTime: 0,
  });
}

const modifyFilterList = (data, listKey) => {
  if (listKey === 'requestUser') {
    const newDataWithValueName = [];
    data.forEach((item) => {
      newDataWithValueName.push({
        valueKey: item.id,
        valueCaption: `${item?.userName}`,
        valueClass: 0,
        keyName: listKey[0] + item.id.toString().replace(/\s+/g, ''),
      });
    });
    return newDataWithValueName;
  }
  const newDataWithValueName = [];
  data.forEach((item) => {
    newDataWithValueName.push({ ...item, keyName: listKey + item?.valueKey?.toString().replace(/\s+/g, '') });
  });
  return newDataWithValueName;
};

export function useColumnFilterList(listKey) {
  return useQuery([listKey], () => getColumnFilterList(listKey), {
    select: (filterList) => modifyFilterList(filterList, listKey),
    enabled: Boolean(
      listKey &&
        !(listKey === 'primaryCategory' || listKey === 'secondaryCategory' || listKey === 'thirdLevelCategory'),
    ),
  });
}

export const modifyCategoriesFilter = (data, listKey) => {
  if (!data) {
    return;
  }
  const newDataWithValueName = [];
  data.forEach((item) => {
    newDataWithValueName.push({
      id: item.id,
      valueClass: 0,
      valueKey: item.id,
      valueCaption: item.value,
      name: item.value,
      keyName: listKey?.[0] + item.id.toString().replace(/\s+/g, ''),
    });
  });
  return newDataWithValueName;
};

export function useCategoriesAttributes(primaryKey, secondaryKey, srType) {
  const queryKeys = useMemo(() => ['categoriesAttr', srType], [srType]);

  const options = useMemo(
    () => ({
      select: (categories) => {
        if (categories?.values?.length > 0) {
          return categories?.values?.sort((a, b) => collator.compare(a.valueForFilter, b.valueForFilter));
        }
        return [];
      },
      placeholderData: [],
    }),
    [],
  );

  const queries = useMemo(
    () => [
      {
        queryKey: [...queryKeys, 'primaryCategory'],
        queryFn: () => getCategoryListAttributes(null, null, null, srType, 59),
        ...options,
      },
      {
        queryKey: [...queryKeys, 'secondaryCategories', primaryKey],
        queryFn: () => getCategoryListAttributes(primaryKey, null, null, srType, 60),
        ...options,
        enabled: !!primaryKey && primaryKey > -1,
      },
      {
        queryKey: [...queryKeys, 'thirdLevelCategories', primaryKey, secondaryKey],
        queryFn: () => getCategoryListAttributes(primaryKey, secondaryKey, null, srType, 61),
        ...options,
        enabled: !!(primaryKey && (secondaryKey || secondaryKey === 0) && secondaryKey > -1),
      },
    ],
    [queryKeys, primaryKey, secondaryKey, srType, options],
  );

  return useMemoizedQueries(queries);
}

export function useModifiedCategoriesAttributes(categoryType, primaryKey, secondaryKey, srType, fieldId) {
  const categories = useCategoriesAttributes(primaryKey, secondaryKey, srType, fieldId);

  const primaryCategories = categories[0]?.data;
  const secondaryCategories = categories[1]?.data;
  const thirdLevelCategories = categories[2]?.data;

  const modifiedCategoriesAttributes = useMemo(() => {
    switch (categoryType) {
      case 'primaryCategory':
        return modifyCategoriesFilter(primaryCategories, 'primaryCategory');
      case 'secondaryCategory':
        return modifyCategoriesFilter(secondaryCategories, 'secondaryCategory');
      case 'thirdLevelCategory':
        return modifyCategoriesFilter(thirdLevelCategories, 'thirdLevelCategory');
      case 'all':
        return [
          modifyCategoriesFilter(primaryCategories, 'primaryCategory'),
          modifyCategoriesFilter(secondaryCategories, 'secondaryCategory'),
          modifyCategoriesFilter(thirdLevelCategories, 'thirdLevelCategory'),
        ];
      default:
        break;
    }
  }, [categoryType, primaryCategories, secondaryCategories, thirdLevelCategories]);
  return modifiedCategoriesAttributes;
}

export function useTicketPermissions(action, srId) {
  return useQuery(
    ['ticketPermissions', action, srId],
    () => {
      if (srId) {
        return getTicketPermissions(action, srId);
      }
    },
    {
      enabled: !!srId && typeof srId === 'number',
      staleTime: 0,
      cacheTime: 1000 * 60,
    },
  );
}

export function buildTemplatesListForDropdown(res) {
  const response = { list: res.currentList?.slice(0, 100) || [] };
  response.values = response.list.map((t) => ({
    id: t.id,
    value: t.templateName,
  }));
  return response;
}

//might need to combine them with reduce here.. (also use forDropdown to convert)
export function useTemplateListForDropdown(sort, columnFilters, defaultsFirst) {
  return useQuery(
    ['templatesList', sort, columnFilters, defaultsFirst],
    () =>
      getTemplatesList({
        page: 0,
        size: 100,
        columnFilters,
        sort,
        defaultsFirst,
        sourcePage: SourcePageEnum.CREATE_TICKET_PAGE,
        excludeSubTypes: true,
      }),
    {
      placeholderData: {},
      select: (res) => buildTemplatesListForDropdown(res),
    },
  );
}

export function useTicketLockStatus(srId) {
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const username = userAccount?.username;
  const isExistingSR = getIsExistingSr(srId) && router.latestLocation.pathname !== ROUTES_PATHS.TEMPLATE_URL;
  return useQuery(['ticketLockStatus', srId], () => getTicketLockStatus({ username, srId }), {
    // we are sending the username to the api to add this user the viewers of this ticket, so when this ticket gets updated by another user, it will send update information in realtime (through the useChannel(sessionId, ({ data, name }) ) only to the viewers of this ticket for permissions and sensitive information purposes. this logic does not have anything to do with get locking status, but we are doing this to avoid 2 api calls on first render of a ticket. we need to refactor the name of this hook and service to make it more clear that it's not only for locking.
    placeholderData: { isLocked: false },
    enabled: isExistingSR,
    staleTime: 0,
    cacheTime: 0,
    select: (data) => ({
      isLocked: data?.isLocked || data?.ticketLock, // ticketLock in case of first fetch from server, isLocked in case of cache
      lockingUser: data?.lockingUser || data?.lockUsername,
    }),
  });
}

export function useHandleTicketLock(srId) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const lockingUser = userAccount?.username;
  const isExistingSR = getIsExistingSr(srId) && router.latestLocation.pathname !== ROUTES_PATHS.TEMPLATE_URL;
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const mutation = useMutation(lockSR, {
    onSuccess: () => {
      if (srId) {
        queryClient.setQueryData(['ticketLockStatus', srId], () => ({
          isLocked: true,
          lockingUser,
        }));
      }
    },
  });
  const { mutate, mutateAsync, ...rest } = mutation;
  const newMutate = (...params) => {
    if (isExistingSR && !lockingDetails?.isLocked) {
      mutate(...params);
    }
  };
  const newMutateAsync = (...params) => {
    if (isExistingSR && !lockingDetails?.isLocked) {
      return mutateAsync(...params);
    }
    return Promise.resolve();
  };
  return { mutate: newMutate, mutateAsync: newMutateAsync, ...rest };
}

export function useHandleTicketUnlock(srId, cb) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const userAccount = useSelector(selectActiveUser);
  const isExistingSR = getIsExistingSr(srId) && router.latestLocation.pathname !== ROUTES_PATHS.TEMPLATE_URL;
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const mutation = useMutation(unlockSR, {
    onSuccess: () => {
      if (srId) {
        queryClient.setQueryData(['ticketLockStatus', srId], () => ({
          isLocked: false,
          lockingUser: null,
        }));
      }
    },
    onSettled: () => {
      if (cb) {
        cb();
      }
    },
  });
  const { mutate, mutateAsync, ...rest } = mutation;
  const newMutate = (...params) => {
    const { forceUnlock } = params[0];
    if (
      forceUnlock ||
      (isExistingSR &&
        lockingDetails &&
        lockingDetails.isLocked &&
        lockingDetails.lockingUser === userAccount?.username)
    ) {
      mutate(...params);
    }
  };
  const newMutateAsync = (...params) => {
    if (
      isExistingSR &&
      lockingDetails &&
      lockingDetails.isLocked &&
      lockingDetails.lockingUser === userAccount?.username
    ) {
      return mutateAsync(...params);
    }
    return Promise.resolve();
  };
  return { mutate: newMutate, mutateAsync: newMutateAsync, ...rest };
}

export function useQueueData(options) {
  const totalServiceRequest = useRef(null);
  const searchSrParams = useSelector(selectSearchSrParams);
  const prefetchSrParams = useSelector(selectPrefetchSrParams);

  const prefetchSearchSrParams = useMemo(
    () =>
      searchSrParams && prefetchSrParams?.startRow && prefetchSrParams?.endRow
        ? {
            ...searchSrParams,
            startRow: prefetchSrParams.startRow,
            endRow: prefetchSrParams.endRow,
          }
        : null,
    [searchSrParams, prefetchSrParams],
  );

  const searchSrRequest = getRequestFromParams(searchSrParams);
  const prefetchSearchSrRequest = getRequestFromParams(prefetchSearchSrParams);

  const queryFn = async () => {
    global.queueAbortController = new AbortController();
    return postSearchSrRequest(searchSrRequest, global.queueAbortController?.signal);
  };

  const prefetchQueryFn = async () => {
    global.queueAbortController = new AbortController();
    return postSearchSrRequest(prefetchSearchSrRequest, global.queueAbortController?.signal);
  };

  const queryKey = useMemo(() => getQueryKeyFromParams(searchSrParams), [searchSrParams]);
  const prefetchQueryKey = useMemo(() => getQueryKeyFromParams(prefetchSearchSrParams), [prefetchSearchSrParams]);

  const queueData = useQuery(queryKey, {
    queryFn,
    enabled: Boolean(searchSrParams && Object.keys(searchSrParams).length > 0),
    staleTime: 1000 * 60 * 15,
    select: useCallback(
      (queueData) => ({
        ...queueData,
        columnConfiguration: queueData.columnConfiguration.map((col) => convertClientColumnConfig(col)),
      }),
      [],
    ),
    ...options,
  });

  const { data } = queueData;
  totalServiceRequest.current = totalServiceRequest.current ?? data?.countTotal;

  useQuery({
    queryKey: prefetchQueryKey,
    queryFn: prefetchQueryFn,
    enabled:
      Boolean(prefetchSearchSrParams && Object.keys(prefetchSearchSrParams).length > 0) &&
      (totalServiceRequest.current === null || prefetchSearchSrParams.startRow < totalServiceRequest.current),
    staleTime: 1000 * 60 * 15,
    select: useCallback(
      (queueData) => ({
        ...queueData,
        columnConfiguration: queueData.columnConfiguration.map((col) => convertClientColumnConfig(col)),
      }),
      [],
    ),
    ...options,
  });

  return queueData;
}

export function useLoadedSrIds() {
  return useQueueData({
    select: useCallback((queueData) => queueData.serviceRequests?.map((item) => item[0].value) ?? [], []),
  });
}

export function useLoadedSrIdsWithValue(selectValueKey) {
  const generateResponse = useCallback(
    (queueData) =>
      queueData.serviceRequests?.reduce((result, item) => {
        const keyVal = item[0].value;
        const value = item.find((col) => col.field === selectValueKey)?.value;
        result[keyVal] = value;
        return result;
      }, {}),
    [selectValueKey],
  );
  return useQueueData({
    select: (queueData) => generateResponse(queueData) ?? {},
  });
}

export function useQueueCountTotal() {
  return useQueueData({
    select: (queueData) => queueData.countTotal || 0,
  });
}

export function useTicketsLockMap() {
  return useQueueData({
    select: (queueData) => queueData.ticketsLockMap,
  });
}

export function useSrTypeFilterCounters() {
  return useQueueData({
    select: (queueData) =>
      queueData ? { ...queueData.srTypeFilterCounter, ALL: queueData.srTypeFilterCounterAll } : null,
    placeholderData: {},
  });
}

export function useIsLastRowWithData() {
  return useQueueData({
    select: (queueData) => queueData.serviceRequests?.length > 0,
  });
}

export const useMigrationFailedHandler = () => {
  const dispatch = useDispatch();
  const { migrationFailedMessage } = useTexts();
  const [classicSRUrl, setClassicSRUrl] = useState('');

  const handleMigrationFailed = (searchString) => {
    // Dispatch toaster message
    dispatch(
      setToasterMessage({
        message: migrationFailedMessage,
        type: 'error',
        styleDialogOverrides: { zIndex: '1301' },
      }),
    );

    // Set URL for classic SR
    setClassicSRUrl(TICKETS_OPEN_URL.CLASSIC(searchString));
  };

  const handleModalClose = () => setClassicSRUrl('');

  return { classicSRUrl, handleMigrationFailed, handleModalClose };
};

export const useAiTitleAndCategories = () =>
  useMutation({
    mutationFn: getSuggestedCategoriesByText,
    onSuccess: (data) => data?.data || {},
  });

export const useLinkedSrLists = ({ sr, fields, dependantFields, updateSrFunc }) => {
  const queryClient = useQueryClient();
  const isTemplate = !sr;
  const fieldAttributes = useFieldAttributes(fields);
  const prevSourceFieldsRef = useRef(new Map());

  const dependantFieldsMap = useMemo(
    () =>
      Object.entries(dependantFields || {})
        .map((entry) =>
          entry.map((fieldKey) => {
            const fieldId = fieldKey?.match(/CustomColumn(\d+)/)?.[1];

            return fieldId
              ? fields?.find((field) => field.customColumn && parseInt(field.fieldId, 10) === +fieldId)?.fieldName
              : fieldKey;
          }),
        )
        .reduce((acc, [key, value]) => {
          if (key && value) {
            acc[value] = acc[value] || [];
            acc[value].push(key);
          }

          return acc;
        }, {}),
    [fields, dependantFields],
  );

  const loadLinkedValues = useCallback(
    ({ updateObj }) => {
      const [sourceFieldName, sourceValueId] = Object.entries(updateObj || {})[0];
      const dependantFields = (dependantFieldsMap[sourceFieldName] || []).map((fieldName) =>
        fields?.find((field) => field?.fieldName === fieldName),
      );

      if (!dependantFields.length) return;

      const sourceFieldValue = fieldAttributes
        ?.find((attr) => attr?.data?.fieldName === sourceFieldName)
        ?.data?.values?.find((value) => String(value?.id) === String(sourceValueId))?.value;

      dependantFields.forEach(async (dependantField) => {
        const dependantFieldId = parseInt(dependantField?.fieldId, 10);
        const dependantFieldName = dependantField?.fieldName;
        const newDependantFieldAttributes = await getFieldAttributes(dependantFieldId, {
          customColumn: dependantField.customColumn,
          fieldFilter: sourceFieldValue,
        });
        const newDependantFieldValues = newDependantFieldAttributes?.values;

        const dependantFieldValue = isTemplate
          ? fields?.find((field) => field?.fieldName === dependantFieldName)?.defaultValue
          : sr[dependantFieldName];

        const isDependantValueExistInList = newDependantFieldValues?.some((field) => field.id === dependantFieldValue);

        if (dependantFieldValue && !isDependantValueExistInList) {
          const objectToUpdate = { [dependantFieldName]: null };
          if (updateSrFunc) await updateSrFunc(objectToUpdate);
        }

        const baseQueryKey = ['fieldAttribute', dependantFieldId, true, false];

        if (isTemplate) {
          queryClient.setQueryData([...baseQueryKey, null, 'customColumntrue'], newDependantFieldAttributes);
        } else {
          queryClient.setQueryData(
            [...baseQueryKey, sr?.id, `srType${sr?.srType}`, 'customColumntrue'],
            newDependantFieldAttributes,
          );
          queryClient.setQueryData([...baseQueryKey, sr?.id, 'customColumntrue'], newDependantFieldAttributes);
        }
      });
    },
    [dependantFieldsMap, fieldAttributes, fields, queryClient, sr, isTemplate, updateSrFunc],
  );

  useEffect(() => {
    const sourceFields = fields?.filter((field) => Object.keys(dependantFieldsMap).includes(field.fieldName));

    sourceFields?.forEach((sourceField) => {
      const fieldName = sourceField.fieldName;
      const fieldValue = (isTemplate ? sourceField.defaultValue : get(sr, fieldName)) || null;

      if (prevSourceFieldsRef.current?.get(fieldName) !== fieldValue) {
        loadLinkedValues({ updateObj: { [fieldName]: fieldValue } });
        prevSourceFieldsRef.current?.set(fieldName, fieldValue);
      }
    });
  }, [fields, dependantFieldsMap, loadLinkedValues, isTemplate, sr]);

  return { loadLinkedValues };
};

const buildSrsDataForDropdown = (data) => {
  const newData = data?.map((item) => ({
    id: item?.id,
    value: item?.title,
    srType: item?.srType,
    valueKey: item?.id,
    srTypeString: capitalizeFirstLetter(SR_TYPE_CODE_MAP[item?.srType]),
    valueCaption: `${item?.id} ${item?.title}`, // being used to filter the multi-select
    chipCaption: `${capitalizeFirstLetter(SR_TYPE_CODE_MAP[item?.srType])} #${item?.id}`,
  }));
  return newData;
};

export function useGetRecentlyCreatedSrs(srId) {
  return useQuery(['recentlyCreatedSrs', srId], () => getRecentlyCreatedSrs(srId), {
    placeholderData: [],
    select: (data) => buildSrsDataForDropdown(data),
    staleTime: 0,
    cahceTime: 0, // API to be executed every time when linking items so the newly related items won't appear in the list OR deleted ones will appear
  });
}
