import { useSelector } from 'react-redux';
import { useRouter } from '@tanstack/react-router';
import { useTicketLockStatus, useTicketPermissions } from 'remote-state/ticketServiceHooks';
import { selectActiveUser } from 'store/userSlice';
import DataFilters from 'features/resolutionPanel/dataFilters';
import Footer from 'features/resolutionPanel/footer';
import { StyledTicketTabs, StyledTabContainer } from './style';

const TicketTabs = (props) => {
  const { children, showTicketAsTab, srType, scrollToAuditLogRecord } = props;
  const router = useRouter();
  const srId = router.latestLocation.search.id;
  const { data: hasEditPermissions } = useTicketPermissions('edit', srId);
  const userAccount = useSelector(selectActiveUser);
  const { data: lockingDetails } = useTicketLockStatus(srId);
  const isTicketLocked = lockingDetails?.isLocked && lockingDetails?.lockingUser !== userAccount?.username;
  const showFooter = !isTicketLocked && hasEditPermissions;

  return (
    <StyledTicketTabs>
      <StyledTabContainer>
        <DataFilters srId={srId} srType={srType} showTicketAsTab={showTicketAsTab} />
        {children}
      </StyledTabContainer>
      {showFooter && (
        <Footer scrollToAuditLogRecord={scrollToAuditLogRecord} srId={srId} showTicketAsTab={showTicketAsTab} />
      )}
    </StyledTicketTabs>
  );
};

export default TicketTabs;
