import { Box, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchField from 'common/components/searchField';

export const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: '475px',
    padding: '25px 5px 0px 0px',
  },
}));

export const StyledDrawerHead = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px 30px 0px 50px',
  '& .headLeft': {
    '.title': {
      color: theme.palette.text.txt_default,
      marginBottom: '5px',
      '.text': {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '24px',
      },
      '.stat': {
        padding: '6px 12px',
        background: theme.palette.grey.divider_grey_v2,
        borderRadius: '16px',
        fontSize: '14px',
        marginLeft: '8px',
        fontWeight: 'bold',
        '&.error': {
          color: theme.palette.labels.bt_red_reg,
        },
      },
    },
    '.subtitle': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.text.secondary_txt,
    },
  },
  '.headRight': {
    marginLeft: 'auto',
    '.icon': {
      color: theme.palette.icons.icons_grey_v1,
      fontSize: '20px',
      cursor: 'pointer',
    },
  },
}));

export const ColumnListWrapper = styled(Box)(() => ({
  padding: '0px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const DnDContextContainer = styled(Box)(({ style }) => ({
  ...style,
  width: '4px',
  background: 'rgba(48, 48, 48, 0.12)',
  borderRadius: '5px',
}));

export const StyledSearchField = styled(SearchField)(() => ({
  width: '100%',
  '.MuiInputBase-root': {
    width: '100%',
    height: '35px',
  },
}));

export const StyledAutoComplete = styled(Box)(() => ({
  '.MuiAutocomplete-popper ': {
    '.MuiPaper-root': {
      margin: '5px 0',
      boxShadow: '0px 2px 8px 0px rgba(118, 130, 150, 0.35)',
    },
  },
}));

export const StyledColumnLi = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  height: '40px',
  display: 'block',
  color: theme.palette.text.title_txt,
  padding: '20px 16px',
  '&:hover': {
    background: theme.palette.background.selected,
  },
  '.match_part': {
    color: theme.palette.text.blue_user_name,
  },
}));

export const ColumnListItemStyled = styled(Box, {
  shouldForwardProp: (prop) => !['isDisabled', 'isDragging', 'isHighlighted'].includes(prop),
})(({ isDisabled, isHighlighted, isDragging, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '395px',
  alignItems: 'center',
  borderRadius: '6px',
  background: isHighlighted ? theme.palette.generic.sysaid_very_light : 'transparent',
  border: isHighlighted ? theme.shape.border.border_3 : theme.shape.border.border,
  padding: '3px 0px',
  overflow: 'hidden',
  height: '37px',
  fontSize: '14px',
  opacity: isDisabled ? '0.5' : '1',
  '&:hover': {
    background: isDragging ? theme?.palette?.generic?.primary : theme.palette.background.bg_hover_main,
    '.remove': {
      display: 'block !important',
    },
  },
  '&:before': {
    content: '" "',
    display: 'block',
    height: '40px',
    margin: '-5px 0px',
    width: '5px',
    background: isDragging ? theme.palette.generic.sysaid_default_hover : 'transparent',
  },
  '.title': {
    padding: '0px 5px',
    color: isDragging ? theme.palette.text.secondary_txt : theme.palette.text.title_txt,
    lineHeight: '21px',
    cursor: 'default',
  },
  '.drag': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 5px',
    cursor: !isDisabled && 'grab',
  },
  '.toolbar': {
    marginLeft: 'auto',
    paddingRight: '15px',
    '.remove': {
      display: 'none',
      cursor: 'pointer',
      color: theme.palette.icons.icons_grey_v3,
    },
    '.pinned': {
      transform: 'rotate(45deg)',
    },
    '.icon': {
      width: '15px',
      padding: '5px 0px 0px 0px',
    },
  },
}));

export const ColumnListItemWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '7px',
  fontSize: '14px',
  color: theme.palette.text.title_txt,
  '.indexNumber': {
    textAlign: 'center',
    color: theme.palette.text.secondary_txt,
    paddingLeft: '10px',
    width: '45px',
  },
}));

export const StyledAddColumn = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: '38px',
  margin: '16px 0',
  '.addInputWrap': {
    width: '395px',
  },
  '.addIconWrap': {
    paddingLeft: '15px',
    width: '45px',
  },
}));

export const StyledAddIcon = styled(Box)(({ disabled, theme }) => ({
  background: disabled ? theme.palette.grey.divider_grey_v3 : theme.palette.icons.field_used,
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.icons.icons_in_journey,
  fontSize: '12px',
  justifyContent: 'center',
  svg: {
    width: '10px',
    fill: theme.palette.icons.icons_in_journey,
    rect: {
      fill: theme.palette.icons.icons_in_journey,
    },
  },
}));

export const StyledAddInput = styled('input')(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.disabled}`,
  width: '395px',
  padding: '10px',
  borderRadius: '6px',
  fontSize: '14px',
  outline: '0 !important',
}));

export const ColumnsConfigurationBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  '.openDrawer': {
    position: 'absolute',
    zIndex: '100',
    right: '0px',
    top: '4px',
    padding: '0 12px 0 2px',
    background: theme.palette.grey.bt_alt_grey_light,
  },
}));

export const StyledConfigBody = styled(Box)(() => ({
  marginTop: '5px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const ActionButtons = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey.bt_alt_grey_hover}`,
  padding: '12px 30px',
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  alignItems: 'center',
  ' button': {
    marginLeft: '10px',
  },
}));

export const ColumnListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '0px 5px',
}));
export const SearchResultBox = styled(Box)(({ theme }) => ({
  height: '24px',
  color: theme.palette.generic.red,
  fontSize: '12px',
  marginTop: '-12px',
  overflow: 'hidden',
  padding: '4px 65px',
}));

export const StyledFieldsList = styled('div')(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  marginRight: '8px',
  ...theme.customScrollbar,
  '::-webkit-scrollbar-track': {
    margin: '10px 0',
  },
}));
