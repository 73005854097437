import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const StyledAvatar = styled(Box, {
  shouldForwardProp: (prop) =>
    !['disableHover', 'unassigned', 'wrapperStyles', 'rootStyles', 'svgStyles', 'size', 'profileImage'].includes(prop),
})(({ theme, unassigned, disableHover, wrapperStyles, rootStyles, svgStyles, size, profileImage }) => ({
  ...wrapperStyles,

  '& .MuiAvatar-root': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: `1px ${unassigned ? 'dashed' : 'solid'} ${theme?.palette?.text?.secondary_txt}`,
    boxShadow: `inset 0px 0px 0px ${theme?.avatars?.backdrop_spacing?.[size]} ${theme?.palette?.generic?.primary}`,
    backgroundColor: !profileImage && theme?.palette?.blueLabels?.bt_blue_chip_light,
    borderRadius: '50%',
    textTransform: 'capitalize',
    boxSizing: 'border-box',
    width: theme?.avatars?.height?.[size],
    height: theme?.avatars?.height?.[size],
    fontSize: theme?.avatars?.font_size?.[size],
    lineHeight: '100%',
    ...rootStyles,

    '& svg': {
      height: theme?.avatars?.svg?.[size],
      width: theme?.avatars?.svg?.[size],
      ...svgStyles,
    },

    '&:hover': disableHover
      ? {}
      : {
          filter: 'brightness(90%)',
        },

    '&:hover::after': disableHover
      ? {}
      : {
          content: '""',
          position: 'absolute',
          backgroundColor: theme?.palette?.hover?.avatar,
          borderRadius: '50%',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        },
  },
}));

export const StyledAvatarGroup = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'avatarAmount',
})(({ theme, avatarAmount }) => ({
  display: 'flex',
  flexDirection: 'row',

  '& #number-avatar .MuiAvatarGroup-avatar': {
    border: 'none',
    backgroundColor: theme?.palette?.background?.pages_background,
    color: theme?.palette?.text?.txt_default,
    fontSize: theme?.avatars?.font_size?.number,
  },

  '& .avatar-wrapper': {
    marginLeft: avatarAmount > 1 ? '-7px' : '0px',
  },

  '& >:first-of-type': {
    '&.avatar-wrapper, .avatar-wrapper': {
      marginLeft: 0,
    },
  },
}));
