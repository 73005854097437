import { useState, useEffect, useRef, useMemo, useCallback, useImperativeHandle, forwardRef, memo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { createPortal } from 'react-dom';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
// import { rankItem } from '@tanstack/match-sorter-utils';
// import { useVirtual } from 'react-virtual';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import CustomScroll from '../customScroll';
import { StyledGrid, StyledSortIcon } from './style';
import GridFilters from './filters/gridFilters';
import ColumnFilter from './filters/columnFilter';
import { FETCH_SIZE, FETCH_MORE_OFFSET } from './constants';
import {
  generalFilterFns,
  getColumnFiltersForRequest,
  getColumnsMap,
  getFilterFnByFilterType,
} from './filters/helpers';
import SearchField from '../searchField';
import useTexts from './useTexts';

export const useGridDataFetch = ({ queryKeys, queryFn, fetchSize, sort, columnFilters, columnsMap }) => {
  const columnFiltersToSend = getColumnFiltersForRequest(columnFilters, columnsMap);
  return useInfiniteQuery(
    [...queryKeys, sort, columnFiltersToSend, true],
    async ({ pageParam = 0 }) =>
      queryFn({
        page: pageParam,
        size: FETCH_SIZE || fetchSize,
        sort,
        columnFilters: columnFiltersToSend,
        defaultsFirst: true,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (!Number.isNaN(lastPage)) return;
        const { last, pageNumber } = lastPage;
        const nextPage = pageNumber + 1;
        return !last ? nextPage : undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
};

const Grid = memo(
  forwardRef((props, ref) => {
    const {
      queryFn,
      queryKey,
      fetchSize,
      columns,
      hasGlobalFilter,
      topFilterName,
      initialState,
      sortingFns: additionalSortingFns,
      filterFns: additionalFilterFns,
      enableSortingRemoval,
      inputSearchLocation,
      inputSearchPlaceholder,
      onRowClicked,
      onColumnFiltersChange,
      gridExternalData,
      hideSortIcon,
    } = props;
    const { searchText, searchAllColumnsText } = useTexts();
    const tableHeaderRef = useRef(null);
    const tableContainerRef = useRef(null);
    const [sorting, setSorting] = useState(initialState?.sortBy || []);
    const [columnFilters, setColumnFilters] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const columnsMap = useMemo(() => getColumnsMap(columns), [columns]);
    const { data, fetchNextPage, isFetching, isInitialLoading, hasNextPage, refetch } = useGridDataFetch({
      queryKeys: [queryKey],
      queryFn,
      fetchSize,
      sort: sorting.length > 0 ? sorting[0] : undefined, // @TODO support subsort
      columnFilters,
      columnsMap,
    });
    const afterFirstRender = useRef();

    useEffect(() => {
      localStorage.setItem(`${queryKey}_sorting`, JSON.stringify(sorting));
    }, [sorting, queryKey]);

    useEffect(() => {
      localStorage.setItem(`${queryKey}_columnFilters`, JSON.stringify(columnFilters));
    }, [columnFilters, queryKey]);

    if (!afterFirstRender.current) {
      const sortingFromCache = localStorage.getItem(`${queryKey}_sorting`);
      if (sortingFromCache) {
        try {
          const parsed = JSON.parse(sortingFromCache);
          setSorting(parsed);
        } catch (e) {
          console.log(`error parsing cached sorting data in ${queryKey}`);
        }
      }
      const columnFiltersFromCache = localStorage.getItem(`${queryKey}_columnFilters`);
      if (columnFiltersFromCache) {
        try {
          const parsed = JSON.parse(columnFiltersFromCache);
          setColumnFilters(parsed);
        } catch (e) {
          console.log(`error parsing cached filters data in ${queryKey}`);
        }
      }
    }
    afterFirstRender.current = true;
    useImperativeHandle(ref, () => ({
      handleRefetch() {
        refetch();
      },
    }));
    const processedColumns = useMemo(
      () =>
        columns.map((col) => ({
          ...col,
          sortingFn: col.sortingFn,
          filterFn: col.filterFn || getFilterFnByFilterType(col.filterType),
        })),
      [columns],
    );
    const processedSortingFn = useCallback(
      (additionalSortingFn) =>
        (...args) =>
          additionalSortingFn(...args, sorting),
      [sorting],
    );
    const sortingFns = useMemo(() => {
      const processedSortingFns = {};
      for (const key in additionalSortingFns) {
        processedSortingFns[key] = processedSortingFn(additionalSortingFns[key]);
      }
      return processedSortingFns;
    }, [additionalSortingFns, processedSortingFn]);

    const filterFns = useMemo(
      () => ({
        ...generalFilterFns,
        ...additionalFilterFns,
      }),
      [additionalFilterFns],
    );

    const flatData = useMemo(() => {
      if (gridExternalData?.length) {
        return gridExternalData;
      }
      if (data?.pages) {
        return data.pages?.flatMap((page) => page.currentList ?? []);
      }
      return [];
    }, [gridExternalData, data?.pages]);

    const table = useReactTable({
      data: flatData,
      columns: processedColumns,
      sortingFns,
      filterFns,
      initialState,
      state: {
        columnFilters,
        sorting,
      },
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      onColumnFiltersChange: setColumnFilters,
      onSortingChange: setSorting,
      enableSortingRemoval,
      debugTable: true,
      debugHeaders: true,
      debugColumns: false,
    });
    const { rows } = table.getRowModel();
    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(() => {
      const containerRefElement = tableContainerRef.current;
      if (containerRefElement) {
        const values = containerRefElement.getValues();
        tableHeaderRef.current.style.transform = `translateX(-${values.scrollLeft}px)`;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          containerRefElement.getScrollHeight() -
            containerRefElement.getScrollTop() -
            containerRefElement.getClientHeight() <
            FETCH_MORE_OFFSET &&
          !isFetching &&
          hasNextPage
        ) {
          fetchNextPage();
        }
      }
    }, [tableContainerRef, fetchNextPage, isFetching, hasNextPage]);
    //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
      fetchMoreOnBottomReached();
    }, [fetchMoreOnBottomReached]);

    const prevSorting = usePreviousValue(sorting);

    // after sorting applied, if there is more available data to fetch - refetch with the new sort
    useEffect(() => {
      if (prevSorting && prevSorting !== sorting && hasNextPage && !isFetching) {
        refetch();
      }
    }, [prevSorting, sorting, hasNextPage, isFetching, refetch]);

    useEffect(() => {
      if (onColumnFiltersChange) {
        onColumnFiltersChange(columnFilters);
      }
    }, [onColumnFiltersChange, columnFilters]);

    const prevColumnFilters = usePreviousValue(columnFilters);

    // after filter applied, if there is physical place in the table - refetch with the new filters
    useEffect(() => {
      const containerRefElement = tableContainerRef.current;
      if (
        prevColumnFilters !== columnFilters &&
        containerRefElement &&
        containerRefElement.getScrollHeight() - containerRefElement.getScrollTop() <=
          containerRefElement.getClientHeight() &&
        !isFetching
      ) {
        refetch();
      }
    }, [prevColumnFilters, columnFilters, tableContainerRef, isFetching, refetch]);

    //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows -
    // copied from https://tanstack.com/table/v8/docs/examples/react/virtualized-infinite-scrolling - causes extra renders - needs to be investigated
    //   const rowVirtualizer = useVirtual({
    //     parentRef: tableContainerRef,
    //     size: rows?.length,
    //     overscan: 10,
    //   });
    //   const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    //   const paddingTop = virtualRows?.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    //   const paddingBottom = virtualRows?.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    const onSearchChange = useCallback((e) => setSearchValue(e.target.value), []);

    const onSearchClear = useCallback(() => {
      setSearchValue('');
    }, []);

    useEffect(() => {
      setColumnFilters((prev) =>
        searchValue
          ? [
              ...prev.filter((columnFilter) => columnFilter.id !== topFilterName),
              { id: topFilterName, value: searchValue },
            ]
          : prev.filter((columnFilter) => columnFilter.id !== topFilterName),
      );
    }, [searchValue, topFilterName]);

    const topFilterColumn = topFilterName ? table.getColumn(topFilterName) : null;

    let searchFieldPlaceholder;

    if (inputSearchPlaceholder) {
      searchFieldPlaceholder = inputSearchPlaceholder;
    } else if (hasGlobalFilter) {
      searchFieldPlaceholder = searchAllColumnsText;
    } else if (topFilterColumn) {
      searchFieldPlaceholder = `${searchText} ${topFilterColumn.columnDef.header}`;
    }

    const SearchInput = () => (
      <div>
        <SearchField
          value={searchValue}
          placeholderText={searchFieldPlaceholder}
          onChange={onSearchChange}
          onClear={onSearchClear}
          autoFocus
        />
      </div>
    );

    if (isInitialLoading) {
      return <>Loading...</>;
    }

    return (
      <StyledGrid className="container" >
        {(hasGlobalFilter || topFilterColumn) && ( // @TODO handle global filter case
          <>{inputSearchLocation ? createPortal(<SearchInput />, inputSearchLocation) : <SearchInput />}</>
        )}
        <GridFilters table={table} columnFilters={columnFilters} />
        <div className="wrapper">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} ref={tableHeaderRef} className="table-row">
              {headerGroup.headers.map((header) => {
                const { column } = header;
                const toggleSortingHandler = column.getToggleSortingHandler();
                return (
                  <div className="header-cell" key={header.id} id={`${header.id}Header`}>
                    {!header.isPlaceholder && (
                      <div>
                        {flexRender(column.columnDef.header, header.getContext())}
                        {column.getCanSort() && (
                          <StyledSortIcon
                            isSortActive={column.getIsSorted()}
                            sort={column.getIsSorted()}
                            onClick={toggleSortingHandler}
                            className="columnHeaderSortIcon"
                            hideSortIcon={hideSortIcon}
                          />
                        )}
                        {column.getCanFilter() && <ColumnFilter column={column} />}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
          <div className="table-body">
            <CustomScroll
              ref={tableContainerRef}
              onScroll={fetchMoreOnBottomReached}
              className="grid-scroll"
              dataset={{ 'data-testid': 'grid-scrollbar' }}
            >
              {/* {paddingTop > 0 && (
            <div className="table-row">
              <div className="cell" style={{ height: `${paddingTop}px` }} />
            </div>
          )} */}
              {/* {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index]; */}
              {rows.map((row) => (
                <div
                  className={`table-row ${onRowClicked ? 'pointer' : undefined}`}
                  key={row.id}
                  onClick={(event) => {
                    if (onRowClicked) onRowClicked(row.original, event);
                  }}
                  onKeyDown={(event) => {
                    if (onRowClicked) onRowClicked(row.original, event);
                  }}
                  role="presentation"
                >
                  {row.getVisibleCells().map((cell) => (
                    <div className={`cell ${cell.column.id}Cell`} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  ))}
                </div>
              ))}
            </CustomScroll>
            {/* {paddingBottom > 0 && (
            <div className='table-row'>
              <div className="cell" style={{ height: `${paddingBottom}px` }} />
            </div>
          )} */}
          </div>
        </div>
      </StyledGrid>
    );
  }),
);

export default Grid;
