import MultiSelectChipList from 'common/components/controls/MultiSelectField/MultiSelectChipList';
import useTexts from 'features/resolutionPanel/middlePanel/relatedItems/hooks/useTexts';

import { actionEnums } from '../../../constants';
import { StyledRelatedItemsEvent } from './style';

export const RelatedItemsEvent = ({ auditLog }) => {
  const { getSrType } = useTexts();

  const extractRelatedItmesArray = (logInformation) => {
    switch (auditLog?.logType) {
      case actionEnums.RELATED_ITEM_SR_CREATED: {
        const relationship = Object.keys(logInformation)[0];
        const linkedItems = Array.isArray(logInformation?.[relationship])
          ? logInformation[relationship].map((item) => ({
              srId: item?.id,
              srType: getSrType(item?.value?.toLowerCase()) || item?.value,
              srDescription: `${getSrType(item?.value?.toLowerCase()) || item?.value} #${item?.id}`,
            }))
          : [];
        return linkedItems;
      }
      case actionEnums.RELATED_ITEM_SR_DELETED: {
        return Object.entries(logInformation)?.map(([srId, srType]) => ({
          srId,
          srType: getSrType(srType?.toLowerCase()) || srType,
          srDescription: `${getSrType(srType?.toLowerCase()) || srType} #${srId}`,
        }));
      }
      default:
        return [];
    }
  };

  const relatedItems = extractRelatedItmesArray(auditLog?.logInformation);

  return (
    <StyledRelatedItemsEvent>
      {relatedItems?.length && (
        <MultiSelectChipList
          itemList={relatedItems}
          selectedItemList={relatedItems?.map((item) => item?.srId)}
          readOnly
          captionString="srDescription"
          keyString="srId"
          withTruncatedChips
          skipValidation
          noPadding
        />
      )}
    </StyledRelatedItemsEvent>
  );
};
