import { Box, styled } from '@mui/material';
import { useStyleHelpers } from 'common/components/dropdownSelect/hooks';

export const StyledCategories = styled('div')`
  display: flex;
  flex-grow: 1;
  width: 77%;
  overflow: hidden;
  & span.floating-ticket {
    display: none;
  }
  & .categories {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  & .MuiContainer-root {
    padding: 0;
  }
`;

export const getCustomHeaderStyles = (selectedOption, required, forceShowError) => {
  const commonStyle = {
    borderRadius: '16px',
    backgroundColor: '#fff',
    fontFamily: 'Roboto-Regular',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const selectedFieldStyle = {
    backgroundColor: '#c9edf9',
    fontFamily: 'Roboto-Regular',
    color: '#303030',
    '&:hover': {
      backgroundColor: '#9fd0e0',
      color: '#303030',
    },
  };
  const requiredFieldStyle = {
    border: 'solid 1px #f76969',
    color: '#f76969',
    '&:hover': {
      backgroundColor: '#fff2f2',
      color: '#de4949',
      border: 'solid 1px #de4949',
    },
  };
  const notRequiredFieldStyle = {
    border: 'solid 1px #7d899d',
    color: '#7d899d',
    '&:hover': {
      backgroundColor: '#f3f3f3',
      color: '#323436',
      border: 'solid 1px #323436',
    },
  };

  let fieldStyle = {};

  if (selectedOption !== null && selectedOption !== undefined) {
    fieldStyle = selectedFieldStyle;
  } else if (!required || (required && !forceShowError)) {
    fieldStyle = notRequiredFieldStyle;
  } else {
    fieldStyle = requiredFieldStyle;
  }

  return {
    ...commonStyle,
    ...fieldStyle,
  };
};

export const Label = styled(Box, {
  shouldForwardProp: (prop) => !['stylingVariant', 'color', 'customHeaderStyles', 'isTemplatePage'].includes(prop),
})(({ theme, stylingVariant, customHeaderStyles, value, disabled, isTemplatePage }) => {
  const { selectItemColor, selectItemBorderRadius, selectItemBgColor, selectHoveredItemBgColor } = useStyleHelpers({
    theme,
    stylingVariant,
  });
  const customStyles = customHeaderStyles(value);
  customStyles.cursor = !disabled ? 'pointer' : 'default';
  if (value !== null && value !== undefined) {
    customStyles['&:hover'].backgroundColor = selectHoveredItemBgColor(false);
  }
  let maxWidth = '160px';
  const calcMaxWidth = 'calc(100% / 3 - 8px)';
  if (isTemplatePage) {
    maxWidth = calcMaxWidth;
  }

  return {
    fontSize: '0.75rem',
    fontFamily: 'Roboto-Regular',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: selectItemBorderRadius(),
    color: selectItemColor(),
    backgroundColor: selectItemBgColor(),
    height: theme.spacing(3),
    boxSizing: 'border-box',
    transition: 'color 0.4s ease, border 0.4s ease',
    ...customStyles,
    maxWidth,
    '@media only screen and (max-width: 1440px)': {
      maxWidth: isTemplatePage ? calcMaxWidth : '140px',
    },
    '@media only screen and (max-width: 1280px)': {
      maxWidth: isTemplatePage ? calcMaxWidth : '120px',
    },
    '&.floating-ticket': {
      maxWidth: calcMaxWidth,
    },
  };
});
