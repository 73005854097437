import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import RobotoRegular from '../../../images/fonts/Roboto/Roboto-Regular.ttf';
import RobotoLight from '../../../images/fonts/Roboto/Roboto-Light.ttf';
import RobotoBold from '../../../images/fonts/Roboto/Roboto-Bold.ttf';

const theme = createTheme({
  palette: {
    mode: 'dark',
    generic: {
      primary: '#000',
      secondary: '#fff',
      grey_600: '#808080',
      grey_200: '#f1f1f1',
      grey_100: '#f9f9fa',
      red: 'red',
      sysaid: '#699D8C',
      sysaid_hover: '#49806D',
      sysaid_mid: '#B2D1C7',
      sysaid_light: '#D0E7DF',
      sysaid_very_light: '#F2FAF7',
      sysaid_default: '#699D8C',
      sysaid_dark_green: '#3D6B5C',
    },
    sysaid_very_light: {
      main: '#F2FAF7',
      contrastText: '#56957F',
    },
    beta_chip_blue: {
      main: '#ECF4FF',
      contrastText: '#6092DB',
    },
    bt_red_light: {
      main: '#FFF2F2',
      contrastText: '#DE4949',
    },
    bt_orange_light: {
      main: '#FFF8F2',
      contrastText: '#E5792C',
    },
    bt_yellow_light: {
      main: '#FFFCE5',
      contrastText: '#DBAA2C',
    },
    bt_alt_grey_light: {
      main: '#F5F6FA',
      contrastText: '#787878',
    },
    blueLabels: {
      bt_cyan_reg: '#C9EDF9',
      bt_cyan_hover: '#9FD0E0',
      bt_light_blue_reg: '#83B0F4',
      bt_light_blue_hover: '#6092DB',
      bt_blue_chip_light: '#95A9C9',
      bt_blue_chip_reg: '#7088AF',
      bt_status_reg: '#516C96',
      bt_status_hover: '#344C73',
      bt_light_blue_hover_op: 'rgba(149, 169, 201, 0.3)',
    },
    labels: {
      bt_red_light: '#FFF2F2',
      bt_red_mid: '#FFD9D9',
      bt_red_reg: '#F76969',
      bt_red_hover: '#DE4949',
      bt_orange_light: '#FFF8F2',
      bt_orange_mid: '#FFD5B7',
      bt_orange_reg: '#FF964A',
      bt_orange_hover: '#E5792C',
      bt_yellow_reg: '#F4C449',
      bt_yellow_hover: '#DBAA2C',
      bt_black_light: '#F3F3F3',
      bt_black_reg: '#323436',
      bt_purple_mid: '#7956C3',
    },
    grey: {
      bt_def_grey_reg: '#7D899D',
      bt_def_grey_light: '#BEC4CE',
      bt_def_grey_hover: '#616F85',
      bt_alt_grey_reg: '#F0F1F5',
      bt_alt_grey_light: '#F5F6FA',
      bt_alt_grey_hover: '#E1E2E5',
      divider_grey_v1: '#DADCDE',
      divider_grey_v2: '#EDEFF1',
      divider_grey_v3: '#CCCCCC',
      divider_grey_v4: '#626263',
      divider_grey_v5: '#787878',
      tooltip_bg: '#494949',
      scroll: '#E6E6E6',
      chip_grey: '#939599',
    },
    text: {
      txt_default: '#303030',
      title_txt: '#4F4F4F',
      secondary_txt: '#787878',
      disable_txt: '#BEC4CE',
      disable_txt_V2: '#ABAEB3',
      disabled: '#ABAEB2',
      autocomplete: 'rgba(48, 48, 48, 0.90)',
      autocompleteSecondary: 'rgba(48, 48, 48, 1)',
      resolutionPanelFooter: 'rgba(48, 48, 48, 0.85)',
      inactive: '#699d8c',
      inactive_secondary: '#e9f1ee',
      checkbox: '#34495e',
      bluegrey: '#c7ccd4',
      blue_user_name: '#3578DB',
      journey_title: '#5D6778',
    },
    icons: {
      icons_in_journey: '#344868',
      icons_grey_v1: '#7D899D',
      bt_def_grey_reg: '#A4A8B0',
      bt_blue_chip_light_transparent: '#95A9C966',
      icons_grey_v3: '#777C85',
      avatar_big_size: '36px',
      avatar_medium_size: '22px',
      avatar_small_size: '16px',
      default: '#699D8C',
      default_hover: '#49806D',
      dragging: '#BEC4CE',
      field_used: '#D0E7DF',
    },
    background: {
      bg_hover_alt: '#E6E8F0',
      bg_hover_main: '#F0F2F9',
      selected_and_hover: '#EBEDF5',
      bg_new_input_text: '#EBF5FF',
      selected: '#F5F6FA',
      action: '#F5FAFF',
      pages_background: '#EBF0F5',
      lightGrey: 'F0EFF0',
      boxShadow: 'rgba(118, 130, 150, 0.35)',
      modal: 'rgba(0,0,0,0.4)',
      cancelNoteModal: 'rgba(0,0,0,0.5)',
      grey_hover: 'rgba(149, 169, 201, 0.3)',
      bg_drag_opacity: 'rgba(240, 241, 245, 0.7)',
      bg_error_tooltip_opacity: 'rgba(212, 17, 17, 0.8)',
      bg_suggested_category: '#F3FAFC',
      blue_hover: '#dfe5ef',
      pdf_icon: '#EE3E2C',
      word_icon: '#3578DB',
      xls_icon: '#1F7244',
      ppt_icon: '#DE4949',
      default_file_icon: '#F5F6FA',
    },
    border: {
      autocomplete: '#969696',
    },
    hover: {
      avatar: 'rgba(48, 48, 48, 0.12)',
    },
    switchToggle: {
      checked: '#699D8C',
      unchecked: '#CCCCCC',
      disabled: '#E6E6E6',
    },
    copilotBadge: {
      color: '#5630AA',
      backgroundColor_1: '#E2EAFF',
      backgroundColor_2: '#EFDCFE',
    },
  },
  shape: {
    border: {
      border: '1px solid #DADCDE',
      border_2: '1px solid #CCCCCC',
      border_transparent: '1px solid transparent',
      borderRadius: '4px',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          backgroundColor: orange[500],
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Roboto-Regular';
          src: local('RobotoRegular'), url('${RobotoRegular}') format('truetype');
                    font-style: normal;
        }
        @font-face {
          font-family: 'Roboto-Light';
          src: local('RobotoLight'), url('${RobotoLight}') format('truetype');
                    font-style: normal;
        }
        @font-face {
          font-family: 'Roboto-Bold';
          src: local('RobotoBold'), url('${RobotoBold}') format('truetype');
                    font-style: normal;
        }
        `,
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: ({ ownerState, theme }) => ({
          ...(ownerState.type === 'info' && {
            color: theme.palette.grey.tooltip_bg,
          }),
          ...(ownerState.type === 'error' && {
            color: theme.palette.background.bg_error_tooltip_opacity,
          }),
        }),
        tooltip: ({ ownerState, theme }) => ({
          ...(ownerState.type === 'info' && {
            backgroundColor: theme.palette.grey.tooltip_bg,
          }),
          ...(ownerState.type === 'error' && {
            backgroundColor: theme.palette.background.bg_error_tooltip_opacity,
          }),
        }),
      },
    },
    addButton: {
      color: '#49806D',
    },
    header: {
      margin: '23px 0 54px',
    },
    headerTitle: {
      fontSize: '28px',
      lineHeight: '24px',
    },
    main: {
      background: '#fff',
      width: '100%',
      height: '100%',
      borderRadius: '10px',
      padding: '36px',
      overflowX: 'auto',
    },
  },
  fonts: {
    robotoRegular: 'Roboto-Regular',
    robotoLight: 'Roboto-Light',
    robotoBold: 'Roboto-Bold',
    robotoMedium: 'Roboto-Medium',
  },
  fontSize: {
    main: '0.875rem',
    small: '0.75rem',
    medium: '1.125rem',
    big: '1.25rem',
  },
  mixins: {
    scrollbar: {
      backgroundColor: 'transparent',
      '-webkit-background-clip': 'text',
      transition: 'background-color .4s',
      scrollbarColor: 'inherit transparent',
      scrollbarWidth: 'thin',
      '&:hover': {
        backgroundColor: '#DADCDE',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'inherit',
        borderRadius: '5px',
      },
    },
  },
});

export default theme;
