import { AUTH_CONSTANTS } from 'constants/auth';
import { openWindow } from 'common/utils/utils';
import { KebabMenu } from 'common/components/kebabMenu';

import Hint from 'features/templateBuilder/TemplateSection/HintIcon';
import { useUserDetailsByName } from 'remote-state/ticketServiceHooks';
import { StyledFieldName, StyledField, FieldNameText } from './styledFieldName';
import { StyledAsterisk } from './styledAsterisk';
import useTexts from '../useTexts';

export default function FieldName(props) {
  const { className, name, required, hintText, hasMoreDetails, url, urlParams, fieldValue, isTemplatePage } = props;
  const { moreDetails } = useTexts();

  const urlParamsKeys = urlParams && Object.keys(urlParams);
  const isUserField = urlParamsKeys && (urlParamsKeys.includes('userName') || urlParamsKeys.includes('userType'));

  const { data: userDetails } = useUserDetailsByName(isUserField ? fieldValue?.userName || fieldValue : undefined);

  const handleURL = () => {
    const targetUrl = new URL(window.location.origin + url);
    Object.entries(urlParams).forEach(([key, value]) => {
      if (value) {
        targetUrl.searchParams.set(key, value);
      } else {
        switch (key) {
          case 'userName':
            targetUrl.searchParams.set(key, `@BASE64@${btoa(userDetails.userName)}`);
            break;
          case 'userType':
            targetUrl.searchParams.set(key, userDetails.isAdmin ? AUTH_CONSTANTS.ADMIN : AUTH_CONSTANTS.END_USER);
            break;
          case 'id':
          case 'computerID':
            targetUrl.searchParams.set(key, fieldValue !== undefined && fieldValue);
            break;
          default:
        }
      }
    });
    return decodeURIComponent(targetUrl);
  };

  const handleKebabMenuAction = (actionName) => {
    switch (actionName) {
      case 'openWindowLink':
        openWindow(handleURL());
        break;
      default:
        break;
    }
  };

  return (
    <StyledField isTemplatePage={isTemplatePage} className="field-name-title">
      <StyledFieldName className={className}>
        <FieldNameText data-cy="field-name-text">{name}</FieldNameText>
        {required && <StyledAsterisk>&nbsp;*</StyledAsterisk>}
        {hintText && <Hint hintText={hintText} />}
      </StyledFieldName>
      {hasMoreDetails && (
        <KebabMenu
          isVisible={Boolean(fieldValue) || fieldValue === 0}
          handleKebabMenuAction={handleKebabMenuAction}
          actionList={[{ name: 'openWindowLink', defaultCaption: moreDetails }]}
        />
      )}
    </StyledField>
  );
}
