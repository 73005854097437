import { ReactComponent as OpenPickerIcon } from '../../../../images/icons/icon_Schedule.svg';
import { StyledDueDateButton } from '../styledDueDateButton';
import useTexts from '../useTexts';

const EmptyDueDate = (props) => {
  const { isError, onClick, dueDateRef, isTemplatePage } = props;
  const { setDate } = useTexts();

  return (
    <StyledDueDateButton
      className="empty-dueDateField"
      ref={dueDateRef}
      onClick={onClick}
      variant="outlined"
      endIcon={
        <OpenPickerIcon
          style={{ width: '14px', height: '14px', paddingBottom: '1px', marginLeft: '14px' }}
          className="openPickerIcon"
        />
      }
      isError={isError}
      isTemplatePage={isTemplatePage}
    >
      {setDate}
    </StyledDueDateButton>
  );
};

export default EmptyDueDate;
